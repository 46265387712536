import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MemberService } from 'services';
import { ProfileParams } from 'types/Profile';
import { updateProfileManagementActions as actions } from '.';

function* updateProfile(action: PayloadAction<ProfileParams, string, (error?: any) => void>) {
  try {
    yield call(MemberService.updateProfile, action.payload);
    yield put(actions.updateProfileSuccess());
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* updateProfileManagementSaga() {
  yield takeLatest(actions.updateProfile.type, updateProfile);
}
