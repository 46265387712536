import { Avatar, Box, Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import path from 'app/routes/path';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocalStorageService } from 'services';

import { useProfile } from 'app/hooks';
import { useAuthSlice } from 'app/pages/Auth/slice';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { snackbarActions } from 'app/pages/SnackBar/slice';

import MenuPopover from '../../components/MenuPopover';
import account from '../../_mock/account';
import { MHidden } from '../@material-extend';
import LanguagePopoverMenu from '../LanguagePopoverMenu';
import RemainPlayTurnMenu from '../RemainPlayTurnMenu';
import SubmitFeedback from '../SubmitFeedback';
import ThemeSwitchPopupMenu from '../ThemeSwitchPopupMenu';
import { MyProfile } from '../EditProfile';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Edit profile',
    icon: '/static/icons/Icon-edit-profile.png',
    linkTo: '',
  },
  {
    label: 'Change password',
    icon: '/static/icons/Icon-change-pw.png',
    linkTo: path.changePassword,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { actions } = useAuthSlice();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const user = useProfile();
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogEditProfile, setOpenDialogEditProfile] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogEditProfile = () => {
    setOpenDialogEditProfile(false);
  };

  const handlePathTo = (path: string, label: string) => {
    if (path === '') {
      if (label === 'Submit feedback') {
        setOpenDialog(true);
        setOpen(false);
      } else if (label === 'Edit profile') {
        setOpenDialogEditProfile(true);
        setOpen(false);
      } else {
        return false;
      }
    } else {
      navigate(path);
    }
  };

  const handleLogout = () => {
    dispatch(
      actions.logout({}, (err?: any) => {
        if (!err) {
          dispatch(
            snackbarActions.updateSnackbar({
              message: 'You have signed out',
              type: 'success',
            })
          );
          LocalStorageService.removeAllItem();
          setOpen(false);
          navigate(path.login);
        }
      })
    );
  };

  const DialogFeedback = () => {
    setOpenDialog(true);
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={(theme) => ({
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        })}
      >
        <Avatar src={user?.avatar || account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {/* {user?.campaigns_roles?.map((item) => {
              return item.campaign_id === parseInt(id as string) ? get(RoleProfileUser, item.role || '') : '';
            })} */}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MHidden width="smUp">
          <Stack sx={{ p: '14px 16px 0', textAlign: 'center' }}>
            {user?.campaigns_roles?.map((item) => {
              return <Stack sx={{ fontSize: '14px' }}>{item.campaign_id === Number(id) && item.campaign_name}</Stack>;
            })}
            {id && <RemainPlayTurnMenu />}
          </Stack>
        </MHidden>

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <div
              style={{
                display:
                  user?.method_login !== 'login_by_username' && option.label === 'Change password' ? 'none' : 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '10px 0 5px 15px',
                cursor: 'pointer',
                fontSize: '14px',
              }}
              onClick={() => handlePathTo(option.linkTo, option.label)}
            >
              <img src={option.icon} alt={option.label} style={{ width: 22, marginRight: '10px' }} />
              {t(option.label)}
            </div>
          ))}
        </Stack>

        <MHidden width="smUp">
          <Stack sx={{ width: '100%', p: '0 14px 6px' }}>
            <ThemeSwitchPopupMenu />
          </Stack>
        </MHidden>

        <MHidden width="smUp">
          <Stack sx={{ width: '100%', p: '0 14px 6px' }}>
            <LanguagePopoverMenu />
          </Stack>
        </MHidden>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={DialogFeedback} sx={{ m: 1 }}>
          <img src={'/static/icons/icon_feedback.png'} alt={'Feedback'} style={{ width: 25, marginRight: '10px' }} />
          {t('Submit feedback')}
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <img src={'/static/icons/Icon-sign-in.png'} alt={'sign-in'} style={{ width: 25, marginRight: '10px' }} />
          {t('Sign out')}
        </MenuItem>
      </MenuPopover>

      {/* Feedback */}
      {openDialog && <SubmitFeedback openDialog={openDialog} handleCloseDialog={handleCloseDialog} />}
      {openDialogEditProfile && (
        <MyProfile openDialog={openDialogEditProfile} handleCloseDialog={handleCloseDialogEditProfile} />
      )}
    </>
  );
}
