import { Box, Typography } from '@mui/material';
import StarIcon from 'assets/table/star.svg';

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => {
  return (
    <Box display="flex">
      <Typography mb={1}>{title}</Typography>
      <Box display="flex" margin="-10px 3px 0px 4px">
        <img src={StarIcon} alt={'star'} width={6} />
      </Box>
    </Box>
  );
};
