import { Box, Link, Typography } from '@mui/material';
import { withLoading } from 'app/components/HOC/WithLoading';
import SignUpForm from 'app/pages/Auth/components/SignUp';
import path from 'app/routes/path';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SignUpParams } from 'types';
import { useSnackbarSlice } from '../SnackBar/slice';
import { BackgroundImage } from './components/BackgroundImage';
import AuthSocial from './components/LoginSocial';
import VerifyEmail from './components/VerifyEmail';
import { useAuthSlice } from './slice';

// ----------------------------------------------------------------------
interface Props {
  setLoading?: Function;
}

const SignUpPage = (props: Props) => {
  const { actions } = useAuthSlice();
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleSignUp = useCallback(
    ({ email, password, name, confirm_password }: SignUpParams) => {
      setIsLoading(true);
      dispatch(
        actions.signUp({ email, password, name, confirm_password }, (err?: any) => {
          setError(err?.data);
          setIsLoading(false);
          if (!err) {
            setStep(step + 1);
            setEmail(email);
            dispatch(
              snackbarActions.updateSnackbar({
                message: `A verification link was sent to ${email}`,
                type: 'success',
              })
            );
          } else {
            if (err.data.message) {
              dispatch(
                snackbarActions.updateSnackbar({
                  message: `${err.data.message}`,
                  type: 'error',
                })
              );
              return;
            }
            dispatch(
              snackbarActions.updateSnackbar({
                message: 'Đăng ký tài khoản không thành công!',
                type: 'error',
              })
            );
          }
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, dispatch, navigate]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#E2E2E2',
        height: { xs: '100%' },
      }}
    >
      <BackgroundImage />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: { md: '60%', xs: '100%' },
          mx: 3,
        }}
      >
        {step === 1 && (
          <>
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'end',
                top: { md: '40px', xs: '15px' },
                right: { md: '20px', xs: '0px' },
              }}
            >
              <Typography
                variant="body2"
                sx={(theme) => ({
                  display: 'flex',
                  fontSize: 14,
                  color: theme.palette.primary.main,
                })}
              >
                {'Already have an account?'}
                <Link
                  component={RouterLink}
                  style={{ textDecoration: 'none' }}
                  to={path.login}
                  className="link-forgot-password"
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 600, ml: 1 }}>
                    Sign in
                  </Typography>
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                m: { xl: '50px 140px', xs: '115px 0px' },
                padding: { xs: '35px 0px 0px 0px' },
              }}
            >
              <Typography
                fontSize="18px"
                sx={(theme) => ({
                  fontWeight: '600',
                })}
              >
                Sign up to PapaHub
              </Typography>
              <Typography fontSize="14px" my={1} fontWeight={400}>
                Enter your detail below.
              </Typography>
              <SignUpForm error={error} onSubmit={handleSignUp} loading={isLoading} />
              <AuthSocial />
            </Box>
          </>
        )}
        {step === 2 && (
          <VerifyEmail
            email={email}
            title="Verify your email"
            description={
              <>
                <Typography fontSize="12px" my={1} fontWeight={400}>
                  Click the link was sent to your email to complete the registration.
                </Typography>
                <Typography fontSize="12px" my={1} fontWeight={400}>
                  If you don't see it, you may need to check your spam folder.
                </Typography>
              </>
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default withLoading(SignUpPage);
