/**
 * Asynchronously loads the component for GameList
 */

import { lazyLoad } from 'utils/loadable';

export const CampaignSettings = lazyLoad(
  () => import('./index'),
  (module) => module.CampaignSettings
);
