import { Icon } from '@iconify/react';
import { Box, Button, Card, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMainGameSlice } from '../../slice';
import { MainGameTypes } from '../../slice/types';
import WatchVideo from './WatchVideo';
export interface Props {
  game: MainGameTypes;
}

export default function GameBaseElement({ game }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams();
  const { id } = params;
  const { actions } = useMainGameSlice();
  const { actions: snackbarActions } = useSnackbarSlice();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { themeInfo } = useSelector(layoutsSelector);
  const disableVideoGame = process.env.REACT_APP_DISABLE_VIDEO_GAME;

  const handleAddGame = () => {
    const campaign_id = Number(id);
    const game_base_id = game.id;
    dispatch(
      actions.chooseGameBase({ campaign_id, game_base_id }, (err?: any) => {
        if (!err) {
          navigate(`/campaigns/${campaign_id}/list-game`);
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('Game added')}`,
              type: 'success',
            })
          );
        } else {
          if (err.message === 'this_game_added') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('The game already exists')}`,
                type: 'error',
              })
            );
            setOpen(false);
          }
          if (err.message === 'campaign_of_game_has_been_ended') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('campaignBeenEnded')}`,
                type: 'error',
              })
            );
            setOpen(false);
          }
        }
      })
    );
  };

  const handleCancelAction = () => {
    setOpen(false);
  };

  const handleActionGame = () => {
    setOpen(true);
  };

  const handleOpenWatchVideo = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Card
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '337px',
            alignItems: 'flex-start',
          }}
        >
          <Stack
            direction="column"
            width="100%"
            sx={{
              position: 'relative',
              '& img': {
                width: '100%',
                height: '245px',
              },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={game?.game_thumbnail || '/static/icons/bg-2.png'} alt="" />
            <Stack sx={{ position: 'absolute' }}>
              <Button
                sx={(theme) => ({
                  backgroundColor: theme.palette.info.darker,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.lighter,
                  },
                  width: '53.33px',
                  height: '53.33px',
                  borderRadius: '30px',
                })}
                disabled={Boolean(disableVideoGame)}
                onClick={handleOpenWatchVideo}
              >
                <Icon icon="eva:arrow-right-fill" width={32} height={32} />
              </Button>
            </Stack>
            <Button
              onClick={handleActionGame}
              sx={(theme) => ({
                backgroundColor: `${themeInfo?.bgColor} !important`,
                '&:hover': {
                  backgroundColor: theme.palette.primary.lighter,
                },
                width: 'auto',
                display: 'flex',
                height: '30px',
                borderRadius: '10px 0',
                position: 'absolute',
                top: 290,
                fontSize: 14,
                color: theme.palette.secondary.lighter,
                left: '24px',
                fontWeight: 400,
              })}
            >
              {t('Add Game')}
            </Button>
            <Typography
              variant="subtitle2"
              noWrap
              sx={(theme) => ({
                fontSize: 18,
                color: theme.palette.secondary.contrastText,
                top: '240px',
                left: '10px',
                fontWeight: 600,
                p: 2,
                position: 'absolute',
              })}
            >
              {game.game_name?.replaceAll('.', '')}
            </Typography>
          </Stack>
        </Box>
      </Card>

      <Dialog open={open} fullWidth maxWidth={'xs'}>
        <DialogTitle sx={(theme) => ({ color: theme.palette.primary.main, fontSize: '18px !important' })}>
          {t('Add Game')}
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="subtitle2"
            noWrap
            color={'primary'}
            sx={(theme) => ({
              fontSize: 14,
              fontWeight: 400,
              mt: '24px',
              mb: '35px',
              display: 'flex',
              justifyContent: 'center',
              color: theme.palette.primary.main,
            })}
          >
            {t('Are you sure you want to add this game?')}
          </Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Button
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.lighter,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
                width: '70px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
              onClick={handleCancelAction}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={handleAddGame}
              sx={(theme) => ({
                backgroundColor: `${themeInfo?.bgColor} !important`,
                '&:hover': {
                  backgroundColor: theme.palette.primary.lighter,
                },
                width: '120px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
            >
              {t('Add')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      {isOpen && <WatchVideo game={game} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}
