/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GlobalStyle from 'styles/theme/globalStyles';
import ThemeConfig from 'styles/theme';
import { SnackbarProvider } from 'notistack';
import Notifier from 'app/pages/Notifier';

import Routes from './routes';
import SnackBar from './pages/SnackBar';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <ThemeConfig>
        <Helmet titleTemplate="Customer Admin" defaultTitle="Customer Admin" htmlAttributes={{ lang: i18n.language }}>
          <meta name="description" content="A Customer Admin application" />
        </Helmet>

        <SnackbarProvider anchorOrigin={Notifier.anchorOrigin}>
          <Notifier />
        </SnackbarProvider>
        <SnackBar />
        <Routes />

        <GlobalStyle />
      </ThemeConfig>
    </BrowserRouter>
  );
}
