export enum Status {
  PENDING = 'Chờ Xác Thực',
  REJECTED = 'Bị Từ Chối',
  APPROVED = 'Đã xác Thực',
}

export enum VerificationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  AVAILABLE = 'AVAILABLE',
  REDEEMED = 'REDEEMED',
}

export enum DeliveryStatus {
  WAITING_FOR_PROCESS = 'WAITING',
  APPROVED = 'APPROVED',
  DELIVERING = 'DELIVERING',
  COMPLETED = 'COMPLETED',
  RETURNED = 'RETURNED',
  DEFAULT = '',
}

export enum RedemptionStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ErrorLogin {
  email_incorrect = 'Email is incorrect',
  wrong_password = 'Incorrect password',
  account_has_not_yet_verified = 'Unverified account',
}

export enum RoleUser {
  CUSTOMER_ADMIN = 'Customer Admin',
  CUSTOMER_GAME_MANAGE = 'Game Management',
  REWARD_MANAGE = 'Reward Management',
  CUSTOMER_OWNER = 'Owner',
}

export enum RoleUserCostumer {
  CUSTOMER_ADMIN = 'Admin',
  CUSTOMER_GAME_MANAGE = 'Game manager',
  REWARD_MANAGE = 'Reward manager',
  CUSTOMER_OWNER = 'Owner',
}

export enum RoleUserAddStaff {
  CUSTOMER_ADMIN = 'Admin',
  CUSTOMER_GAME_MANAGE = 'Game manager',
  REWARD_MANAGE = 'Reward manager',
}

export enum RoleProfileUser {
  CUSTOMER_ADMIN = 'Customer Admin',
  CUSTOMER_GAME_MANAGE = 'Game Management',
  REWARD_MANAGE = 'Reward Management',
  CUSTOMER_OWNER = 'Owner',
  CUSTOMER_USER = 'Customer User',
}

export enum RoleStaff {
  CUSTOMER_OWNER = 'CUSTOMER_OWNER',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  CUSTOMER_GAME_MANAGE = 'CUSTOMER_GAME_MANAGE',
  REWARD_MANAGE = 'REWARD_MANAGE',
}

export enum StatusAccountLowerCase {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum TypeUser {
  PERSONAL = 'PERSONAL',
}

export enum StatusCampaign {
  COMING = 'COMING',
  PAUSE = 'PAUSE',
  ENDED = 'ENDED',
  RUNNING = 'RUNNING',
}

export enum StatusCampaignGameVersion {
  OFFICIAL = 'Official',
  DRAFT = 'Draft',
}

export enum TypeSocial {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
}

export enum TypeNamespace {
  NAMESPACE = 'reset-password',
}

export enum TypeParamsActionStaffRequest {
  REMOVE_STAFF = 'remove-staff',
  CHANGE_STADD_STATUS = 'change-staff-status',
}

export enum GameType {
  STRANGE_COLLECTING = 'STRANGE_COLLECTING',
  ADDICTIVE_MATCH = 'ADDICTIVE_MATCH',
}

export enum CategoryImage {
  avatar = 'avatar',
  items_image = 'items_image',
  main_background = 'main_background',
  page_background = 'page_background',
  notify_background = 'notify_background',
  gift_image = 'gift_image',
  logo_image = 'logo_image',
  reward = 'reward',
  feedback_image = 'feedback_image',
  feedback = 'feedback',
  requestNewTheme = 'request_new_theme',
}

export enum PermissionsType {
  papa_campaign_list = 'papa_campaign_list',
  papa_campaign_create = 'papa_campaign_create',
  papa_campaign_update = 'papa_campaign_update',
  papa_campaign_delete = 'papa_campaign_delete',
  papa_campaign_read = 'papa_campaign_read',
  papa_campaign_add_game = 'papa_campaign_add_game',
  papa_reward_list = 'papa_reward_list',
  papa_reward_create = 'papa_reward_create',
  papa_reward_update = 'papa_reward_update',
  papa_reward_delete = 'papa_reward_delete',
  papa_reward_read = 'papa_reward_read',
  papa_player_update = 'papa_player_update',
  papa_player_delete = 'papa_player_delete',
  papa_player_read = 'papa_player_read',
  papa_dashboard_read = 'papa_dashboard_read',
  papa_billing_read = 'papa_billing_read',

  papa_staff_invite = 'papa_staff_invite',
  papa_staff_read = 'papa_staff_read',
  papa_staff_update = 'papa_staff_update',
  papa_staff_delete = 'papa_staff_delete',
  papa_staff_list = 'papa_staff_list',
  papa_game_read = 'papa_game_read',
  papa_game_update = 'papa_game_update',
  papa_game_create = 'papa_game_create',
  papa_game_delete = 'papa_game_delete',
  papa_game_list = 'papa_game_list',
  papa_game_status_update = 'papa_game_status_update',
  papa_game_member_update = 'papa_game_member_update',
  papa_game_config_create = 'papa_game_config_create',
  papa_game_config_read = 'papa_game_config_read',
  papa_game_config_update = 'papa_game_config_update',
  papa_game_config_delete = 'papa_game_config_delete',
  papa_game_config_list = 'papa_game_config_list',
  papa_customer_read = 'papa_customer_read',
  papa_customer_update = 'papa_customer_update',
  papa_customer_delete = 'papa_customer_delete',
  papa_customer_list = 'papa_customer_list',
  papa_payment_read = 'papa_payment_read',
  papa_payment_update = 'papa_payment_update',
  papa_payment_list = 'papa_payment_list',
  papa_service_pack_read = 'papa_service_pack_read',
  papa_service_pack_update = 'papa_service_pack_update',
  papa_service_pack_delete = 'papa_service_pack_delete',
  papa_service_pack_list = 'papa_service_pack_list',
}

export enum TokenType {
  BEARER = 'Bearer',
}
export enum NotifyType {
  ALL = 0,
  UNREAD = 1,
}

export enum GameURLCONFI {
  TEXT = 'TEXT',
  INPUT = 'INPUT',
}

export enum PackageType {
  FREE = 'free',
  ADVANCE = 'prepaid',
  PAYASYOUGO = 'postpaid',
}

export enum PackageTypePayment {
  EMERALD = 'emerald',
  SAPPHIRE = 'sapphire',
  RUBY = 'ruby',
  DIAMOND = 'diamond',
}

export enum PackageName {
  EMERALD = 'Emerald',
  SAPPHIRE = 'Sapphire',
  RUBY = 'Ruby',
  DIAMOND = 'Diamond',
}

export enum PackageNamePaymentUpdate {
  EMERALD = 'Emeral',
  SAPPHIRE = 'Sapphire',
  RUBY = 'Ruby',
  DIAMOND = 'Diamond',
}

export enum PackageNameStaff {
  EMERALD = 'Emeralds',
  SAPPHIRE = 'Sapphire',
  RUBY = 'Ruby',
  DIAMOND = 'Diamond',
}
export enum ThemeQuality {
  NORMAL = 'Normal',
  GOOD = 'Good (recommended)',
  VERY_GOOD = 'Very good',
}

export enum RewardType {
  GIFT_CODE = 'GIFT_CODE',
  PHYSICAL = 'PHYSICAL',
}

export enum TypeUnitPrice {
  SERVICEPACK = 'service_pack',
  ASSERPACK = 'asset_pack',
  FEEDBACK = 'feedback',
  PARTNER = 'partner',
  REQUEST_THEME = 'request_theme',
  REPLY_CONTACT = 'reply_contact',
  REQUEST_PROMOTION = 'request_promotion'
}

export enum SupportCenterType {
  FEEDBACK = 'feedback',
  PARTNER = 'partner',
  REQUEST_THEME = 'request_theme',
  REPLY_CONTACT = 'reply_contact',
  REQUEST_PROMOTION = 'request_promotion'
}
