import { Box, Link, Typography } from '@mui/material';
import { withLoading } from 'app/components/HOC/WithLoading';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ForgotPasswordRequest } from 'types';
import { TypeNamespace } from 'types/Enum';
import path from '../../routes/path';
import { useSnackbarSlice } from '../SnackBar/slice';
import { BackgroundImage } from './components/BackgroundImage';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import VerifyEmail from './components/VerifyEmail';
import { useAuthSlice } from './slice';

// ----------------------------------------------------------------------
interface Props {
  setLoading?: Function;
}

const ForgotPassword = (props: Props) => {
  const { actions } = useAuthSlice();
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState<string>();
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const handleRequestPassword = useCallback(
    ({ email }) => {
      setLoading(true);
      const _email = email;
      const request: ForgotPasswordRequest = {
        email: _email,
        namespace: TypeNamespace.NAMESPACE,
      };
      dispatch(
        actions.forotPassword(request, (err?: any) => {
          if (!err) {
            setEmail(_email);
            dispatch(
              snackbarActions.updateSnackbar({
                message: `A verification link was sent to ${_email}`,
                type: 'success',
              })
            );
            setStep(step + 1);
            setLoading(false);
          } else {
            dispatch(
              snackbarActions.updateSnackbar({
                message: 'Please check the information.',
                type: 'error',
              })
            );
            setLoading(false);
            if (err.error === 'invalid_user') {
              dispatch(
                snackbarActions.updateSnackbar({
                  message: 'Please check the information.',
                  type: 'error',
                })
              );
            }
            setError(err);
            setLoading(false);
            if (err.status === 503) {
              dispatch(
                snackbarActions.updateSnackbar({
                  message: 'Please check the infomation',
                  type: 'error',
                })
              );
              setLoading(false);
            }
          }
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, dispatch, navigate, email]
  );

  const clearError = () => {
    setError(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#E2E2E2',
        height: '100%',
      }}
    >
      <BackgroundImage />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: { md: '55%', xs: '100%' },
          mx: 3,
        }}
      >
        {step === 1 && (
          <>
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'end',
                top: '40px',
                right: { md: '10px', xs: '0px' },
              }}
            >
              <Typography
                variant="body2"
                sx={(theme) => ({
                  display: 'flex',
                  fontSize: 14,
                  color: theme.palette.primary.main,
                })}
              >
                {'Already have an account?'}
                <Link
                  component={RouterLink}
                  style={{ textDecoration: 'none' }}
                  to={path.login}
                  className="link-forgot-password"
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 600, ml: 1 }}>
                    Sign in
                  </Typography>
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                m: { md: '210px 140px', xs: '0px' },
              }}
            >
              <Typography fontSize="18px">Forgot password</Typography>
              <Typography fontSize="14px" my={1} fontWeight={400}>
                Enter your email to continue.
              </Typography>
              <ForgotPasswordForm
                loading={loading}
                onSubmit={handleRequestPassword}
                error={error}
                onClearError={clearError}
              />
            </Box>
          </>
        )}
        {step === 2 && (
          <Box>
            <VerifyEmail
              email={email}
              title="Forgot password"
              description={
                <>
                  <Typography fontSize="12px" my={1} fontWeight={400}>
                    Click the link was sent to your email to reset the password.
                  </Typography>
                  <Typography fontSize="12px" my={1} fontWeight={400}>
                    If you don't see it, you may need to check your spam folder.
                  </Typography>
                </>
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withLoading(ForgotPassword);
