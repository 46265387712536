/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const GameRequestNewTheme = lazyLoad(
  () => import('./index'),
  (module) => module.GameRequestNewTheme
);
