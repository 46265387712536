/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Container, Snackbar, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import BreadCrumbs from 'app/components/BreadCrumbs';
import LoadingScreen from 'app/components/LoadingScreen';
import path from 'app/routes/path';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ListGame from './component/ListGame';
import ListGameDraft from './component/ListGameDraft';

import { useMainGameSliceGameVersion } from './slice';
import { selectMainGameList } from './slice/selectors';
import { MainGameTypes } from './slice/types';

// ----------------------------------------------------------------------

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  position: 'relative',
}));

// ----------------------------------------------------------------------

export function GameListVersion() {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const params = useParams();
  const { id, gameId } = params;
  const dispatch = useDispatch();
  const { actions } = useMainGameSliceGameVersion();
  const fetchFormData = useSelector(selectMainGameList);
  const { mainGameList, isLoadingTable } = fetchFormData;
  const listGameBase = mainGameList as MainGameTypes[] | undefined;
  const gameOffice = useMemo(() => {
    return listGameBase?.find((item) => item.active_version === 1) || null;
  }, [listGameBase]);
  const getListGame = () => {
    const gameIdVersion = Number(gameId);

    dispatch(
      actions.fetchMainGameListVersion({ gameIdVersion }, (err: any) => {
        if (!err) {
        }
      })
    );
  };

  React.useEffect(() => {
    getListGame();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const banner = (
    <Box style={{ width: '100%', height: '100%' }}>
      <Box sx={{ paddingLeft: '2%', paddingTop: '2vh', marginTop: { sm: '2vh', md: '0vh' } }}>
        <BreadCrumbs
          links={[
            {
              title: t('configManagement.gameList'),
              path: `${path.campaign}/${id}/list-game`,
            },
            {
              title: `${gameOffice?.game_name}`,
            },
            {
              title: t('configManagement.gameVersion'),
            },
          ]}
        />
      </Box>
      <Box>
        {' '}
        <Container
          sx={{ paddingLeft: { xs: '10%', sm: '2%' }, paddingRight: '8%', paddingTop: '2%', maxWidth: '100%' }}
        >
          <Typography
            variant="subtitle2"
            sx={(theme) => ({ color: theme.palette.grey[500_90], fontSize: 18 })}
            gutterBottom
          >
            {t('configManagement.official')}
          </Typography>
          <Stack>
            <ListGame gameList={mainGameList} getListGame={getListGame} />
          </Stack>
        </Container>
        <Container sx={{ paddingLeft: { xs: '10%', sm: '2%' }, paddingRight: '8%', maxWidth: '100%' }}>
          <Typography
            variant="subtitle2"
            sx={(theme) => ({ color: theme.palette.grey[500_90], fontSize: 18 })}
            gutterBottom
          >
            {t('configManagement.draff')}
          </Typography>
          <Stack>
            <ListGameDraft gameList={mainGameList} getListGame={getListGame} />
          </Stack>
        </Container>
      </Box>
    </Box>
  );

  if (isLoadingTable) return <LoadingScreen />;
  return (
    <Box sx={{ height: '100%' }}>
      <MainStyle>{banner}</MainStyle>
      <Snackbar
        sx={{ mt: '70px' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {t('')}
        </Alert>
      </Snackbar>
    </Box>
  );
}
