/**
 *
 * Asynchronously loads the component for CreateCampaign
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreateCampaign = lazyLoad(
  () => import('./index'),
  (module) => module.CreateCampaign
);
