import { Dialog } from '@mui/material';
import { MainGameTypes } from '../slice/types';

export interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  game: MainGameTypes;
  getListGame: () => void;
}

export default function ActionGame({ open }: Props) {
  return <Dialog open={open} fullWidth maxWidth={'xs'}></Dialog>;
}
