import { Box } from '@mui/material';
import React from 'react';

const LoadingScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '333px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src="/static/loader/spinner.svg" alt="" width={100} height={100} />
    </Box>
  );
};

export default React.memo(LoadingScreen);
