/* eslint-disable jsx-a11y/img-redundant-alt */
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Container, Dialog, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useProfile } from 'app/hooks';
import account from 'app/_mock/account';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAuthSlice } from 'app/pages/Auth/slice';
import UpdateProfile from 'app/pages/MyProfile/components/UpdateProfile';
import { UploadAvatar } from 'app/pages/MyProfile/components/UpdateProfile/UploadAvatar';

// ----------------------------------------------------------------------

interface Props {
  openDialog: boolean;
  handleCloseDialog: () => void;
}

export function MyProfile(props: Props) {
  const { openDialog, handleCloseDialog } = props;
  const { t } = useTranslation();
  const authSchema = Yup.object()
    .shape({
      name: Yup.string().matches(/\S/g, t('common.whiteSpace')),
    })
    .required();
  const {
    control,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(authSchema),
    mode: 'all',
  });
  const [isDisableButtonLogin, setIsdisableButtonLogin] = useState<boolean>(true);
  const [valueAvt, setValueAvt] = useState('');
  const user = useProfile();
  const { actions } = useAuthSlice();
  const dispatch = useDispatch();

  const refecthDataUser = () => {
    dispatch(actions.getUserInfo());
  };

  useEffect(() => {
    setValue('name', user?.name);
    if (valueAvt) {
      setIsdisableButtonLogin(false);
    } else {
      setIsdisableButtonLogin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, valueAvt]);

  const checkFullData = () => {
    if (!getValues('name') || user?.name === '') {
      setError('name', { message: t('common.fieldEmpty') });
      setIsdisableButtonLogin(true);
      return;
    }
    if (getValues('name').length > 50) {
      setError('name', { message: t('common.noMoreThan50Characters') });
      setIsdisableButtonLogin(true);
      return;
    }
    if (getValues('name')) {
      setIsdisableButtonLogin(false);
    } else {
      setIsdisableButtonLogin(true);
    }
  };

  const renderAvatar = () => {
    if (valueAvt || user?.avatar) {
      return valueAvt || user?.avatar;
    } else {
      return account.photoURL;
    }
  };

  const handleAvatar = (value: boolean) => {
    if (value) {
      setIsdisableButtonLogin(false);
      clearErrors('avatar');
    } else {
      setIsdisableButtonLogin(true);
      setError('avatar', { message: t('common.fileSizeLessThan3M') });
    }
  };

  return (
    <Dialog open={openDialog} fullWidth maxWidth="sm">
      <form>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px 24px 0',
            }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: 600 }} variant="subtitle2" noWrap color={'primary'}>
              {t('Edit profile')}
            </Typography>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Container
            sx={{
              padding: 3,
              maxWidth: '100%',
            }}
          >
            <Grid sx={{ justifyContent: 'center' }} container spacing={3}>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Box>
                  <Typography sx={{ fontSize: 14 }} variant="subtitle2" noWrap color={'primary'}>
                    {t('Profile photo')}
                  </Typography>
                  <Stack
                    sx={{
                      '& img': {
                        borderRadius: '50px',
                      },
                      marginTop: '12px',
                      marginBottom: '14px',
                    }}
                  >
                    <img src={renderAvatar()} alt="profile-photo" width={100} height={100} />
                  </Stack>
                  <UploadAvatar
                    setValueAvt={setValueAvt}
                    valueAvt={valueAvt}
                    handleAvatar={handleAvatar}
                    errors={errors}
                    isMultiple={false}
                  />

                  {/*------------------- Name ----------------*/}
                  {user?.name && (
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => {
                        return (
                          <TextField
                            sx={{ marginBottom: '30px' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={
                              <Typography
                                variant="subtitle2"
                                noWrap
                                color={'primary'}
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 400,
                                  mt: '0px',
                                  mb: '10px',
                                  display: 'flex',
                                  marginBottom: '30px',
                                }}
                              >
                                {t('fullName')}
                                <Typography
                                  sx={(theme) => ({
                                    fontSize: 15,
                                    fontWeight: 400,
                                    color: theme.palette.info.darker,
                                    margin: '1px 3px 0px 4px',
                                  })}
                                >
                                  *
                                </Typography>
                              </Typography>
                            }
                            {...field}
                            id="name"
                            size="small"
                            fullWidth
                            variant="filled"
                            defaultValue={user?.name}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors('name');
                            }}
                            helperText={errors?.name?.message}
                            onBlur={checkFullData}
                            error={errors?.name?.message}
                            inputProps={{
                              style: {},
                            }}
                            InputProps={{
                              style: {
                                marginBottom: '12px',
                              },
                            }}
                          />
                        );
                      }}
                    />
                  )}
                  {/* ------------------------Email--------------------- */}
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => {
                      return (
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={
                            <Typography
                              variant="subtitle2"
                              noWrap
                              color={'primary'}
                              sx={{ fontSize: 15, fontWeight: 400, mt: '0px', mb: '10px', display: 'flex' }}
                            >
                              {t('Email')}
                              <Typography
                                sx={(theme) => ({
                                  fontSize: 15,
                                  fontWeight: 400,
                                  color: theme.palette.info.darker,
                                  margin: '1px 3px 0px 4px',
                                })}
                              >
                                *
                              </Typography>
                            </Typography>
                          }
                          {...field}
                          id="email"
                          size="small"
                          fullWidth
                          variant="filled"
                          value={user?.email}
                          helperText={errors?.name?.message || t('validated.emailNotEdited')}
                          disabled
                          inputProps={{
                            style: {},
                          }}
                          InputProps={{}}
                        />
                      );
                    }}
                  />

                  <Stack sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <Button
                      sx={(theme) => ({
                        backgroundColor: theme.palette.secondary.lighter,
                        color: theme.palette.primary.main,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.light,
                        },
                        width: '90px',
                        display: 'flex',
                        height: '36px',
                        borderTopLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '0px',
                        mt: '20px',
                      })}
                      onClick={handleCloseDialog}
                    >
                      {t('Cancel')}
                    </Button>
                    {user && (
                      <UpdateProfile
                        isDisableButtonLogin={isDisableButtonLogin || Object.keys(errors).length > 0}
                        user={user}
                        nameInput={getValues('name')}
                        refecthDataUser={refecthDataUser}
                        avataURL={valueAvt}
                      />
                    )}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </form>
    </Dialog>
  );
}
