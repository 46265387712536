import { Fragment, memo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { usePaymentSlice } from 'app/pages/PaymentManagement/slice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectPaymentManagement } from 'app/pages/PaymentManagement/slice/selectors';
import path from 'app/routes/path';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import { LocalStorageService } from 'services';
import { PackageTypePayment } from 'types/Enum';
import { selectCampaign } from 'app/pages/HomePage/slice/selectors';
import moment from 'moment';

const MainStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    margin: '10px 0',
  },
}));

const RemainPlayTurn = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = usePaymentSlice();
  const { dataPlayTurnUsed } = useSelector(selectPaymentManagement);
  const { themeInfo } = useSelector(layoutsSelector);
  const fetchFormData = useSelector(selectCampaign);
  const { listCampaign } = fetchFormData;
  const [percentPlayTurnUsed, setPercentPlayTurnUsed] = useState<number>(0);
  const [openUpgrade, setOpenUpgrade] = useState<boolean>(false);
  const [checkShowPopup, setCheckShowPopup] = useState<boolean>(false);

  const dontShowPopup = LocalStorageService.get<boolean>(
    `${LocalStorageService.DONT_SHOW_POPUP_UPGRADE_PAYMENT}_${id}`
  );

  useEffect(() => {
    if (id) {
      dispatch(actions.getPlayTurnUsed({ campaign_id: id }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (listCampaign) {
      const dateNow = new Date();
      const endDate = moment(listCampaign?.package.expired_date).local();
      const totalDate = (endDate.valueOf() - dateNow.getTime()) * 0.00000001;
      if (totalDate <= 3 && location.pathname === `/campaigns/${id}/dashboard`) {
        setOpenUpgrade(true);
      } else {
        setOpenUpgrade(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCampaign]);

  useEffect(() => {
    if (dataPlayTurnUsed) {
      const purchased = dataPlayTurnUsed?.data.total_play_turns_purchased;
      const remaining = dataPlayTurnUsed?.data.total_play_turns_remaining;
      const percentUsed = (remaining / purchased) * 100;
      setPercentPlayTurnUsed(percentUsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPlayTurnUsed]);

  const handleCloseUpgradeMoney = () => {
    setOpenUpgrade(false);
  };

  const handleChangeCheckboxShow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckShowPopup(event.target.checked);
  };

  const handleDontShow = () => {
    if (checkShowPopup) {
      LocalStorageService.set(`${LocalStorageService.DONT_SHOW_POPUP_UPGRADE_PAYMENT}_${id}`, checkShowPopup);
    }
  };

  return (
    <Fragment>
      {dataPlayTurnUsed ? (
        <MainStyle>
          <Box
            sx={{
              display: 'inline-flex',
              justifyContent: 'space-around',
              padding: '0 10px',
              backgroundColor:
                percentPlayTurnUsed <= 20 || dataPlayTurnUsed.data.total_play_turns_remaining === 0
                  ? theme.palette.error.contrastText
                  : theme.palette.info.darker,
              borderRadius: 20,
            }}
          >
            <Typography sx={{ display: 'flex', width: 'auto' }}>
              <Typography>{t('common.remainPlayTurn')}:</Typography>
              <Typography sx={{ paddingLeft: '4px' }}>{dataPlayTurnUsed?.data.total_play_turns_remaining}</Typography>
            </Typography>
          </Box>
        </MainStyle>
      ) : (
        ''
      )}
      {openUpgrade && !dontShowPopup ? (
        <form>
          <Dialog open={openUpgrade}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px' }}>
              <Typography>{t('Extend package')}</Typography>
              <IconButton
                onClick={() => {
                  handleDontShow();
                }}
              ></IconButton>
            </Box>
            <Box sx={{ textAlign: 'center', padding: '0 32px' }}>
              <Typography>
                {t('payment.ContentPopupUpgrade', { value: listCampaign?.package.package_package_name })}
              </Typography>
            </Box>
            <Box sx={{ margin: '16px 24px 0' }}>
              <FormControlLabel
                control={<Checkbox checked={checkShowPopup} onChange={handleChangeCheckboxShow} defaultChecked />}
                label={t('common.dontShowPopupMeta')}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 24px' }}>
              <Button
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.secondary.contrastText,
                  mr: 2,
                }}
                onClick={() => {
                  handleCloseUpgradeMoney();
                  handleDontShow();
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: `${themeInfo?.bgColor} !important`,
                  '&:hover': {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
                onClick={() => {
                  navigate(`${path.campaign}/${id}/payment/service-pack`);
                  handleDontShow();
                  handleCloseUpgradeMoney();
                }}
              >
                {t('payment.upgradeNow')}
              </Button>
            </Box>
          </Dialog>
        </form>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default memo(RemainPlayTurn);
