import { call, put, takeLatest } from 'redux-saga/effects';
import { MemberService } from 'services';

import { DataCustomerAccountProps } from './types';
import { createCampaignActions as actions } from '.';

function* fetchStrongListData() {
  try {
    const result: DataCustomerAccountProps[] = yield call(MemberService.fetchStrongNotificationList);
    yield put(actions.strongNotificationSuccess(result));
  } catch (error) {}
}

export function* NavbarSaga() {
  yield takeLatest(actions.strongNotification.type, fetchStrongListData);
}
