/**
 *
 * Logo
 *
 */
import { Box, useTheme } from '@mui/material';
import * as React from 'react';

export function Logo() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        color: theme.palette.primary.main,
        textAlign: 'center',
      }}
    >
      <img src="/static/icons/Logo-sidebar.png" alt="logo" />
    </Box>
  );
}
