import { PayloadAction } from '@reduxjs/toolkit';
import { Pageable } from 'types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { FilterParams, ParamsRequest } from './../../../../types/Filter';
import { notificationSaga } from './saga';
import { DataListAllSupport, SupportCenterState } from './types';

export const initialState: SupportCenterState = {
  isLoadingAllList: true,
};

const slice = createSlice({
  name: 'supportCenter',
  initialState,
  reducers: {
    fetchAllListSupportCenter: {
      reducer(state) {
        return state;
      },
      prepare(params: FilterParams, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    fetchAllListSupportCenterSuccess: () => { },

    readSupportCenter: {
      reducer(state) {
        return state;
      },
      prepare(params: { support_center_id: number, type: string }, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    readSupportCenterSuccess: () => { },

    readAllSupportCenter: {
      reducer(state) {
        return state;
      },
      prepare(params: {type: string, campaign_id: number}, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    readAllSupportCenterSuccess: () => { },

    getAllListSupportCenter(state, action) { },
    getAllListNotificationSuccess: (state, action: PayloadAction<Pageable<DataListAllSupport>>) => {
      state.listSupportCenter = action.payload;
    },
  },
});

export const { actions: notificationActions } = slice;

export const useSupportCenterSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: notificationSaga });
  return { actions: slice.actions };
};
