import React from 'react';
import { Box, Button, IconButton, Popover, Typography, useTheme } from '@mui/material';
import IconAssetTheme from 'assets/table/icon-asset-theme.svg';
import BackgroundAsset from 'assets/background/background_asset.png';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function RequestNewTheme() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { id, gameId } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        sx={{
          padding: 0,
          img: {
            width: '64px',
            height: '64px',
          },
        }}
      >
        <img src={IconAssetTheme} alt="icon-asset-theme" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '410px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: 245, height: 165, padding: 1.5 }}>
            <Typography sx={{ mb: 3 }}>{t('requestNewThemeManagement.contentRequestNewTheme')}</Typography>
            <Button
              sx={{
                backgroundColor: theme.palette.secondary.contrastText,
                '&:hover': {
                  color: theme.palette.secondary.contrastText,
                },
              }}
              onClick={() => navigate(`/campaigns/${id}/list-game/${gameId}/request-new-theme`)}
            >
              {t('requestNewThemeManagement.requestNewTheme')}
            </Button>
          </Box>
          <Box
            sx={{
              width: 165,
              img: {
                width: '165px',
                height: '165px',
              },
            }}
          >
            <img src={BackgroundAsset} alt="background asset" />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
