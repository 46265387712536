/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useThemeState } from 'app/hooks';
import { useTranslation } from 'react-i18next';

import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

interface TypeColor {
  value: string;
  bgColor: string;
  label: string;
  index: number;
}

// ----------------------------------------------------------------------

interface Props {
  handleChangeTheme?: (value: any) => void;
}

export default function ThemeSwitchPopupMenu({ handleChangeTheme }: Props) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>();
  const { theme: customTheme, setMode } = useThemeState();
  const { t } = useTranslation();

  useEffect(() => {
    setValue(customTheme.value);
  }, [customTheme.value]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const COLORS = [
    {
      value: 'Gray',
      label: `${t('common.Gray')}`,
      bgColor: '#585858',
      textColor: '#ABABAB',
      textColorActive: '#FFFFFF',
      index: 0,
    },
    {
      value: 'Rust',
      label: `${t('common.Rust')}`,
      bgColor: '#F18200',
      textColor: '#ABABAB',
      textColorActive: '#FFFFFF',
      index: 1,
    },
    {
      value: 'Lavender',
      label: `${t('common.Lavender')}`,
      bgColor: '#998BDD',
      textColor: '#ABABAB',
      textColorActive: '#FFFFFF',
      index: 1,
    },
    {
      value: 'Ocean',
      label: `${t('common.Ocean')}`,
      bgColor: '#2DA6F7',
      extColor: '#ABABAB',
      textColorActive: '#FFFFFF',
      index: 2,
    },
    {
      value: 'Sea Green',
      label: `${t('common.SeaGreen')}`,
      bgColor: '#2E8A57',
      extColor: '#ABABAB',
      textColorActive: '#FFFFFF',
      index: 3,
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _theme = COLORS.filter((item) => item.value === (event.target as HTMLInputElement).value);
    handleChangeTheme && handleChangeTheme(_theme[0]);
    setMode(_theme[0]);
    setValue((event.target as HTMLInputElement).value);
    setOpen(false);
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleOpen}>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={(theme) => ({
            height: 44,
            color: theme.palette.primary.main,
            fontSize: '14px',
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          })}
        >
          <img
            src={'/static/icons/ThemeDefault.svg'}
            alt={'theme'}
            style={{
              width: 26,
              height: 26,
            }}
          />
        </IconButton>
        <Typography>{t('common.theme')}</Typography>
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 220,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {COLORS.map((option: TypeColor) => (
                <FormControlLabel
                  sx={{
                    mx: 0,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-around',
                  }}
                  key={option.index}
                  value={option.value}
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', width: '165px' }}>
                      <Box
                        sx={{
                          width: '24px',
                          heigth: '24px',
                          borderRadius: '50%',
                          backgroundColor: option.bgColor,
                          mr: 1,
                        }}
                      ></Box>
                      <Grid>{option.label}</Grid>
                    </Box>
                  }
                  labelPlacement="start"
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
      </MenuPopover>
    </>
  );
}
