/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const PaymentInvoice = lazyLoad(
  () => import('./index'),
  (module) => module.PaymentInvoice
);
