import { PayloadAction } from '@reduxjs/toolkit';
import {
  AuthParams,
  AuthResponse,
  ForgotPasswordRequest,
  ResendVerifyEmailRequest,
  ResetPasswordRequest,
  SignUpParams,
  TokenRequest,
} from 'types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { authSaga } from './saga';
import { AuthState } from './types';

export const initialState: AuthState = {};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<any>) {},
    login: {
      reducer(state) {
        return state;
      },
      prepare(params: AuthParams, meta?: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    signUpSuccess() {},
    signUp: {
      reducer(state) {
        return state;
      },
      prepare(params: SignUpParams, meta?: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    forotPasswordSuccess() {},
    forotPassword: {
      reducer(state) {
        return state;
      },
      prepare(params: ForgotPasswordRequest, meta?: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    resetPasswordSuccess() {},
    resetPassword: {
      reducer(state) {
        return state;
      },
      prepare(params: ResetPasswordRequest, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    resendVerifyEmailSuccess() {},
    resendVerifyEmail: {
      reducer(state) {
        return state;
      },
      prepare(params: ResendVerifyEmailRequest, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    logoutSuccess() {},
    logout: {
      reducer(state) {
        return state;
      },
      prepare(params, meta: (err: any) => void) {
        return { payload: params, meta };
      },
    },
    getUserInfo() {},
    getUserInfoSuccess(state, action) {
      state.userInfo = action.payload;
    },
    updateProfile: {
      reducer(state) {
        return state;
      },
      prepare(params: { name: string; avatar: string | null }, meta: (err: any) => void) {
        return { payload: params, meta };
      },
    },
    updateProfileSuccess: () => {},

    loginByGoogleSuccess(state, action: PayloadAction<AuthResponse>) {},
    loginByGoogle: {
      reducer(state) {
        return state;
      },
      prepare(params: TokenRequest, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
  },
});

export const { actions: authActions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};
