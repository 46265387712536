import { Avatar, Box, Divider, Grid, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SupportCenterType } from 'types/Enum';
import { createMinute, renderIcons, renderTypeSupportCenter } from 'utils/helpers';
import { ListSupportCenter } from '../slice/types';

interface Props {
  allListSupportCenter: ListSupportCenter[]
}

export default function DetailSupport(props: Props) {
  const { allListSupportCenter } = props
  const [supportCenterDetail, setSupportCenterDetail] = React.useState<ListSupportCenter>();
  const { id } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();

  React.useEffect(() => {
    if (window.location.href.includes('?spId')) {
      const id = window.location.href.split('?spId=')[1]
      setSupportCenterDetail(allListSupportCenter.filter((item: ListSupportCenter) => item.id === Number(id))[0])
    }
  }, [window.location.href, allListSupportCenter])

  const renderRequestFeedback = () => {
    return (
      <Box
        sx={{
          background: theme.palette.secondary.lighter,
          borderRadius: '20px',
          float: 'right',
          margin: '18px 32px 0',
          padding: '18px',
          width: '600px'
        }}
      >
        {supportCenterDetail &&
          <p style={{ fontSize: '14px', marginBottom: '18px', fontWeight: 600 }}>{renderTitle(supportCenterDetail.type)}</p>}
        <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('fullName')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '250px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {supportCenterDetail?.fullName}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>Email:</p>
          <p
            style={{
              fontSize: '12px',
              width: '250px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {supportCenterDetail?.email}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'start', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('reward.description')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '300px',
              display: '-webkit-box',
              WebkitLineClamp: 10,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {supportCenterDetail?.message || ""}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('reward.attachedPhoto')}:</p>
          {supportCenterDetail?.file && supportCenterDetail.file.length > 0 &&
            <div style={{ width: '60%' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {supportCenterDetail.file.map((data: string) => (
                  <img src={data} alt="" width={80} height={80} style={{ margin: '5px' }} />
                ))}
              </div>
            </div>
          }
        </Grid>
      </Box>
    );
  };

  const renderRequestPromotion = () => {
    return (
      <Box
        sx={{
          background: theme.palette.secondary.lighter,
          borderRadius: '20px',
          float: 'right',
          margin: '18px 32px 0',
          padding: '18px',
          width: '600px'
        }}
      >
        {supportCenterDetail &&
          <p style={{ fontSize: '14px', marginBottom: '18px', fontWeight: 600 }}>{renderTitle(supportCenterDetail.type)}</p>}
        <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.campaignID')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '250px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {id}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.campaignName')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '250px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {supportCenterDetail?.email}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'start', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.campaignUrl')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '300px',
              display: '-webkit-box',
              WebkitLineClamp: 10,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {supportCenterDetail?.campaign_url || ""}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'start', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.customerName')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '300px',
              display: '-webkit-box',
              WebkitLineClamp: 10,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {supportCenterDetail?.fullName || ""}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.campaignBanner')}:</p>
          <div style={{ width: '60%' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {supportCenterDetail?.banner_campaign && <img src={supportCenterDetail?.banner_campaign} alt="" width={175} height={120} style={{ margin: '5px' }} />}
            </div>
          </div>
        </Grid>
      </Box>
    )
  }

  const renderRequestNewTheme = () => {
    return (
      <Box
        sx={{
          background: theme.palette.secondary.lighter,
          borderRadius: '20px',
          float: 'right',
          margin: '18px 32px 0',
          padding: '18px',
          width: '600px'
        }}
      >
        {supportCenterDetail &&
          <p style={{ fontSize: '14px', marginBottom: '18px', fontWeight: 600 }}>{renderTitle(supportCenterDetail.type)}</p>}
        <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('fullName')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '250px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {supportCenterDetail?.fullName}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>Email:</p>
          <p
            style={{
              fontSize: '12px',
              width: '250px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {supportCenterDetail?.email}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.themeTopic')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '300px',
              display: '-webkit-box',
              WebkitLineClamp: 10,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {supportCenterDetail?.theme_topic}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'start', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.gameName')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '250px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {supportCenterDetail?.game_name || ""}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.themeStyle')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '300px',
              display: '-webkit-box',
              WebkitLineClamp: 6,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {supportCenterDetail?.theme_style}
          </p>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.attachedPhoto')}:</p>
          <div style={{ width: '60%' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {supportCenterDetail?.file && <img src={supportCenterDetail?.file || ""} alt="" width={175} height={120} style={{ margin: '5px' }} />}
            </div>
          </div>
        </Grid>
        <Divider sx={{ margin: '10px' }} />
        <Grid sx={{ display: 'flex', alignItems: 'start', marginLeft: '8px' }}>
          <p style={{ fontSize: '12px', fontWeight: 600, width: '180px' }}>{t('supportCenter.themeQuality')}:</p>
          <p
            style={{
              fontSize: '12px',
              width: '250px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {supportCenterDetail?.theme_quality || ""}
          </p>
        </Grid>
      </Box>
    )
  }

  const renderResponRequest = () => {
    return (
      <Box
        sx={{
          background: theme.palette.secondary.lighter,
          borderRadius: '20px',
          margin: '18px 32px 0',
          padding: '18px',
          width: '75%',
        }}
      >
        <p style={{ fontSize: '14px', marginBottom: '18px', fontWeight: 600 }}>Request {supportCenterDetail?.status.toLowerCase()}</p>
        <p style={{ fontSize: '12px' }}>
          <div dangerouslySetInnerHTML={{ __html: supportCenterDetail?.reply || "" }} />
        </p>
      </Box>
    );
  };

  const renderTypeDetail = (tyle: string) => {
    switch (tyle) {
      case SupportCenterType.FEEDBACK:
        return renderRequestFeedback();
      case SupportCenterType.REQUEST_PROMOTION:
        return renderRequestPromotion();
      case SupportCenterType.REQUEST_THEME:
        return renderRequestNewTheme();
      default:
        break;
    }
  }

  const renderTitle = (type: string) => {
    const title = renderTypeSupportCenter(type).toLowerCase();
    return <strong>{title.charAt(0).toUpperCase() + title.slice(1)}</strong>;
  }

  const renderDate = (date: string) => {
    if (date) {
      const currentDate = moment(date).format('DD/MM/YYYY, hh:mm A');
      return currentDate + " " + `(${createMinute(date)})`
    } else {
      return ""
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <Grid sx={{ display: 'flex', alignItems: 'center', padding: '26px' }}>
        <Avatar src={renderIcons(supportCenterDetail?.type || "")} />
        {supportCenterDetail?.type && <p style={{ marginLeft: '8px', fontSize: '18px' }}>{renderTitle(supportCenterDetail.type)}</p>}
      </Grid>
      <Divider />
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {renderTypeDetail(supportCenterDetail?.type || "")}
          <p
            style={{
              color: theme.palette.success.lighter,
              fontSize: '14px',
              float: 'right',
              display: 'flex',
              justifyContent: 'end',
              marginRight: '42px',
            }}
          >
            {renderDate(supportCenterDetail?.created_at || "")}
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', float: 'left' }}>
          {supportCenterDetail?.reply && renderResponRequest()}
          <p
            style={{
              color: theme.palette.success.lighter,
              fontSize: '14px',
              float: 'left',
              display: 'flex',
              margin: '0 42px 40px',
            }}
          >
            {renderDate(supportCenterDetail?.replyAt || "")}
          </p>
        </div>
      </div>
    </Box>
  );
}
