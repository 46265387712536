import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AuthorizationRouteCampaign from 'app/components/Permission/PermissionCampaign';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import path from 'app/routes/path';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionsType } from 'types/Enum';
import Duplicate from 'assets/background/duplicate.svg';
import { useMainGameSliceGameVersion } from '../slice';
import { DataMainGameProps, MainGameTypes } from '../slice/types';
import GameElement from './GameElement';

export interface Props {
  gameList?: DataMainGameProps;
  getListGame: () => void;
  rules?: RegisterOptions;
}

interface PropMoreMenu {
  item?: MainGameTypes;
}

export default function ListGame({ gameList, getListGame }: Props) {
  const listGameBase = gameList as MainGameTypes[] | undefined;
  const gameOffice = useMemo(() => {
    return listGameBase?.find((item) => item.active_version === 1) || null;
  }, [listGameBase]);
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const [openConfirmEditName, setopenConfirmEditName] = useState(false);
  const dispatch = useDispatch();
  const { actions } = useMainGameSliceGameVersion();
  const { actions: snackbarActions } = useSnackbarSlice();
  const navigate = useNavigate();
  const { themeInfo } = useSelector(layoutsSelector);
  const { control } = useForm({
    mode: 'all',
  });

  const [gameVersionName, setGameVersionName] = useState(() => {
    return gameOffice?.game_version_name || '';
  });

  useEffect(() => {
    gameOffice?.game_version_name && setGameVersionName(gameOffice?.game_version_name);
  }, [gameOffice]);

  const handleEdit = () => {
    const gameId = gameOffice?.game_id;
    const gameVersion_id = gameOffice?.id;
    navigate(`${path.campaign}/${id}/games/${gameId}/game-versions/${gameVersion_id}?at=gameVersions&type=official`);
  };

  const handleCancelDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setopenConfirmEditName(false);
  };

  const handleChangeName = () => {
    const gameId = gameOffice?.game_id;
    const gameVersion_id = gameOffice?.id;
    const game_version_name = gameVersionName;
    dispatch(
      actions.changeNameGameVersion({ gameId, gameVersion_id, game_version_name }, (err?: any) => {
        if (!err) {
          getListGame();
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('configManagement.changedVersionName')} `,
              type: 'success',
            })
          );
          setopenConfirmEditName(false);
        } else {
          if (err.message === 'The game version name must not be greater than 50 characters.') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('common.noMoreThan50Characters')}`,
                type: 'error',
              })
            );
          }
          if (err.message === 'name_version_must_unique') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('configManagement.thisNameDuplicated')}`,
                type: 'error',
              })
            );
          }
          if (err.message === 'The game version name field is required.') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('configManagement.theGameVersionNameFieldRequired')}`,
                type: 'error',
              })
            );
          }
        }
      })
    );
  };

  const handleOpenEditName = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setopenConfirmEditName(true);
  };

  const handleDuplicate = () => {
    const gameVersion_id = gameOffice?.id;
    const id_campaign = Number(id);
    dispatch(
      actions.DuplicateGameVersion({ id_campaign, gameVersion_id }, (err?: any) => {
        if (!err) {
          getListGame();
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('configManagement.duplicated')} ${gameOffice?.game_version_name}`,
              type: 'success',
            })
          );
        } else {
          if (err.message === 'limit_draft_versions') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('configManagement.canNotDuplicateThan5Versions')}`,
                type: 'error',
              })
            );
          }
        }
      })
    );
  };

  const MoreMenuOficall = ({ item }: PropMoreMenu) => {
    const totalDuplicate = Number(listGameBase?.length) - 1;
    return (
      <Fragment>
        <AuthorizationRouteCampaign campaignId={Number(id)} allowedPermissions={[PermissionsType.papa_game_update]}>
          <MenuItem
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            onClick={handleEdit}
          >
            <ListItemIcon>
              <Icon icon="solar:settings-linear" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={t('Edit configuration')} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </AuthorizationRouteCampaign>

        <AuthorizationRouteCampaign
          campaignId={Number(id)}
          allowedPermissions={[PermissionsType.papa_game_status_update]}
        >
          <MenuItem
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            onClick={handleOpenEditName}
          >
            <ListItemIcon>
              <Icon icon="carbon:edit" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={t('configManagement.rename')} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          <MenuItem
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            onClick={handleDuplicate}
          >
            <ListItemIcon>
              <img src={Duplicate} alt="" width="24px" height="24px" />
            </ListItemIcon>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <ListItemText primary={t('configManagement.duplicate')} primaryTypographyProps={{ variant: 'body2' }} />
              <Box
                sx={(theme) => ({
                  fontSize: '14px',
                  color: theme.palette.primary.main,
                })}
              >
                ({totalDuplicate}/5)
              </Box>
            </Box>
          </MenuItem>
        </AuthorizationRouteCampaign>
      </Fragment>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6} xl={4} lg={4} sx={{ display: { xs: 'flex' } }}>
        {gameOffice && <GameElement game={gameOffice} getListGame={getListGame} children={<MoreMenuOficall />} />}
      </Grid>
      <Dialog open={openConfirmEditName} fullWidth maxWidth={'xs'}>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <DialogTitle sx={(theme) => ({ color: theme.palette.primary.main, fontSize: '18px !important' })}>
            {t('configManagement.rename')}
          </DialogTitle>
          <IconButton
            onClick={handleCancelDelete}
            sx={{ color: `${themeInfo?.bgColor}`, paddingTop: '10px', marginBottom: '15px' }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>

        <DialogContent>
          <Typography>
            {/* ----------------------- */}
            <Controller
              name={'game_version_name'}
              control={control}
              rules={{
                required: String(t('common.fieldEmpty')),
                maxLength: {
                  value: 50,
                  message: t('common.noMoreThan50Characters'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    sx={{ marginTop: '20px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <Typography
                        variant="subtitle2"
                        noWrap
                        color={'primary'}
                        sx={{
                          fontSize: 15,
                          fontWeight: 400,
                          mt: '0px',
                          mb: '10px',
                          display: 'flex',
                          marginBottom: '30px',
                        }}
                      >
                        {t('versionName')}
                        <Typography
                          sx={(theme) => ({
                            fontSize: 15,
                            fontWeight: 400,
                            color: theme.palette.info.darker,
                            margin: '1px 3px 0px 4px',
                          })}
                        >
                          *
                        </Typography>
                      </Typography>
                    }
                    size="small"
                    fullWidth
                    variant="filled"
                    defaultValue={gameVersionName}
                    onChange={(e) => {
                      setGameVersionName(e.target.value);
                      field.onChange(e.target.value);
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{
                      style: {
                        marginBottom: '12px',
                      },
                    }}
                  />
                );
              }}
            />
            {/* 
            --------------------- */}
          </Typography>

          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Button
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.lighter,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
                width: '70px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
              onClick={handleCancelDelete}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={handleChangeName}
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.lighter,
                },
                width: '120px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
            >
              {t('configManagement.ok')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
//trang map game
