import { RequestPromotionData } from 'types/RequestPromotion';
import { createService } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const instance = createService(baseURL);

const requestPromotion = async (param: RequestPromotionData) => {
  try {
    const formData = new FormData();
    formData.append('banner_campaign', param.banner_campaign || '');
    formData.append('campaign_url', param.campaign_url || '');

    await instance.post(`/v1/products/campaigns/${param.campaign_id}/send-request-promotion`, formData);
    const _data = {
      status: true,
      message: '',
    };
    return _data;
  } catch (e: any) {
    const _data = {
      status: false,
      message: e.response.data.message,
    };
    return _data;
  }
};

export default { requestPromotion };
