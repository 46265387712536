export interface MainGameState {
  mainGameList?: DataMainGameProps;
  isLoadingTable?: boolean;
}

export interface MainGameTypes {
  id: number;
  game_name?: string;
  status?: number;
  game_types?: string;
  game_thumbnail?: string;
  video_instruction?: string;
  updated_at?: string;
  game_type: string;
}

export interface DataMainGameProps {
  data: MainGameTypes[];
  current_page: number;
  total: number;
  per_page: number;
  to: number;
}

export interface ResponseMainGame {
  success: boolean;
  data: DataMainGameProps;
  message: string;
}

export enum TypeGameAR {
  GAME_AR = 'GAME_AR',
}
