import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MemberService } from 'services';
import { CreateCampaignParam, DeleteCampaignParam, UpdateCampaignParam } from 'types/Campaign';

import { createCampaignActions as actions } from '.';

export function* createCampaign(action: PayloadAction<CreateCampaignParam, string, (error?: any) => void>) {
  try {
    const result: CreateCampaignParam = yield call(MemberService.createCampaign, action.payload);
    yield put(actions.createCampaignSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* updateCampaign(action: PayloadAction<UpdateCampaignParam, string, (error?: any) => void>) {
  try {
    const result: UpdateCampaignParam = yield call(MemberService.updateCampaign, action.payload);
    yield put(actions.updateCampaignSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* deleteCampaign(action: PayloadAction<DeleteCampaignParam, string, (error?: any) => void>) {
  try {
    const result: DeleteCampaignParam = yield call(MemberService.deleteCampaign, action.payload);
    yield put(actions.createCampaignSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* fetchCampaignData(action: PayloadAction<DeleteCampaignParam, string, (error?: any) => void>) {
  try {
    const result: CreateCampaignParam = yield call(MemberService.fetchCampaign, action.payload);
    yield put(actions.fetchCampaignSuccess(result));
    // action.meta({
    //   success: true,
    //   response: result,
    // })
  } catch (error: any) {
    action.meta({
      success: false,
      response: error.response,
    });
  }
}

export function* authSaga() {
  yield takeLatest(actions.createCampaign.type, createCampaign);
  yield takeLatest(actions.updateCampaign.type, updateCampaign);
  yield takeLatest(actions.deleteCampaign.type, deleteCampaign);
  yield takeLatest(actions.fetchCampaignData.type, fetchCampaignData);
}
