import { GameThemeParams } from 'app/pages/GameBaseManagement/GameConfiguration/slice/types';
import { FilterParams, Pageable } from 'types';
import { GameBaseRequest, GameBaseState, GameThemeState } from 'types/GameBase';
import { LeaderboardInformation } from 'types/Leaderboard';
import { serialize } from 'utils/helpers';
import { createService, createServiceNoToken } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const baseURL_Game = process.env.REACT_APP_API_URL_GAME;
const instance = createService(baseURL);
const instance_game = createService(baseURL_Game);
const instanceNoAuth = createServiceNoToken(baseURL);

const fetchGameBaseInformation = async (param: string): Promise<GameBaseState> => {
  const response = await instance.get(`/v1/products/no-auth/game-bases/${param}`);
  return response.data.data;
};

const editGameBaseInformation = async (param: GameBaseRequest) => {
  const response = await instance.post(
    `/v1/products/campaigns/${param.campaignId}/${param.gameVersion_id ? 'game-bases-draft' : 'game-bases'}/${
      param.gameVersion_id || param.gameId
    }`,
    param.data
  );
  return response.data.data;
};

const fetchGameThemes = async (gameBaseId: string): Promise<GameThemeState[]> => {
  const response = await instance.get(`/v1/products/game-bases/${gameBaseId}/game-themes`);
  return response.data.data;
};

const fetchGameThemeInfomation = async (param: GameThemeParams): Promise<GameThemeState> => {
  const response = await instance.get(`/v1/products/game-bases/${param.gameBaseId}/game-themes/${param.gameThemeId}`);
  return response.data.data;
};

const editGameThemeInformation = async (param: GameThemeParams): Promise<GameThemeState> => {
  const response = await instance.post(
    `/v1/products/game-bases/${param.gameBaseId}/game-themes/${param.gameThemeId}`,
    param.data
  );
  return response.data.data;
};

const fetchListLeaderBoardData = async (params?: FilterParams): Promise<Pageable<LeaderboardInformation>> => {
  const newParams = { ...params };
  const newParam = { ...params };
  delete newParam.page;
  delete newParam.perpage;
  delete newParams.campaign_id;
  delete newParams.game_id;
  const response = await instance_game.post(`/v1/games/leader-board?${serialize(newParams)}`, newParam);
  return response.data.data;
};

const fetchGameBaseInformationNoAuth = async (param: string): Promise<GameBaseState> => {
  const response = await instanceNoAuth.get(`/v1/products/no-auth/game-bases/${param}`);
  return response.data.data;
};

export default {
  fetchGameBaseInformation,
  editGameBaseInformation,
  fetchGameThemes,
  fetchGameThemeInfomation,
  editGameThemeInformation,
  fetchListLeaderBoardData,
  fetchGameBaseInformationNoAuth,
};
