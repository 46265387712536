import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { keycloakService, LocalStorageService } from 'services';
import {
  AuthParams,
  AuthResponse,
  ForgotPasswordRequest,
  ResendVerifyEmailRequest,
  ResetPasswordRequest,
  SignUpParams,
  TokenRequest,
  UserInfo,
} from 'types';
import { authActions as actions } from '.';

export function* login(action: PayloadAction<AuthParams, string, (error?: any) => void>) {
  try {
    const res: AuthResponse = yield call(keycloakService.login, action.payload);
    LocalStorageService.set(LocalStorageService.OAUTH_TOKEN, res.access_token);
    LocalStorageService.set(LocalStorageService.REFRESH_TOKEN, res.refreshToken);
    LocalStorageService.set(LocalStorageService.REMEMBER_ME, action.payload.remember_me);
    action.meta();
    yield put(actions.loginSuccess(res));
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* getUserInfo() {
  try {
    const userInfo: UserInfo = yield call(keycloakService.getUserInfo);
    LocalStorageService.set(LocalStorageService.PROFILE_SERVICE, userInfo);
    yield put(actions.getUserInfoSuccess(userInfo));
  } catch (err: any) {}
}

export function* logout(action: PayloadAction<any, string, (err?: any) => void>) {
  try {
    yield call(keycloakService.logout);
    LocalStorageService.removeItem(LocalStorageService.OAUTH_TOKEN);
    LocalStorageService.removeItem(LocalStorageService.REMEMBER_ME);
    LocalStorageService.removeAllItem();
    yield put(actions.logoutSuccess());
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* signUp(action: PayloadAction<SignUpParams, string, (error?: any) => void>) {
  try {
    yield call(keycloakService.signUp, action.payload);
    yield put(actions.signUpSuccess());
    action.meta();
  } catch (error: any) {
    action.meta(error.response);
  }
}

function* loginByGoogle(action: PayloadAction<TokenRequest, string, (error?: any) => void>) {
  try {
    const response: AuthResponse = yield call(keycloakService.loginByGoogle, action.payload);
    LocalStorageService.set(LocalStorageService.OAUTH_TOKEN, response.access_token);
    // LocalStorageService.set(LocalStorageService.REFRESH_TOKEN, response.refresh_token);
    yield put(actions.loginByGoogleSuccess(response));
    action.meta();
  } catch (error: any) {
    LocalStorageService.removeAllItem();
    action.meta(error.response?.data);
  }
}

function* forotPassword(action: PayloadAction<ForgotPasswordRequest, string, (error?: any) => void>) {
  try {
    yield call(keycloakService.forotPassword, action.payload);
    yield put(actions.forotPasswordSuccess());
    action.meta();
  } catch (error: any) {
    action.meta(error.response);
  }
}

function* resetPassword(action: PayloadAction<ResetPasswordRequest, string, (error?: any) => void>) {
  try {
    yield call(keycloakService.resetPassword, action.payload);
    yield put(actions.resetPasswordSuccess());
    action.meta();
  } catch (error: any) {
    action.meta(error.response);
  }
}

function* resendVerifyEmail(action: PayloadAction<ResendVerifyEmailRequest, string, (error?: any) => void>) {
  try {
    yield call(keycloakService.resendVerifyEmail, action.payload);
    yield put(actions.resendVerifyEmailSuccess());
    action.meta();
  } catch (error: any) {
    action.meta(error.response);
  }
}

export function* authSaga() {
  yield takeLatest(actions.login.type, login);
  yield takeLatest(actions.logout.type, logout);
  yield takeLatest(actions.signUp.type, signUp);
  yield takeLatest(actions.getUserInfo.type, getUserInfo);
  yield takeLatest(actions.loginByGoogle.type, loginByGoogle);
  yield takeLatest(actions.forotPassword.type, forotPassword);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(actions.resendVerifyEmail.type, resendVerifyEmail);
}
