import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { NavbarSaga } from './saga';

import { NavbarState, DataCustomerAccountProps } from './types';

export const initialState: NavbarState = {};

const slice = createSlice({
  name: 'navbarManagement',
  initialState,
  reducers: {
    strongNotification(state, action) {
      state.isLoading = true;
    },

    strongNotificationSuccess(state, action: PayloadAction<DataCustomerAccountProps[]>) {
      state.strongNoti = action.payload;
    },
  },
});

export const { actions: createCampaignActions } = slice;

export const useNavbarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: NavbarSaga });
  return { actions: slice.actions };
};
