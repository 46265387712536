import { FilterParams, PlayerExportParams, PlayerInfomation, PlayerParams } from 'types';
import { serialize, serializeExport } from './../../utils/helpers/index';
import { createService, downloadFileService } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const instance = createService(baseURL);
const instanceGetFile = downloadFileService(baseURL, 'application/json');
const fetchPlayerInformation = async (params?: FilterParams): Promise<PlayerInfomation> => {
  const newParams = { ...params };
  const response = await instance.post(`/v1/products/players?${serialize(newParams)}`);
  return response.data.data;
};

const fetchListDetailPlayer = async (params?: FilterParams): Promise<PlayerInfomation> => {
  const response = await instance.post(`/v1/products/players/detail`, params);
  return response.data.data;
};

const actionPlayer = async (params: PlayerParams): Promise<PlayerInfomation> => {
  const response = await instance.post(`/v1/products/players/change-status`, params);
  return response.data.data;
};
const actionExportPlayer = async (params: PlayerExportParams): Promise<PlayerExportParams> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  const response = await instanceGetFile.post(`/v1/products/players?${serializeExport(newParams)}`, {
    campaign_id: params.campaign_id,
  });
  return response.data;
};

export default { fetchPlayerInformation, fetchListDetailPlayer, actionPlayer, actionExportPlayer };
