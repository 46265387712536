import { Avatar, Box, Grid } from '@mui/material';
import React, { useRef } from 'react';
import styled from 'styled-components';

import { useNavigate, useParams } from 'react-router-dom';
import { createMinute, renderIcons, renderTypeSupportCenter } from 'utils/helpers';
import { ListSupportCenter } from '../slice/types';

const RootContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #223250;
  margin-top: 20px;
  margin-bottom: 26px;
  height: 48px;
  width: 100%;
  padding: 0 10px 0 10px;
  @media screen and (max-width: 590px) {
    display: inline;
  }
`;

const Search = styled.div`
  width: 100%;
  @media screen and (max-width: 580px) {
    width: 100%;
    margin-bottom: 10px;
  }

  display: inline-flex;
  background: #f4f4f4;
  align-items: center;
  border-radius: 8px;
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #f4f4f4;
  height: 48px;
  .search-icon {
    margin-left: 16px;
  }
  input {
    font-size: 16px;
    padding-left: 16px;
    border: none;
    height: 100%;
    width: 100%;
    outline: none;
    border-radius: 8px;
    background: #f4f4f4;
  }
`;

interface Props {
  sublListSupportCenter: ListSupportCenter[]
  setSubListSupportCenter: (sublListSupportCenter: ListSupportCenter[]) => void;
}

export default function AllListSupport(props: Props) {
  const { sublListSupportCenter, setSubListSupportCenter } = props;
  const [listSp, setListSp] = React.useState<ListSupportCenter[]>([]);
  const [valueInput, setValueInput] = React.useState();
  const inputRef = useRef<HTMLInputElement>(null);
  const rootContainerRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    setListSp(sublListSupportCenter)
  }, [sublListSupportCenter])

  const renderMessage = (notify: ListSupportCenter) => {
    if (notify?.reply) {
      return removeTags(" - " + notify?.reply || "") || "";
    } else {
      return ""
    }
  };

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dataSearch = e.target.value;
    const dataArr = [...sublListSupportCenter]
    if (dataSearch !== '') {
      setListSp(dataArr.filter((data) => data.type.includes(dataSearch)))
    } else {
      setListSp(dataArr)
    }
  };

  const removeTags = (str: string) => {
    const div = document.createElement('div');
    div.innerHTML = str;
    return div.textContent || div.innerText || '';
  };

  const renderTitle = (status: string, type: string) => {
    const title = (renderTypeSupportCenter(type) + " " + status).toLowerCase();
    return <strong>{title.charAt(0).toUpperCase() + title.slice(1)}</strong>;
  }

  const handleRedirectPage = (spId: number) => {
    navigate(`/campaigns/${id}/support-center?spId=${spId}`)
  };

  const renderItemSupport = (item: ListSupportCenter) => {
    return (
      <div style={{ width: '100%' }} onClick={() => handleRedirectPage(item.id)}>
        <Grid sx={(theme) => ({
          padding: '10px 20px 10px 20px', display: 'flex', width: '100%', justifyContent: 'space-between', '&:hover': {
            backgroundColor: theme.palette.primary.lighter,
            cursor: 'pointer'
          },
        })}>
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <Avatar src={renderIcons(item.type)} />
            <Grid sx={{ width: '220px', marginLeft: '10px' }}>
              <Grid item sx={{ fontSize: '14px', fontWeight: 600 }}>
                {renderTypeSupportCenter(item.type)}
              </Grid>
              <Grid
                item
                sx={{
                  fontSize: '12px',
                  maxWidth: '220px',
                  overflow: 'hidden',
                  WebkitLineClamp: '3',
                  display: '-webkit-box !important',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {renderTitle(item.status, item.type)} {renderMessage(item)}
              </Grid>
            </Grid>
          </div>
          <Grid item sx={(theme) => ({ fontSize: '12px', color: theme.palette.success.lighter })}>
            {createMinute(item.created_at)}
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Box
      sx={{
        width: '40%',
        boxShadow: '0px 10px 20px rgba(38, 51, 77, 0.15)',
        background: 'white',
        height: '100%',
      }}
    >
      <RootContainer>
        <Search className="input" ref={rootContainerRef}>
          <input
            type="text"
            placeholder={'Search'}
            onChange={handleChangeSearchInput}
            value={valueInput}
            ref={inputRef}
          />
          <img src="/static/images/search.svg" width={24} height={24} alt="" className="search-icon" />
        </Search>
      </RootContainer>
      <Grid container sx={{ width: '100%' }}>{listSp.map((item) => renderItemSupport(item))}</Grid>
    </Box>
  );
}
