import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { FilterParams, Pageable, ParamsRequest } from 'types';
import notificationService from 'services/api/notificationService';
import { DataListAllSupport, NotiResponse } from './types';

import { notificationActions as actions } from '.';

function* fetchAllListSupportCenter(action: PayloadAction<ParamsRequest, string, (error?: any) => void>) {
  try {
    const response: NotiResponse = yield call(notificationService.fetchAllListSupportCenter, action.payload);
    yield put(actions.fetchAllListSupportCenterSuccess());
    action.meta({
      success: true,
      res: response.data,
    });
  } catch (error: any) {
    action.meta({
      success: false,
      response: error.response,
    });
  }
}

function* readSupportCenter(action: PayloadAction<{ support_center_id: number, type: string }, string, (error?: any) => void>) {
  try {
    yield call(notificationService.readSupportCenter, action.payload);
    yield put(actions.readSupportCenterSuccess());
    action.meta({
      success: true,
    });
  } catch (error: any) {
    action.meta({
      success: false,
      response: error.response,
    });
  }
}

function* readAllSupportCenter(action: PayloadAction<{ type: string, campaign_id: number }, string, (error?: any) => void>) {
  try {
    yield call(notificationService.readAllSupportCenter, action.payload);
    yield put(actions.readAllSupportCenterSuccess());
    action.meta({
      success: true,
    });
  } catch (error: any) {
    action.meta({
      success: false,
      response: error.response,
    });
  }
}

function* getAllListSupportCenter(action: PayloadAction<FilterParams>) {
  try {
    const result: Pageable<DataListAllSupport> = yield call(notificationService.getAllListSupportCenter, action.payload);
    yield put(actions.getAllListNotificationSuccess(result));
  } catch (errors) { }
}

export function* notificationSaga() {
  yield takeLatest(actions.fetchAllListSupportCenter.type, fetchAllListSupportCenter);
  yield takeLatest(actions.readSupportCenter.type, readSupportCenter);
  yield takeLatest(actions.readAllSupportCenter.type, readAllSupportCenter);
  yield takeLatest(actions.getAllListSupportCenter.type, getAllListSupportCenter);
}
