import {
  DataFavouriteGame,
  DataPlayTurnInGame,
  DataRegistrantionsToday,
  DataRegistrantionsWeek,
} from 'app/pages/Dashboard/slice/types';
import { FilterParams } from 'types';
import { serialize } from 'utils/helpers';
import { createService } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const instance = createService(baseURL);
const baseUrlGame = process.env.REACT_APP_API_URL_GAME;
const instanceGame = createService(baseUrlGame);

const getRegistrantionsTodayData = async (params?: FilterParams): Promise<DataRegistrantionsToday> => {
  const response = await instance.get(
    `/v1/products/campaigns/${params?.campaign_id}/dashboard/number-registration-today`
  );
  return response.data;
};

const getRegistrantionsWeekData = async (params?: FilterParams): Promise<DataRegistrantionsWeek> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  delete newParams.page;
  delete newParams.perpage;
  delete newParams.orders;
  delete newParams.searchKeyTypes;
  const response = await instance.get(
    `/v1/products/campaigns/${params?.campaign_id}/dashboard/number-registration-once-week?${serialize(newParams)}`
  );
  return response.data;
};

const getPlayTurnInGame = async (params?: FilterParams): Promise<DataPlayTurnInGame> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  delete newParams.page;
  delete newParams.perpage;
  delete newParams.orders;
  delete newParams.searchKeyTypes;
  const response = await instanceGame.get(
    `/v1/games/overview-play-turn-by-game/${params?.campaign_id}?${serialize(newParams)}`
  );
  return response.data;
};

const getFavouriteGameData = async (params?: FilterParams): Promise<DataFavouriteGame> => {
  const response = await instanceGame.get(`/v1/games/favorite-game/${params?.campaign_id}`);
  return response.data.data;
};

export default {
  getRegistrantionsTodayData,
  getRegistrantionsWeekData,
  getPlayTurnInGame,
  getFavouriteGameData,
};
