import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface Links {
  title?: string;
  path?: string;
}

interface Props {
  links: Links[];
}

const RootBreadcrumdContainer = styled.div`
  display: flex;
  .title-breacrumbs {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    color: #585858;
  }
  .title-page {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    color: #585858;
  }
  .MuiBreadcrumbs-separator {
    margin-left: 0px;
    margin-right: 0px;
    .icon {
      margin-top: 3px;
    }
  }
`;

const BreadCrumbs = React.memo((props: Props) => {
  const { links } = props;

  return (
    <RootBreadcrumdContainer>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" className="icon" />} aria-label="breadcrumb">
        {links.map((item: Links, index: number) => (
          <Link
            key={index}
            variant="subtitle2"
            href={item.path}
            className={item.path ? 'title-breacrumbs' : 'title-page'}
          >
            {item?.title}
          </Link>
        ))}
      </Breadcrumbs>
    </RootBreadcrumdContainer>
  );
});

export default BreadCrumbs;
