import React, { useMemo, useState } from 'react';
import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material';
import { NotifyType } from 'types/Enum';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { createMinute } from 'utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import path from 'app/routes/path';
import { useNotificationSlice } from 'app/components/Notification/slice';
import SkeletonNotify from 'app/components/Notification/Skeleton';
import { ListNotify } from 'app/components/Notification/slice/types';
import { useTranslation } from 'react-i18next';

interface Props {
  typeNotify: NotifyType;
}

export default function NotifyList(props: Props) {
  const { typeNotify } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const { actions } = useNotificationSlice();
  const dispatch = useDispatch();
  const [isLoadingAllList, setIsLoadingAllList] = useState(false);
  const [page, setPage] = React.useState(1);
  const [sublListNotification, setSubListNotification] = useState<ListNotify[]>([]);
  const [totalListAllNoti, setTotalListAllNoti] = useState(0);
  const params = useParams();
  const { id } = params;
  const { t } = useTranslation();

  React.useEffect(() => {
    setIsLoadingAllList(true);
    if (typeNotify === NotifyType.ALL) {
      dispatch(
        actions.fetchAllListNotification({ page, perpage: 10 }, (err?: any) => {
          setSubListNotification([...err?.res.data]);
          setTotalListAllNoti(err.res.total);
          setIsLoadingAllList(false);
        })
      );
    } else {
      dispatch(
        actions.fetchAllListNotification({ page, perpage: 10, is_checked: 0 }, (err?: any) => {
          setSubListNotification([...err?.res.data]);
          setTotalListAllNoti(err.res.total);
          setIsLoadingAllList(false);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeNotify]);

  React.useEffect(() => {
    return () => {
      setPage(1);
    };
  }, []);

  const getMoreList = () => {
    if (typeNotify === NotifyType.ALL) {
      dispatch(
        actions.fetchAllListNotification({ page: page + 1, perpage: 10 }, (err?: any) => {
          if (err?.success) {
            setSubListNotification([...sublListNotification, ...err?.res.data]);
            setPage(page + 1);
            setIsLoadingAllList(false);
          }
        })
      );
    } else {
      dispatch(
        actions.fetchAllListNotification({ page: page + 1, perpage: 10, is_checked: 0 }, (err?: any) => {
          if (err?.success) {
            setSubListNotification([...sublListNotification, ...err?.res.data]);
            setPage(page + 1);
            setIsLoadingAllList(false);
          }
        })
      );
    }
  };

  const hasMore = useMemo(() => {
    if (totalListAllNoti && totalListAllNoti > sublListNotification.length) {
      return true;
    } else {
      return false;
    }
  }, [totalListAllNoti, sublListNotification]);

  // const handleRedirectPage = (type: string) => {
  //   if (type === 'App\\Notifications\\UpdateGameNotification') {
  //     navigate(path.gameList);
  //   } else {
  //     navigate(path.individualUsers);
  //   }
  // };

  const handleRedirectPage = (type: string) => {
    if (type === 'App\\NotificationsInviteStaffIntoCampaign') {
      navigate(`${path.campaign}/${id}/players`); //1
    } else if (type === 'App\\Notifications\\ChangeStatusRedemptionNotification') {
      navigate(`${path.campaign}/${id}/players`); //2
    } else if (type === 'App\\Notifications\\RewardRunOutOrSoldOut') {
      navigate(`${path.campaign}/${id}/reward/game-reward`); //3ok
    } else if (type === 'App\\Notifications\\UpdateGameNotification') {
      navigate(`${path.campaign}/${id}/list-game`); //4
      // } else if (type === 'App\\Notifications\\RewardRunOutOrSoldOut') {
      //   navigate(`${path.campaign}/${id}/players`);
      // } else if (type === 'App\\Notifications\\RewardRunOutOrSoldOut') {
      //   navigate(`${path.campaign}/${id}/players`);
    } else {
      navigate(`${path.campaign}/${id}/players`);
    }
  };
  const renderType = (type: string) => {
    if (type === 'App\\Notifications\\ChangeStatusRedemptionNotification') {
      return `${t('notification.changeStatusRedemp')}`;
    } else if (type === 'App\\NotificationsInviteStaffIntoCampaign') {
      return `${t('notification.inviteStaffIntoCampaign')}`;
    } else if (type === 'App\\Notifications\\RewardRunOutOrSoldOut') {
      return `${t('notification.rewardSoldOut')}`;
    } else if (type === 'App\\Notifications\\UpdateGameNotification') {
      return `${t('notification.updateGameNotification')}`;
    } else if (type === 'App\\Notifications\\InviteStaffIntoCampaign') {
      return `${t('notification.inviteStaffIntoCampaign')}`;
    } else {
      return;
    }
  };

  const renderMess = (message: string) => {
    switch (message) {
      case '[campaign]_account_requests_redemption':
        return `${t('notification.accountRequestsRedemption')}`;
      case '[campaign]_the_account_has_joined_the_campaign':
        return `${t('notification.theAccountJoined')}`;
      case '[campaign]_the_number_of_reward_is_about_to_sold_out':
        return `${t('notification.theNumberSoldOut')}`;
      case '[campaign]_the_game_has_been_edited':
        return `${t('notification.theGameEdited')}`;
      default:
        return 'Notification type';
    }
  };

  const handleReadNotification = (notiId: string, checked: number) => {
    if (!checked) {
      dispatch(actions.readNotification({ notiId }, (err?: any) => {}));
    }
  };

  return (
    <Box id="scrollableDiv">
      <InfiniteScroll
        dataLength={sublListNotification.length || 0}
        next={getMoreList}
        hasMore={hasMore}
        height={500}
        loader={<SkeletonNotify loadingNumber={2} />}
        scrollableTarget="scrollableDiv"
      >
        {isLoadingAllList ? (
          <SkeletonNotify loadingNumber={6} />
        ) : (
          sublListNotification.map((notify) => (
            <Grid
              container
              key={notify.id}
              p={1}
              bgcolor={notify.checked ? 'unset' : 'rgb(244, 244, 244)'}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                handleRedirectPage(notify.type);
                handleReadNotification(notify.id, notify.checked);
              }}
            >
              <Grid item sm={1.5}>
                <Avatar src={notify.avatar ? notify.avatar : ''} />
              </Grid>
              <Grid item sm={8}>
                <Typography fontSize={'14px'} lineHeight={'18px'} color="rgb(88, 88, 88)" fontWeight={600}>
                  {/* {notify.type} */}
                  {renderType(notify.type)}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: '14px',
                    lineHeight: '18px',
                    background: theme.palette.primary.main,
                  })}
                  mt={0.5}
                >
                  {renderMess(notify.message)}
                </Typography>
              </Grid>
              <Grid item sm={2.5} display={'flex'} flexDirection={'column'} alignItems={'self-end'}>
                <Typography fontSize={'14px'} lineHeight={'17px'} color="rgb(88, 88, 88)" fontWeight={500}>
                  {createMinute(notify.created_at)}
                </Typography>
                {!notify.checked && (
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      bgcolor: theme.palette.primary.contrastText,
                      mt: 1,
                      mr: 1,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          ))
        )}
      </InfiniteScroll>
    </Box>
  );
}
