import React, { useMemo, useState } from 'react';
import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { NotifyType, PackageType, RoleUserAddStaff } from 'types/Enum';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { createMinute } from 'utils/helpers';
import { useProfile } from 'app/hooks';

import { useNavigate } from 'react-router-dom';
import path from 'app/routes/path';
import { get } from 'lodash';
import { useNotificationSlice } from '../slice';
import { ListNotify } from '../slice/types';
import SkeletonNotify from '../Skeleton';

interface Props {
  typeNotify: NotifyType;
  handleClose: () => void;
  campaignId?: number;
}

export default function NotifyList(props: Props) {
  const { typeNotify, handleClose, campaignId } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { actions } = useNotificationSlice();
  const dispatch = useDispatch();
  const [isLoadingAllList, setIsLoadingAllList] = useState<boolean>();
  const [page, setPage] = React.useState(1);
  const [sublListNotification, setSubListNotification] = useState<ListNotify[]>([]);
  const [totalListAllNoti, setTotalListAllNoti] = useState(0);
  const userId = useProfile();

  React.useEffect(() => {
    if (typeNotify === NotifyType.ALL) {
      setIsLoadingAllList(true);
      dispatch(
        actions.fetchAllListNotification(
          { page, perpage: 10, campaign_id: !!campaignId ? campaignId?.toString() : '' },
          (err?: any) => {
            setSubListNotification([...err?.res.data]);
            setTotalListAllNoti(err.res.total);
            setIsLoadingAllList(false);
          }
        )
      );
    } else {
      setIsLoadingAllList(true);
      dispatch(
        actions.fetchAllListNotification(
          { page, perpage: 10, is_checked: 0, campaign_id: !!campaignId ? campaignId?.toString() : '' },
          (err?: any) => {
            setSubListNotification([...err?.res.data]);
            setTotalListAllNoti(err.res.total);
            setIsLoadingAllList(false);
          }
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeNotify, campaignId]);

  React.useEffect(() => {
    return () => {
      setPage(1);
    };
  }, [typeNotify]);

  const getMoreList = () => {
    if (typeNotify === NotifyType.ALL) {
      dispatch(
        actions.fetchAllListNotification({ page: page + 1, perpage: 10 }, (err?: any) => {
          if (err?.success) {
            setSubListNotification([...sublListNotification, ...err?.res.data]);
            setPage(page + 1);
            setIsLoadingAllList(false);
          }
        })
      );
    } else {
      dispatch(
        actions.fetchAllListNotification({ page: page + 1, perpage: 10, is_checked: 0 }, (err?: any) => {
          if (err?.success) {
            setSubListNotification([...sublListNotification, ...err?.res.data]);
            setPage(page + 1);
            setIsLoadingAllList(false);
          }
        })
      );
    }
  };

  const hasMore = useMemo(() => {
    if (totalListAllNoti && totalListAllNoti > sublListNotification.length) {
      return true;
    } else {
      return false;
    }
  }, [totalListAllNoti, sublListNotification]);

  const handleRedirectPage = (type: string, campaign_id: string, game_id: string, role: string, message: string) => {
    switch (type) {
      case 'App\\Notifications\\InviteStaffIntoCampaign':
        return navigate(`${path.campaign}/${campaign_id}/staffs`);
      case 'App\\Notifications\\ChangeRoleStaffInCampaign':
        if (message === '[campaign]_your_account_has_been_remove_at_campaign') {
          return navigate(`${path.homePage}`);
        } else {
          return navigate(`${path.campaign}/${campaign_id}/staffs`);
        }
      case 'App\\Notifications\\ChangeStatusRedemptionNotification':
        return navigate(`${path.campaign}/${campaign_id}/reward/redemption`);
      case 'App\\Notifications\\RewardRunOutOrSoldOut':
        return navigate(`${path.campaign}/${campaign_id}/reward/game-reward`);
      case 'App\\Notifications\\UpdateGameNotification':
        return navigate(`${path.campaign}/${campaign_id}/list-game/${game_id}`);
      case 'App\\Notifications\\UpdateCampaign':
        if (message === '[campaign]_has_deleted') {
          return false;
        } else {
          return navigate(`${path.homePage}`);
        }
      case 'App\\Notifications\\UpdatePackage':
        return navigate(`${path.campaign}/${campaign_id}/payment/invoice`);
      default:
        return navigate(`${path.campaign}/${campaign_id}/players`);
    }
  };

  const renderType = (type: string) => {
    switch (type) {
      case 'App\\Notifications\\InviteStaffIntoCampaign':
        return `${t('notification.inviteStaffIntoCampaign')}`;
      case 'App\\Notifications\\ChangeStatusRedemptionNotification':
        return `${t('notification.changeStatusRedemp')}`;
      case 'App\\Notifications\\RewardRunOutOrSoldOut':
        return `${t('notification.rewardRunSoldOut')}`;
      case 'App\\Notifications\\UpdateGameNotification':
        return `${t('notification.gameNotification')}`;
      case 'App\\Notifications\\UpdateCampaign':
        return `${t('notification.updateCampaign')}`;
      case 'App\\Notifications\\ChangeRoleStaffInCampaign':
        return `${t('notification.changeRoleStaff')}`;
      case 'App\\Notifications\\UpdatePackage':
        return `${t('notification.updatePackage')}`;
      default:
        return 'Notification type';
    }
  };

  const renderMessage = (notify: ListNotify) => {
    switch (notify.message) {
      case '[campaign]_the_account_has_joined_the_campaign':
        if (Number(notify.data.staff_id) === userId?.id) {
          return `[${notify.data.campaign_name}] ${t('notification.YourWithRole')} "${`${t(
            `common.${notify.data.staff_role}`
          )}`}"
        `;
        } else {
          return `[${notify.data.campaign_name}] ${t('notification.theAccount')} "${notify.data.staff_email}" ${t(
            'notification.theAccountJoined'
          )}`;
        }

      case '[campaign]_your_account_has_been_change_status_at_campaign':
        if (notify.data.status) {
          return `[${notify.data.campaign_name}] ${t('notification.activatedAtCampaign')}`;
        } else {
          return `[${notify.data.campaign_name}] ${t('notification.deactivatedAtCampaign')}`;
        }
      case '[campaign]_your_account_has_been_remove_at_campaign':
        return `[${notify.data.campaign_name}] ${t('notification.removeAtCampaign')}`;
      case '[campaign]_player_received_a_gift_from_the_game':
        return `[${notify.data.campaign_name}] ${t('notification.player')} "${
          notify.data.player_name == null ? 'Player' : notify.data.player_name
        }" ${t('notification.receivedGift')} "${notify.data.game_name}"`;
      case '[campaign]_account_requests_redemption':
        return `[${notify.data.campaign_name}] ${t('notification.theAccount')} "${notify.data.player_name}" ${t(
          'notification.requestRedemption'
        )}`;
      case '[campaign]_the_number_of_reward_is_about_to_sold_out':
        return `[${notify.data.campaign_name}] ${t('notification.theNumberOfReward')} "${notify.data.reward_name}" ${t(
          'notification.isSoldOut'
        )}`;
      case '[campaign]_the_number_of_reward_is_about_to_run_out':
        return `[${notify.data.campaign_name}] ${t('notification.theNumberOfReward')} "${notify.data.reward_name}" ${t(
          'notification.isRunOut'
        )}`;
      case '[campaign]_the_game_has_been_edited':
        return `[${notify.data.campaign_name}] ${t('notification.theGame')} "${notify.data.game_name}" ${t(
          'notification.editGameNotification'
        )}`;
      case '[campaign]_is_coming_to_an_end':
        return `[${notify.data.campaign_name}] ${t('notification.comingEnd')}`;
      case '[campaign]_is_coming_soon':
        return `[${notify.data.campaign_name}] ${t('notification.comingSoon')}`;
      case '[campaign]_has_ended':
        return `[${notify.data.campaign_name}] ${t('notification.hasEnded')}`;
      case '[campaign]_has_been_paused':
        return `[${notify.data.campaign_name}] ${t('notification.hasPause')}`;
      case '[campaign]_has_been_resumed':
        return `[${notify.data.campaign_name}] ${t('notification.hasResumed')}`;
      case '[campaign]_has_deleted':
        return `[${notify.data.campaign_name}] ${t('notification.deleteCampaign')}`;
      case '[campaign]_your_account_permission_has_been_change_to':
        return `[${notify.data.campaign_name}] ${t('notification.changeRole')} "${get(
          RoleUserAddStaff,
          notify.data.role
        )}"`;
      case '[campaign]_your_package_has_been_limited':
        if (notify.data.package_type === PackageType.FREE) {
          return `[${notify.data.campaign_name}] ${t('notification.packageLimited')}`;
        } else {
          return `[${notify.data.campaign_name}] ${t('notification.packageAdvanceLimited')}`;
        }
      case '[campaign]_your_package_is_about_to_reach_its_limit':
        if (notify.data.package_type === PackageType.FREE) {
          return `[${notify.data.campaign_name}] ${t('notification.packageFreeTwentyPercent')}`;
        } else {
          return `[${notify.data.campaign_name}] ${t('notification.packageAdvanceTwentyPercent')}`;
        }
      default:
        return 'Notification message';
    }
  };

  const renderIcons = (type: string) => {
    switch (type) {
      case 'App\\Notifications\\InviteStaffIntoCampaign':
        return '/static/icons-notification/icon-staff.png';
      case 'App\\Notifications\\ChangeStatusRedemptionNotification':
        return '/static/icons-notification/icon-reward.png';
      case 'App\\Notifications\\RewardRunOutOrSoldOut':
        return '/static/icons-notification/icon-reward.png';
      case 'App\\Notifications\\UpdateGameNotification':
        return '/static/icons-notification/icon-game.png';
      case 'App\\Notifications\\UpdateCampaign':
        return '/static/icons-notification/icon-campaign.png';
      case 'App\\Notifications\\ChangeRoleStaffInCampaign':
        return '/static/icons-notification/icon-staff.png';
      case 'App\\Notifications\\UpdatePackage':
        return '/static/icons-notification/icon-payment.png';
      default:
        return '';
    }
  };

  const handleReadNotification = (notiId: string, checked: number) => {
    if (!checked) {
      dispatch(actions.readNotification({ notiId }, (err?: any) => {}));
    }
  };

  const handleReadAllListNotification = () => {
    dispatch(
      actions.readAllNotification({}, (res?: any) => {
        if (res?.success) {
          dispatch(
            actions.fetchAllListNotification({ page, perpage: 10 }, (err?: any) => {
              setSubListNotification([...err?.res.data]);
              setTotalListAllNoti(err.res.total);
              setIsLoadingAllList(false);
            })
          );
        }
      })
    );
  };

  return (
    <Box id="scrollableDiv">
      <InfiniteScroll
        dataLength={sublListNotification.length || 0}
        next={getMoreList}
        hasMore={hasMore}
        height={500}
        loader={<SkeletonNotify loadingNumber={2} />}
        scrollableTarget="scrollableDiv"
      >
        {isLoadingAllList ? (
          <SkeletonNotify loadingNumber={6} />
        ) : (
          sublListNotification.map((notify, index) => (
            <Grid
              container
              key={notify.id + index}
              p={1}
              bgcolor={notify.checked ? 'unset' : 'rgb(244, 244, 244)'}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexWrap: 'nowrap',
              }}
              onClick={() => {
                handleRedirectPage(
                  notify.type,
                  notify.data.campaign_id,
                  notify.data.game_id,
                  notify.data.role,
                  notify.message
                );
                handleReadNotification(notify.id, notify.checked);
                handleClose();
              }}
            >
              <Grid item sm={1.5}>
                <Avatar src={notify.avatar ? notify.avatar : renderIcons(notify.type)} />
              </Grid>
              <Grid item sm={8}>
                <Typography fontSize={'14px'} lineHeight={'18px'} color={theme.palette.primary.main} fontWeight={600}>
                  {renderType(notify.type)}
                </Typography>
                <Typography fontSize={'14px'} lineHeight={'18px'} color={theme.palette.primary.main} mt={0.5}>
                  {renderMessage(notify)}
                </Typography>
              </Grid>
              <Grid item sm={2.5} display={'flex'} flexDirection={'column'} alignItems={'self-end'}>
                <Typography fontSize={'12px'} lineHeight={'17px'} color={theme.palette.primary.main} fontWeight={500}>
                  {createMinute(notify.created_at)}
                </Typography>
                {!notify.checked && (
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      bgcolor: theme.palette.primary.contrastText,
                      mt: 1,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          ))
        )}
      </InfiniteScroll>
      <Typography
        fontSize={'12px'}
        lineHeight={'15px'}
        fontWeight={600}
        color={theme.palette.primary.main}
        my={2}
        mr={1.5}
        textAlign={'end'}
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={handleReadAllListNotification}
      >
        {t(translations.notification.seeAll)}
      </Typography>
    </Box>
  );
}
