import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack, styled, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ForgotPasswordRequest } from 'types';
import { EMAIL_REGEX } from 'utils/helpers/regex';
import * as Yup from 'yup';

export const RootStyle = styled('div')({
  '& .MuiFilledInput-root': {
    borderRadius: '8px',
    background: '#FFFFFF',
  },
  '& .MuiFilledInput-root:after': {
    right: 'unset',
    border: '1px solid  #005FC5',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root.Mui-error:after': {
    border: '1px solid  #FF4842',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root:before': {
    right: 'unset',
    content: '""',
  },
  '& .MuiFormControl-root:after': {
    border: '1px solid  #005FC5',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
});

// ----------------------------------------------------------------------
interface Props {
  onSubmit: (values: ForgotPasswordRequest) => void;
  error?: any;
  onClearError?: () => void;
  loading: boolean;
}

const authSchema = Yup.object()
  .shape({
    email: Yup.string().required('Please enter your email!').email('Invalid email'),
  })
  .required();

export default function ForgotPasswordForm(props: Props) {
  const { onSubmit, error, onClearError, loading } = props;
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm<ForgotPasswordRequest>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(authSchema),
    mode: 'all',
  });
  const [isDisableButtonLogin, setIsdisableButtonLogin] = useState<boolean>(true);

  const checkFullData = () => {
    if (getValues('email') && !EMAIL_REGEX.test(getValues('email'))) {
      setIsdisableButtonLogin(true);
      setError('email', { message: 'Wrong email format!' });
      return;
    }
  };

  return (
    <RootStyle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  placeholder={'Email address'}
                  label={'Email'}
                  error={!!errors?.email || error?.data?.message === 'send_request_reset_password_fail'}
                  helperText={error ? "Couldn't find your account" : errors?.email?.message}
                  onChange={(e) => {
                    if (EMAIL_REGEX.test(e.target.value)) {
                      setIsdisableButtonLogin(false);
                    }
                    field.onChange(e);
                    clearErrors('email');
                    onClearError && onClearError();
                  }}
                  onBlur={(e: any) => {
                    field.onChange(e.target.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '));
                    checkFullData();
                  }}
                />
              );
            }}
          />
        </Stack>
        <LoadingButton
          loading={loading}
          type="submit"
          fullWidth
          disabled={isDisableButtonLogin}
          sx={(theme) => ({
            backgroundColor: isDisableButtonLogin ? '#EAECEF' : '#585858',
            color: '#FFFFFF',
            mt: 5,
            padding: '20px 0',
            fontWeight: '700',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            bgcolor: '#585858',
            height: '36px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '0px',
            borderTopRightRadius: '0px',
          })}
        >
          Continue
        </LoadingButton>
      </form>
    </RootStyle>
  );
}
