/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from '@iconify/react';
import { Avatar, Box, CircularProgress, FormHelperText, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileService } from 'services';
import { FileUpload } from 'types';
interface Props {
  error?: string;
  fileIndex?: number;
  handleFilesCallback?: (file: string, field: any, multiple?: boolean) => void;
  removeFile: (index?: number, field?: any) => void;
  field: any;
  fileProp?: string;
  multiple?: boolean;
  width?: string;
  height?: string;
  extensions?: string;
  categoryImage?: string;
  validateFiles?: (files: File, field: any, messageError?: string) => void;
  setError?: any;
  isBanner?: boolean;
}

interface State {
  file: string;
  loading: boolean;
}

const initState = {
  file: '',
  loading: false,
};

const UploadFile = (props: Props) => {
  const {
    error,
    handleFilesCallback,
    field,
    fileProp,
    multiple,
    removeFile,
    fileIndex,
    width,
    height,
    extensions,
    categoryImage = 'main_background',
    validateFiles,
    setError,
    isBanner,
  } = props;
  const [state, setState] = React.useState<State>(initState);
  const { file, loading } = state;
  const { t } = useTranslation();

  React.useEffect(() => {
    setState({
      ...state,
      file: fileProp || '',
    });
  }, [fileProp]);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const elm = e.target;
    if (!elm.files) return;
    const { files } = elm;

    let error;
    if (categoryImage === 'reward') {
      error = validateFiles?.(files[0], field) || null;
      if (error) {
        return;
      } else {
        const image = new Image();
        image.src = URL.createObjectURL(files[0]);
        image.onload = function () {
          if (image.width !== image.height) {
            const message = t('The image must have the same width and height');
            setError(field.name, {
              message,
            });
            return;
          } else {
            error = validateFiles?.(files[0], field) || null;
            if (error) {
              return;
            }

            UploadFile(files[0]);
          }
        };
      }
    } else {
      error = validateFiles?.(files[0], field) || null;
      if (error) {
        return;
      }

      UploadFile(files[0]);
    }
  };

  const UploadFile = async (fileUpload: File) => {
    setState({
      ...state,
      loading: true,
    });
    const params: FileUpload = {
      category_image: categoryImage,
      image: fileUpload,
      width,
      height,
      extensions,
    };
    const response = await FileService.fetchUrlImages(params);
    setState({
      ...state,
      loading: false,
    });

    if (!response.status) {
      validateFiles && validateFiles(fileUpload, field, response.data);
    } else {
      handleFilesCallback && handleFilesCallback(response.data.data.imageUrl, field, multiple);
    }
  };

  const onRemoveFile = () => {
    removeFile(fileIndex, field);
  };

  const renderUpload = React.useMemo(() => {
    let width = Number.parseInt(props?.width || '64');
    let height = Number.parseInt(props?.height || '64');
    if (width > 300 || width < 64) {
      const newW = width > 300 ? 300 : 64;
      height = (newW * height) / width;
      width = newW;
    }
    if (height > 300 || height < 64) {
      const newH = height > 300 ? 300 : 64;
      width = (newH * width) / height;
      height = newH;
    }

    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width, height }}>
          <CircularProgress sx={{ margin: 'auto' }} size={50} />
        </Box>
      );
    }

    if (file) {
      return (
        <Box
          sx={(theme) => ({
            position: 'relative',
            height,
            width,
            background: theme.palette.success.darker,
            border: '1px solid',
            borderRadius: '4px !important',
          })}
        >
          <Avatar
            variant="rounded"
            src={file}
            alt="images"
            sx={{
              height: '100%',
              width: '100%',
              '& img': {
                objectFit: 'cover',
              },
            }}
          />
          <Box position="absolute" top={5} right={5} sx={{ cursor: 'pointer' }} onClick={onRemoveFile}>
            <Icon icon="carbon:close-filled" width="20px" height="20px" />
          </Box>
        </Box>
      );
    }
    return isBanner ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton
          sx={(theme) => ({
            position: 'relative',
            fontSize: '12px',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.grey[100]}`,
            borderRadius: '10px 0',
            width: '84px',
            fontFamily: 'Lexend',
            height: '24px',
            color: theme.palette.primary.main,
          })}
          component="label"
        >
          <input hidden type="file" onChange={onChange} accept={extensions} />
          <Icon icon="carbon:add" color="#585858" /> {t('Upload')}
        </IconButton>
      </Box>
    ) : (
      <IconButton
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: (theme) => theme.palette?.text?.secondary,
          height,
          width,
          border: (theme) => `1px solid ${theme.palette?.text?.secondary}`,
          borderRadius: '4px',
          background: theme.palette.primary.main,
        })}
        component="label"
      >
        <input hidden type="file" onChange={onChange} accept={extensions} />
        <img alt="upload-file" src={'/static/icons/icon-upload.svg'} width="13px" height="13px" />
      </IconButton>
    );
  }, [file, loading]);

  const renderError = React.useMemo(() => {
    if (error) {
      return (
        <FormHelperText
          sx={{
            mt: 1,
            fontSize: 14,
          }}
          error
        >
          {error}
        </FormHelperText>
      );
    }
    return null;
  }, [error]);

  return (
    <Box mr={2} mt={2}>
      {renderUpload}
      {renderError}
    </Box>
  );
};

export default UploadFile;
