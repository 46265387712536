import { LoadingButton } from '@mui/lab';
import { Box, Link, Typography } from '@mui/material';
import { withLoading } from 'app/components/HOC/WithLoading';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import path from 'app/routes/path';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { ForgotPasswordRequest } from 'types';
import { TypeNamespace } from 'types/Enum';
import { useAuthSlice } from '../../slice';

// ----------------------------------------------------------------------
interface Props {
  email: string;
  title: string;
  description: React.ReactNode;
}

const VerifyEmail = ({ email, title, description }: Props) => {
  const { actions } = useAuthSlice();
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState<number>(60);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown && countDown < 60 && countDown > 0) {
        setCountDown(countDown - 1);
      }
      if (countDown === 0) {
        setCountDown(0);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    } else {
      clearInterval(countDown);
    }
  });

  const handleRequestPassword = () => {
    setIsLoading(true);
    const _email = email;
    const request: ForgotPasswordRequest = {
      email: _email,
      namespace: TypeNamespace.NAMESPACE,
    };
    dispatch(
      actions.forotPassword(request, (err?: any) => {
        if (!err) {
          dispatch(
            snackbarActions.updateSnackbar({
              message: `A verification link was sent to ${_email}`,
              type: 'success',
            })
          );
          setIsLoading(false);
          setCountDown(60);
        } else {
          dispatch(
            snackbarActions.updateSnackbar({
              message: 'Please check the information.',
              type: 'error',
            })
          );
          setIsLoading(false);
        }
      })
    );
  };

  // const handleVerifyEmail = () => {
  //   dispatch(
  //     actions.resendVerifyEmail({ email }, (err?: any) => {
  //       if (!err) {
  //         dispatch(
  //           snackbarActions.updateSnackbar({
  //             message: `A verification link was sent to ${email}`,
  //             type: 'success',
  //           })
  //         );
  //         setCountDown(59);
  //       } else {
  //         if (err.error === 'invalid_user') {
  //           dispatch(
  //             snackbarActions.updateSnackbar({
  //               message: 'Please check the information.',
  //               type: 'error',
  //             })
  //           );
  //         }
  //       }
  //     })
  //   );
  // };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'end',
          mx: 3,
          top: '40px',
          right: { md: '20px', xs: '0px' },
          marginRight: { xs: '0px' },
        }}
      >
        <Typography
          variant="body2"
          sx={(theme) => ({
            display: 'flex',
            fontSize: 14,
            color: theme.palette.primary.main,
          })}
        >
          {'Already have an account?'}
          <Link
            component={RouterLink}
            style={{ textDecoration: 'none' }}
            to={path.login}
            className="link-forgot-password"
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 600, ml: 1 }}>
              Sign in
            </Typography>
          </Link>
        </Typography>
      </Box>
      <Box
        sx={{
          m: { md: '230px 140px', xs: '0px' },
        }}
      >
        <Typography fontSize="18px">{title}</Typography>
        {description}
        <LoadingButton
          fullWidth
          loading={isLoading}
          disabled={countDown > 0}
          onClick={handleRequestPassword}
          sx={(theme) => ({
            color: theme.palette.secondary.lighter,
            my: 2,
            padding: '20px 0',
            fontWeight: '700',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            bgcolor: theme.palette.primary.main,
            height: '36px',
            borderRadius: '10px 0px',
          })}
        >
          Resend verification link{' '}
          {countDown === 0 ? (
            ''
          ) : (
            <Typography
              sx={(theme) => ({
                color: theme.palette.grey[500_80],
                marginLeft: '5px',
              })}
            >
              {' '}
              {`(${countDown})`}
            </Typography>
          )}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default withLoading(VerifyEmail);
