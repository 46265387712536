import { FileTypeUpload, FileUpload, MultiFileTypeUpload } from 'types';

import { createService, createServiceNoToken } from './axios';

const baseURL = process.env.REACT_APP_API_URL;
const instance = createService(baseURL, 'multipart/form-data');
const instanceNoAuth = createServiceNoToken(baseURL);

const fetchUrlImages = async (params: FileUpload) => {
  try {
    const formData = new FormData();
    formData.append('category_image', params.category_image || '');
    formData.append('extensions', params.extensions || '');
    formData.append('height', params.height || '');
    formData.append('image', params.image || File);
    formData.append('width', params.width || '');
    const response = await instance.post(`/v1/users/upload-image-asset`, formData);
    const _data = {
      data: response.data,
      status: true,
    };
    return _data;
  } catch (e: any) {
    const _data = {
      data: e.response.data.message,
      status: false,
    };
    return _data;
  }
};

const uploadFile = async (params: FileTypeUpload) => {
  try {
    const formData = new FormData();
    formData.append('category_image', params.category_image || '');
    formData.append('extensions', params.extensions || '');
    formData.append('height', params.height || '');
    formData.append('image', params.image || File);
    formData.append('width', params.width || '');
    const response = await instance.post(`/v1/users/upload-image-asset`, formData);
    const _data = {
      data: response.data,
      status: true,
    };
    return _data;
  } catch (e: any) {
    const _data = {
      data: e.response.data.errors.image.toString(),
      status: false,
    };
    return _data;
  }
};

const uploadFileMulti = async (params: MultiFileTypeUpload) => {
  try {
    const formData = new FormData();
    formData.append('category_image', params.category_image || '');
    formData.append('extensions', 'jpge,jpg,png' || '');
    for (let i = 0; i < params.images.length; i++) {
      const element = params.images[i];
      formData.append(element.title, element.file);
    }
    const response = await instance.post(`/v1/users/upload-image-asset`, formData);
    const _data = {
      data: response.data,
      status: true,
    };
    return _data;
  } catch (e: any) {
    const _data = {
      data: e.response.data.errors.image.toString(),
      status: false,
    };
    return _data;
  }
};

const uploadFileNoAuth = async (params: FileTypeUpload) => {
  try {
    const formData = new FormData();
    formData.append('category_image', params.category_image || '');
    formData.append('extensions', params.extensions || '');
    formData.append('height', params.height || '');
    formData.append('image', params.image || File);
    formData.append('width', params.width || '');
    const response = await instanceNoAuth.post(`/v1/upload-image-no-auth`, formData);
    const _data = {
      data: response.data,
      status: true,
    };
    return _data;
  } catch (e: any) {
    const _data = {
      data: e.response.data.errors.image.toString(),
      status: false,
    };
    return _data;
  }
};

export default {
  fetchUrlImages,
  uploadFile,
  uploadFileMulti,
  uploadFileNoAuth,
};
