import { DeliveryStatusPost, FilterParams, Pageable, RedemptionStatusPost, RewardRedemInformation } from 'types';
import { serialize } from 'utils/helpers';
import { createService } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const instance = createService(baseURL);

const fetchGameRewardRedemData = async (params?: FilterParams): Promise<Pageable<RewardRedemInformation>> => {
  const newParams = { ...params };
  delete newParams.campaignId;
  const response = await instance.get(
    `/v1/products/campaigns/${params?.campaignId}/redemptions?${serialize(newParams)}`
  );
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const fetchRewardRedemInformation = async (params?: FilterParams): Promise<RewardRedemInformation> => {
  const response = await instance.get(`/v1/products/campaigns/${params?.campaign_id}/redemptions/${params?.redem_id}`);
  return response.data.data;
};

const changeStatusRedem = async (params: RedemptionStatusPost) => {
  const response = await instance.post(
    `/v1/products/campaigns/${params?.campaign_id}/redemptions/change-redemption-status`,
    JSON.stringify(params)
  );
  return response.data;
};

const changeStatusDelivery = async (params: DeliveryStatusPost) => {
  const response = await instance.post(
    `/v1/products/campaigns/${params?.campaign_id}/redemptions/change-delivery-status`,

    JSON.stringify(params)
  );
  return response.data;
};

export default {
  fetchGameRewardRedemData,
  fetchRewardRedemInformation,
  changeStatusRedem,
  changeStatusDelivery,
};
