import { PayloadAction } from '@reduxjs/toolkit';
import { Pageable } from 'types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { ParamsRequest } from './../../../../types/Filter';
import { notificationSaga } from './saga';
import { DataListAllNoti, NotificationState } from './types';

export const initialState: NotificationState = {
  isLoadingAllList: true,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    fetchAllListNotification: {
      reducer(state) {
        return state;
      },
      prepare(params: ParamsRequest, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    fetchAllListNotificationSuccess: () => {},

    readNotification: {
      reducer(state) {
        return state;
      },
      prepare(params: { notiId: string }, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    readNotificationSuccess: () => {},

    readAllNotification: {
      reducer(state) {
        return state;
      },
      prepare(params, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    readAllNotificationSuccess: () => {},

    getAllListNotification(state, action) {},
    getAllListNotificationSuccess: (state, action: PayloadAction<Pageable<DataListAllNoti>>) => {
      state.listNotification = action.payload;
    },
  },
});

export const { actions: notificationActions } = slice;

export const useNotificationSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: notificationSaga });
  return { actions: slice.actions };
};
