import { Box } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSupportCenterSlice } from '../slice';
import { ListSupportCenter } from '../slice/types';
import AllListSupport from './AllListSupport';
import DetailSupport from './DetailSupport';

interface Props { }

export default function DetailSupportCenter(props: Props) {
  const dispatch = useDispatch();
  const { id, spId } = useParams();
  const { actions } = useSupportCenterSlice();
  const [sublListSupportCenter, setSubListSupportCenter] = React.useState<ListSupportCenter[]>([]);


  React.useEffect(() => {
    dispatch(
      actions.fetchAllListSupportCenter(
        { page: 1, perpage: 10, campaign_id: !!id ? id?.toString() : '' },
        (err?: any) => {
          setSubListSupportCenter([...err?.res.data]);
        }
      )
    );
  }, [])

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <AllListSupport sublListSupportCenter={sublListSupportCenter} setSubListSupportCenter={setSubListSupportCenter} />
      <DetailSupport allListSupportCenter={sublListSupportCenter} />
    </Box>
  );
}
