/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const LeaderBoard = lazyLoad(
  () => import('./index'),
  (module) => module.LeaderBoard
);
