import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Grid, IconButton, Menu, Popover, Typography, useTheme } from '@mui/material';
import ICON_SUPPORT from 'assets/table/icon_sp_center.png';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NotifyType, TypeUnitPrice } from 'types/Enum';
import { renderTypeSupportCenter } from 'utils/helpers';
import SupportCenterList from './List';
import { useSupportCenterSlice } from './slice';
import { selectSupportCenter } from './slice/selectors';


interface TabPanelProps {
  children?: React.ReactNode;
  index: NotifyType;
  value: NotifyType;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SupportCenter() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const { actions } = useSupportCenterSlice();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const { id } = useParams();
  const [value, setValue] = React.useState<NotifyType>(NotifyType.ALL);
  const [typeSupportCenter, setTypeSupportCenter] = React.useState<TypeUnitPrice>();
  const { listSupportCenter } = useSelector(selectSupportCenter);
  const typeSupport = [
    {
      id: 1,
      type: t("All"),
    },
    {
      id: 2,
      type: TypeUnitPrice.REQUEST_THEME,
    },
    {
      id: 3,
      type: TypeUnitPrice.REQUEST_PROMOTION,
    },
    {
      id: 4,
      type: TypeUnitPrice.FEEDBACK,
    }
  ]

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(actions.getAllListSupportCenter({ page: 1, perpage: 10, is_checked: 0, campaign_id: id ? id : '' }));
    }, 3000);
    return () => clearInterval(timer);
  }, [id, listSupportCenter]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: NotifyType) => {
    setValue(newValue);
  };

  const handleChangeFilterNoti = (item: { id?: number; type: any; }) => {
    setTypeSupportCenter(item.type);
    dispatch(actions.getAllListSupportCenter({ page: 1, perpage: 10, type: item.type, campaign_id: id ? id : '' }));
    handleClosel();
  };
  const [anchorEll, setAnchorEll] = React.useState<HTMLButtonElement | null>(null);
  const openn = Boolean(anchorEll);
  const handleClickk = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEll(event.currentTarget);
  };
  const handleClosel = () => {
    setAnchorEll(null);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton onClick={handleClick}>
        <img src={ICON_SUPPORT} alt="Icon notification" width="24px" height="24px" />
        {listSupportCenter?.total ? (
          <Grid
            sx={{
              position: 'absolute',
              fontSize: '12px',
              top: '5%',
              right: '10%',
              padding: '3px',
              width: listSupportCenter?.total && listSupportCenter?.total >= 5 ? 'fit-content' : '18px',
              height: 'fit-content',
              color: theme.palette.common.white,
              background: 'red',
              borderRadius: '50%',
            }}
          >
            {Number(listSupportCenter?.total) > 5 ? '5+' : listSupportCenter?.total}
          </Grid>
        ) : (
          ''
        )}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '379px',
            height: 'fit-content',
            maxHeight: '701px',
            top: '75px !important',
          },
        }}
      >
        <Box p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography
            fontSize={'18px'}
            lineHeight={'22px'}
            color={theme.palette.secondary.contrastText}
            fontWeight={500}
          >
            {t(translations.supportCenter.supportCenter)}
          </Typography>
          <IconButton
            sx={{
              mr: 1,
              pb: 0,
              mb: 1,
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickk}
          >
            <FilterListIcon sx={{ color: theme.palette.common.black }} />
          </IconButton>
        </Box>
        <Box sx={{ width: '100%' }}>
          {/* ..................Sort Notification................. */}
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEll}
              open={openn}
              onClose={handleClosel}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  color: theme.palette.common.black,
                  height: '188px',
                  width: '174px',
                }}
              >
                <Typography
                  sx={{
                    padding: '0px 3px 0px 7px',
                  }}
                >
                  {typeSupport.map((item) => (
                    <Button
                      key={item.id}
                      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', pl: 0, pr: 0 }}
                      onClick={() => handleChangeFilterNoti(item)}
                    >
                      <Typography
                        variant="subtitle2"
                        noWrap
                        color={'primary'}
                        sx={{ fontSize: 14, fontWeight: 500, padding: '0 16px' }}
                      >
                        {renderTypeSupportCenter(item.type)}
                      </Typography>
                    </Button>
                  ))}
                </Typography>
              </Typography>
            </Menu>
          </div>
          {/* ................................................... */}
          <TabPanel value={value} index={NotifyType.ALL}>
            <SupportCenterList typeNotify={NotifyType.ALL} handleClose={handleClose} campaignId={Number(id)} typeSupportCenter={typeSupportCenter} />
          </TabPanel>
        </Box>
      </Popover>
    </Box>
  );
}
