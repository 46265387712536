import { get, replace } from 'lodash';
import numeral from 'numeral';
import queryString from 'query-string';
import { FileDimension, FilterParams } from 'types';
import { RoleUserCostumer, SupportCenterType } from 'types/Enum';
import { translations } from 'locales/translations';

export const getSort = ({ filter, property }: { filter: FilterParams; property: string }) => {
  if (filter.sort && filter.sort.length > 0) {
    const currentSort = filter.sort.find((item) => item && item.split(',')[0] === property);
    const newSort = [...filter.sort];
    if (currentSort) {
      const newSortType = currentSort?.split(',')[1] === 'desc' ? 'asc' : 'desc';
      const currentIndex = newSort.findIndex((s) => s.split(',')[0] === property);
      newSort.splice(currentIndex, 1);
      newSort.push(`${property},${newSortType}`);
      return newSort;
    }
  }
  return [`${property},desc`];
};

export const getOrder = ({ filter, property }: { filter: FilterParams; property: string }) => {
  if (filter.orders && filter.orders.length > 0) {
    const currentSort = filter.orders.find((item) => item && item.split(' ')[0] === property);
    const newSort = [...filter.orders];
    if (currentSort) {
      const newSortType = currentSort?.split(' ')[1] === 'desc' ? 'asc' : 'desc';
      const currentIndex = newSort.findIndex((s) => s.split(',')[0] === property);
      newSort.splice(currentIndex, 1);
      newSort.push(`${property} ${newSortType}`);
      return newSort;
    }
  }
  return [`${property} desc`];
};

// ----------------------------------------------------------------------

export function fCurrency(elment: number) {
  return numeral(elment).format(Number.isInteger(elment) ? '$0,0' : '$0,0.00');
}

export function fPercent(elment: number) {
  return numeral(elment / 100).format('0.0%');
}

export function fNumber(elment: number) {
  return numeral(elment).format();
}

export function fShortenNumber(elment: number) {
  return replace(numeral(elment).format('0.00a'), '.00', '');
}

export function fData(elment: number) {
  return numeral(elment).format('0.0 b');
}

export const setToLS = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = (key: string) => {
  const value = window.localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
};

export function parseParams(search: string) {
  return queryString.parse(search.substr(1)); // remove '?'
}

export const renderRole = (role?: string) => {
  return get(RoleUserCostumer, `${role}`);
};

export const convertVideoObjUrl = (file: File) => {
  if (file.type?.includes('video')) {
    return URL.createObjectURL(new Blob([file], { type: file.type }));
  }
  return URL.createObjectURL(file);
};

export const getDimensionsOfImage = (url: string): Promise<FileDimension> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;
    image.addEventListener('load', function () {
      const { height, width } = image;
      resolve({ height, width });
    });
  });
};

export const exportFileExcel = (url: string, fileName: string) => {
  const blob = new Blob([url]);
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
};

export const exportExcel = (url: string, fileName: string) => {
  const blob = new Blob([url]);
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.setAttribute('download', `Reward_statistics(${fileName}).xlsx`);
  document.body.appendChild(a);
  a.click();
};

export const exportExcelRemid = (url: string, fileName: string) => {
  const blob = new Blob([url]);
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.setAttribute('download', `Reward_redemption(${fileName}).xlsx`);
  document.body.appendChild(a);
  a.click();
};

export const exportExcelSaff = (url: string, fileName: string) => {
  const blob = new Blob([url]);
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.setAttribute('download', `Staff(${fileName}).xlsx`);
  document.body.appendChild(a);
  a.click();
};

export const exportExcelPlay = (url: string, fileName: string) => {
  const blob = new Blob([url]);
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.setAttribute('download', `Player(${fileName}).xlsx`);
  document.body.appendChild(a);
  a.click();
};

export const downloadFilePdf = (data: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Invoice.pdf');
  document.body.appendChild(link);
  link.click();
};

export const serialize = (params: any) => {
  const str = [];
  for (const p in params)
    if (params.hasOwnProperty(p)) {
      if (params[p] !== '') {
        str.push(encodeURIComponent(p) + '=' + params[p]);
      }
    }
  return str.join('&');
};

export const serializeExport = (params: any) => {
  const str = [];
  for (const p in params)
    if (params.hasOwnProperty(p)) {
      if (!!params[p] && params[p] !== '') {
        str.push(encodeURIComponent(p) + '=' + params[p]);
      }
    }
  return str.join('&');
};

export const getGameConfigKeyTitle = (key: string, lang: string | undefined, getValues: Function, t: Function) => {
  const dynamicTitle = getValues(`${key}.key_${lang || 'en'}`);
  if (dynamicTitle) return dynamicTitle;

  const embedKeys = JSON.parse(JSON.stringify(translations.configManagement));
  if (embedKeys[convertCharacter(key)]) {
    return t(`configManagement.${convertCharacter(key)}`);
  }

  return replaceCharacter(key);
};

export const replaceCharacter = (text: string) => {
  const split = text.split('.');
  text = split[split.length - 1];
  const subText = text.charAt(0).toUpperCase() + text.slice(1);
  return subText.replaceAll('_', ' ');
};

export const convertCharacter = (text: string) => {
  const split = text.split('.');
  text = split[split.length - 1];
  return text;
};

export const createMinute = (ms: string) => {
  const date = new Date();
  const createMili = Date.parse(ms);
  const milliseconds = Date.parse(`${date}`) - createMili;
  const mins = Math.floor(milliseconds / 60000);
  if (mins <= 1) {
    return 'Just now';
  } else if (mins >= 60 && mins < 1440) {
    return Math.floor(mins / 60) + 'h';
  } else if (mins >= 1440 && mins < 2880) {
    return Math.floor(mins / 1440) + ' day ago';
  } else if (mins >= 2880) {
    return Math.floor(mins / 1440) + ' days ago';
  } else {
    return mins + 'm';
  }
};

export const formatCurrency = (n?: string | number) => {
  if (n === 0) return '0';
  if (!n) return '';
  const money = typeof n === 'string' ? n : Math.round(n).toString();
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return money.replace(thousands, '.');
};

export const renderTypeSupportCenter = (type: string) => {
  switch (type) {
    case SupportCenterType.FEEDBACK:
      return `Feedback`;
    case SupportCenterType.PARTNER:
      return `Partner`;
    case SupportCenterType.REPLY_CONTACT:
      return `Reply Contact`;
    case SupportCenterType.REQUEST_PROMOTION:
      return `Request Promotion`;
    case SupportCenterType.REQUEST_THEME:
      return `Request Theme`;
    default:
      return type;
  }
}

export const renderIcons = (type: string) => {
  switch (type) {
    case SupportCenterType.FEEDBACK:
      return '/static/icons/Feedback.png';
    case SupportCenterType.PARTNER:
      return '/static/icons/Feedback.png';
    case SupportCenterType.REPLY_CONTACT:
      return '/static/icons/Package.png';
    case SupportCenterType.REQUEST_PROMOTION:
      return '/static/icons/Promotion.png';
    case SupportCenterType.REQUEST_THEME:
      return '/static/icons/Theme.png';
    default:
      return '';
  }
};
