import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { MainGameTypes } from '../slice/types';

const RootStyle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
  height: 24,
  borderRadius: 8,
  marginTop: '25px',
});

export interface Props {
  game: MainGameTypes;
}

export default function StatusGame({ game }: Props) {
  const { t } = useTranslation();
  const { active_version } = game;

  const renderPaused = () => (
    <RootStyle
      sx={(theme) => ({
        bgcolor: theme.palette.success.lighter,
      })}
    >
      <Box sx={(theme) => ({ padding: '10px', color: theme.palette.primary.main, fontSize: 14 })}>
        {t('configManagement.draff')}
      </Box>
    </RootStyle>
  );

  const renderRunning = () => (
    <RootStyle
      sx={(theme) => ({
        bgcolor: theme.palette.info.darker,
      })}
    >
      <Box sx={(theme) => ({ padding: '10px', color: theme.palette.primary.main, fontSize: 14 })}>
        {t('configManagement.official')}
      </Box>
    </RootStyle>
  );

  return (
    <>
      {active_version === 1 && renderRunning()}
      {active_version === 0 && renderPaused()}
    </>
  );
}
