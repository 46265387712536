import {
  DataAllPackage,
  DataDetailPayment,
  DataOrderPaymentPostpaid,
  DataPayment,
  DataPlayTurnUsedAction,
  ListInvoice,
  PayloadOrderPayment,
} from 'app/pages/PaymentManagement/slice/types';
import { FilterParams, Pageable } from 'types';
import { serialize } from 'utils/helpers/index';
import { PayloadPayment } from './../../app/pages/PaymentManagement/slice/types';
import { createService, downloadFileService } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const instance = createService(baseURL);
const instanceGetFile = downloadFileService(baseURL, 'application/json');

const orderPaymentData = async (params?: PayloadOrderPayment): Promise<Pageable<DataDetailPayment>> => {
  const url = `/v1/products/orders/store`;
  const response = await instance.post(url, params);
  return response.data;
};

const updatePaymentData = async (params?: PayloadOrderPayment): Promise<Pageable<DataDetailPayment>> => {
  const url = `/v1/products/orders/upgrade-package`;
  const response = await instance.post(url, params);
  return response.data;
};

const orderPaymentPostpaidData = async (params?: PayloadOrderPayment): Promise<Pageable<DataOrderPaymentPostpaid>> => {
  const url = `/v1/products/orders/upgrade`;
  const response = await instance.post(url, params);
  return response.data;
};

const paymentData = async (params?: PayloadPayment): Promise<Pageable<DataPayment>> => {
  const url = `/v1/products/pay`;
  const response = await instance.post(url, params);
  return response.data;
};

const fetchListInvoiceData = async (params?: FilterParams): Promise<Pageable<ListInvoice>> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  const response = await instance.get(
    `/v1/products/campaigns/${params?.campaign_id}/invoice-list?${serialize(newParams)}`
  );
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const getUnitPricePackage = async (params?: FilterParams): Promise<Pageable<DataAllPackage>> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  delete newParams.type;
  const response = await instance.get(`/v1/products/packages/detail/unit-price?type=${params?.type}`);
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const getListExtendPackage = async (params?: FilterParams): Promise<Pageable<DataAllPackage>> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  const response = await instance.get(`/v1/products/no-auth/packages/play_turns?${serialize(newParams)}`);
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const getListAllPackage = async (params?: FilterParams): Promise<Pageable<DataAllPackage>> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  const response = await instance.get(`/v1/products/get-all-packages?${serialize(newParams)}`);
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const getPlayTurnUsedData = async (params?: FilterParams): Promise<DataPlayTurnUsedAction> => {
  const response = await instance.get(`/v1/products/campaigns/${params?.campaign_id}/package-turn-used`);
  return response.data;
};

const downloadInvoice = async (params?: PayloadPayment): Promise<any> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  delete newParams.amount;
  delete newParams.language;
  const url = `/v1/products/campaigns/${params?.campaign_id}/download-invoice`;
  const response = await instanceGetFile.post(url, newParams);
  return response.data;
};


const getUnitPrice = async (params: FilterParams): Promise<number> => {
  const { type, ...restParams } = params;
  const response = await instance.get(`v1/products/packages/detail/unit-price?${serialize({ type })}`);
  return response.data.data.unit_price;
};

export default {
  orderPaymentData,
  updatePaymentData,
  paymentData,
  fetchListInvoiceData,
  getListAllPackage,
  orderPaymentPostpaidData,
  getPlayTurnUsedData,
  downloadInvoice,
  getListExtendPackage,
  getUnitPricePackage,
  getUnitPrice,
};
