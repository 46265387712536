import {
  DataUnitPricePackageTheme,
  EstimationInfo,
  ParamsRequestNewTheme,
} from 'app/pages/GameBaseManagement/GameRequestNewTheme/slice/types';
import { FilterParams, Pageable } from 'types';
import { createService, createServiceNoToken } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const instance = createService(baseURL);
const baseUserUrl = process.env.REACT_APP_API_URL;
const instanceUser = createService(baseUserUrl);
const instanceNoAuth = createServiceNoToken(baseURL);

const fetchEstimationInformation = async (param?: FilterParams): Promise<EstimationInfo> => {
  const response = await instance.get(`/v1/products/package-themes/find-by-game-id/${param?.game_id}`);
  return response.data.data;
};

const requestNewThemeInformationData = async (param: ParamsRequestNewTheme): Promise<ParamsRequestNewTheme> => {
  const response = await instanceUser.post(`/v1/themes/request-new-theme`, param);
  return response.data;
};

const fetchEstimationInformationNoAuth = async (param?: FilterParams): Promise<EstimationInfo> => {
  const response = await instanceNoAuth.get(`/v1/products/no-auth/get-new-theme-package/${param?.game_id}`);
  return response.data.data;
};

const requestNewThemeInformationDataNoAuth = async (param: ParamsRequestNewTheme): Promise<ParamsRequestNewTheme> => {
  const response = await instanceUser.post(`/v1/themes/request-new-theme`, param);
  return response.data;
};

const getDetailUnitPricePackageTheme = async (params?: FilterParams): Promise<Pageable<DataUnitPricePackageTheme>> => {
  const response = await instance.get(`/v1/products/package-themes/unit-price`);
  return response.data.data;
};

export default {
  fetchEstimationInformation,
  requestNewThemeInformationData,
  fetchEstimationInformationNoAuth,
  requestNewThemeInformationDataNoAuth,
  getDetailUnitPricePackageTheme,
};
