import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import memberService from 'services/api/memberService';

import { GameBaseParam, GameParam } from 'types/Game';

import { DataMainGameProps } from './types';

import { mainGameActions as actions } from '.';

function* fetchMainGameListData(action: PayloadAction<GameParam, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.fetchMainGameData, action.payload);
    yield put(actions.fetchMainGameListSuccess(result));
  } catch (errors) {}
}

export function* deleteGame(action: PayloadAction<GameParam, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.deleteGame, action.payload);
    yield put(actions.deleteGameSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* fetchListGameBaseData() {
  try {
    const result: DataMainGameProps = yield call(memberService.fetchListGameBaseData);
    yield put(actions.fetchListGameBaseDataSuccess(result));
  } catch (error) {}
}

export function* actionGame(action: PayloadAction<GameParam, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.actionGame, action.payload);
    yield put(actions.actionGameSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* chooseGameBase(action: PayloadAction<GameBaseParam, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.chooseGameBase, action.payload);
    yield put(actions.chooseGameBaseSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* mainGameSaga() {
  yield takeLatest(actions.fetchMainGameList.type, fetchMainGameListData);
  yield takeLatest(actions.deleteGame.type, deleteGame);
  yield takeLatest(actions.actionGame.type, actionGame);
  yield takeLatest(actions.fetchListGameBaseData.type, fetchListGameBaseData);
  yield takeLatest(actions.chooseGameBase.type, chooseGameBase);
}
