import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { GameBaseParam, GameParam } from 'types/Game';

import { DataMainGameProps, MainGameState } from './types';

import { mainGameSaga } from './saga';

export const initialState: MainGameState = {};

const slice = createSlice({
  name: 'mainGame',
  initialState,
  reducers: {
    fetchMainGameList: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    fetchMainGameListSuccess(state, action: PayloadAction<DataMainGameProps>) {
      state.mainGameList = action.payload;
      state.isLoadingTable = false;
    },
    deleteGameSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    deleteGame: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    actionGameSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    actionGame: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    fetchListGameBaseData(state) {},
    fetchListGameBaseDataSuccess: (state, action: PayloadAction<DataMainGameProps>) => {
      state.mainGameList = action.payload;
    },
    chooseGameBaseSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    chooseGameBase: {
      reducer(state) {
        return state;
      },
      prepare(params: GameBaseParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
  },
});

export const { actions: mainGameActions } = slice;

export const useMainGameSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: mainGameSaga });
  return { actions: slice.actions };
};
