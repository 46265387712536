import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { FeedbackInfo, FeedbackInfoManagementState } from './types';

import { feedbackManagementSaga } from './saga';

export const initialState: FeedbackInfoManagementState = {};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    feedbackSuccess() {},
    feedback: {
      reducer(state) {
        return state;
      },
      prepare(params: FeedbackInfo, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
  },
});

export const { actions: feedbackManagementActions } = slice;

export const useFeedbackManagementSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: feedbackManagementSaga });
  return { actions: slice.actions };
};
