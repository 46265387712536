import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import path from 'app/routes/path';

import { RefreshTokenResponse } from 'types';
import { LocalStorageService } from '../';

import { destroyToken, saveToken, tryRefreshToken } from './keycloakService';

declare module 'axios' {
  export interface AxiosRequestConfig {
    throwAccessDenied?: boolean; // is true if you want to self handle access denied exception
  }
}

export const createService = (baseURL?: string, contentType: string = 'application/json'): AxiosInstance => {
  return interceptAuth(baseConfig(baseURL, contentType));
};

const baseConfig = (baseURL?: string, contentType: string = 'application/json', additionHeaders?: any) => {
  return {
    baseURL,
    headers: {
      'Accept-Language': 'en-US',
      'Content-Type': contentType,
      ...additionHeaders,
    },
  };
};

export const downloadFileService = (baseURL?: string, contentType: string = 'application/json'): AxiosInstance => {
  const config: AxiosRequestConfig = baseConfig(baseURL, contentType, { 'Cache-Control': 'no-cache' });
  config.responseType = 'blob';
  return interceptAuth(config);
};

export const createServiceNoToken = (baseURL?: string): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Accept-Language': 'en-US',
      'Content-Type': 'application/json',
    },
  });
  instance.interceptors.request.use((config) => {
    return config;
  });
  return instance;
};

export const createServiceRefreshToken = (
  baseURL?: string,
  contentType: string = 'application/json'
): AxiosInstance => {
  return interceptRefreshToken(baseConfig(baseURL, contentType));
};

const interceptAuth = (config: AxiosRequestConfig) => {
  const instance = axios.create(config);
  instance.interceptors.request.use((cf) => {
    const token = LocalStorageService.get(LocalStorageService.OAUTH_TOKEN);
    if (token && cf?.headers) {
      cf.headers['Authorization'] = 'Bearer ' + token;
    }
    return cf;
  });

  let refreshtokenCount = 0;
  function createAxiosResponseInterceptor() {
    const interceptor = instance.interceptors.response.use(
      (response) => {
        refreshtokenCount = 0;
        return response;
      },
      (error) => {
        if (error.response.status !== 401) {
          refreshtokenCount = 0;
          return Promise.reject(error);
        }
        axios.interceptors.response.eject(interceptor);
        if (++refreshtokenCount > 5) {
          destroyToken();
          window.location.href = path.login;
        }
        return tryRefreshToken()
          .then((response) => {
            const responseData: RefreshTokenResponse = response.data.data;
            saveToken(responseData);
            error.response.config.headers['Authorization'] = 'Bearer ' + response.data.data.access_token;
            return axios(error.response.config);
          })
          .catch((error) => {
            destroyToken();
            window.location.href = path.login;
            return Promise.reject(error);
          })
          .finally(createAxiosResponseInterceptor);
      }
    );
  }
  createAxiosResponseInterceptor();
  return instance;
};

const interceptRefreshToken = (config: AxiosRequestConfig) => {
  const instance = axios.create(config);
  const token = LocalStorageService.get(LocalStorageService.REFRESH_TOKEN) as string;
  instance.interceptors.request.use((cf) => {
    if (token && cf?.headers) {
      cf.headers['Authorization'] = 'Bearer ' + token;
    }
    return cf;
  });

  function createAxiosResponseInterceptor() {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          if (error.config.url !== '/users/get-me') {
            window.location.href = path.login;
          }
          LocalStorageService.removeAllItem();
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }
  createAxiosResponseInterceptor();
  return instance;
};
