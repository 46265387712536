import { Box, Dialog, IconButton, Link, Typography, useTheme } from '@mui/material';
import { withLoading } from 'app/components/HOC/WithLoading';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import LoginForm from '../../components/Login';
import path from '../../routes/path';
import { useSnackbarSlice } from '../SnackBar/slice';
import { BackgroundImage } from './components/BackgroundImage';
import AuthSocial from './components/LoginSocial';
import { useAuthSlice } from './slice';

// ----------------------------------------------------------------------
interface Props {
  setLoading?: Function;
}

const AuthPage = (props: Props) => {
  const { actions } = useAuthSlice();
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [openDialogMobile, setOpenDialogMobile] = useState<boolean>(true);
  const handleCloseDialogMobile = () => {
    setOpenDialogMobile(false);
  };
  const handleLogin = useCallback(
    ({ email, password, remember_me }) => {
      setIsLoading(true);
      dispatch(
        actions.login({ email, password, remember_me }, (err?: any) => {
          setIsLoading(false);
          if (!err) {
            dispatch(
              snackbarActions.updateSnackbar({
                message: t('Welcome back to PapaHub!'),
                type: 'success',
              })
            );
            navigate(path.root);
          } else {
            switch (err.message) {
              case 'account_has_not_yet_verified':
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: t(
                      'Your account has not been verified, please go to Gmail to verify to sign in successfully'
                    ),
                    type: 'error',
                  })
                );
                break;
              case 'email_incorrect':
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: 'Email incorrect',
                    type: 'error',
                  })
                );
                break;
              case 'account_is_inactive':
                navigate(path.loginFailed);
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: 'Sign in failed',
                    type: 'error',
                  })
                );
                break;
              case 'account_has_been_deleted':
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: 'Your email has been deleted, please contact the site manager to recreate it.',
                    type: 'error',
                  })
                );
                break;

              default:
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: 'Please check the information.',
                    type: 'error',
                  })
                );
                break;
            }
            setError(err);
          }
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, dispatch, navigate]
  );

  const clearError = () => {
    setError(false);
  };

  return (
    <Box
      sx={{
        display: { md: 'flex' },
        flexDirection: 'row',
        backgroundColor: '#E2E2E2',
        height: '100%',
      }}
    >
      <BackgroundImage />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: { md: '55%', xs: '100%' },
          marginLeft: { xs: '0px' },
          height: { xs: '100%' },
          mx: 3,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'end',
            top: { md: '40px', xs: '95vh' },
            right: { md: '10px', xs: '60px' },
          }}
        >
          <Typography
            variant="body2"
            sx={(theme) => ({
              display: 'flex',
              fontSize: 14,
              color: theme.palette.primary.main,
            })}
          >
            {'Don’t have an account?'}
            <Link
              component={RouterLink}
              style={{ textDecoration: 'none' }}
              to={path.signUp}
              className="link-forgot-password"
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: { md: '600', xs: '700' }, ml: 1, textDecoration: 'underline' }}
              >
                Sign up now
              </Typography>
            </Link>
          </Typography>
        </Box>
        <Dialog open={openDialogMobile} sx={{ display: { sm: 'none', xs: 'flex' } }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px 10px 16px 14px',
            }}
          >
            <Typography sx={{ fontSize: '17px', fontWeight: 700 }}>{t('common.notSupportDevice')}</Typography>
            <IconButton
              onClick={() => {
                handleCloseDialogMobile();
              }}
            >
              <CloseIcon style={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Box>
          <Box sx={{ padding: '0px 16px 20px 8px' }}>
            <Box>
              <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                {t('common.thePleaseComputerExperience')}
              </Typography>
            </Box>
            <Box sx={{ margin: '16px 0' }}></Box>
          </Box>
        </Dialog>
        <Box
          sx={(theme) => ({
            m: { md: '100px 50px', xs: '35px 24px 85px 24px' },
          })}
        >
          <Typography sx={{ display: { md: 'none', xs: 'flex' }, justifyContent: 'center' }}>
            <img src="/static/illustrations/Logo_mobile.svg" alt="" width={'220px'} height={'40px'} />
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: '18px',
              paddingTop: { xs: '12vw', md: '0vw' },
              fontWeight: 600,
            })}
          >
            Sign in to PapaHub
          </Typography>
          <Typography fontSize="14px" my={1} fontWeight={400} sx={{ color: theme.palette.success.lighter }}>
            Enter your detail below.
          </Typography>
          <LoginForm onSubmit={handleLogin} error={error} onClearError={clearError} loading={isLoading} />
          <AuthSocial />
        </Box>
      </Box>
    </Box>
  );
};

export default withLoading(AuthPage);
