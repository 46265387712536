import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { PaymentService } from 'services';
import { FilterParams, Pageable } from 'types';
import {
  DataAllPackage,
  DataDetailPayment,
  DataOrderPaymentPostpaid,
  DataPlayTurnUsedAction,
  ListInvoice,
  PayloadOrderPayment,
  PayloadPayment,
} from './types';

import { paymentManagementActions as actions } from '.';

function* orderPaymentData(action: PayloadAction<PayloadOrderPayment, string, (error?: any) => void>) {
  try {
    const result: Pageable<DataDetailPayment> = yield call(PaymentService.orderPaymentData, action.payload);
    yield put(actions.orderPaymentSuccess());
    action.meta(result);
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* updatePaymentData(action: PayloadAction<PayloadOrderPayment, string, (error?: any) => void>) {
  try {
    const result: Pageable<DataDetailPayment> = yield call(PaymentService.updatePaymentData, action.payload);
    yield put(actions.updatePaymentSuccess());
    action.meta(result);
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* orderPaymentPostpaidData(action: PayloadAction<PayloadOrderPayment, string, (error?: any) => void>) {
  try {
    const result: Pageable<DataOrderPaymentPostpaid> = yield call(
      PaymentService.orderPaymentPostpaidData,
      action.payload
    );
    yield put(actions.orderPaymentPostpaidSuccess());
    action.meta(result);
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* paymentData(action: PayloadAction<PayloadPayment, string, (error?: any) => void>) {
  try {
    const result: Pageable<DataDetailPayment> = yield call(PaymentService.paymentData, action.payload);
    yield put(actions.paymentSuccess());
    action.meta(result);
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* fetchListInvoice(action: PayloadAction<FilterParams>) {
  try {
    const result: Pageable<ListInvoice> = yield call(PaymentService.fetchListInvoiceData, action.payload);
    yield put(actions.fetchListInvoiceSuccess(result));
  } catch (errors) {}
}

function* fetchListExtend(action: PayloadAction<FilterParams>) {
  try {
    const result: Pageable<DataAllPackage> = yield call(PaymentService.getListExtendPackage, action.payload);
    yield put(actions.getListExtendPackageSuccess(result));
  } catch (errors) {}
}

function* fetchUnitPrice(action: PayloadAction<FilterParams>) {
  try {
    const result: Pageable<ListInvoice> = yield call(PaymentService.getUnitPricePackage, action.payload);
    yield put(actions.getUnitPricePackageSuccess(result));
  } catch (errors) {}
}

function* getListAllPackage(action: PayloadAction<FilterParams>) {
  try {
    const result: Pageable<DataAllPackage> = yield call(PaymentService.getListAllPackage, action.payload);
    yield put(actions.getListAllPackageSuccess(result));
  } catch (errors) {}
}

function* getPlayTurnUsedData(action: PayloadAction<FilterParams>) {
  try {
    const result: DataPlayTurnUsedAction = yield call(PaymentService.getPlayTurnUsedData, action.payload);
    yield put(actions.getPlayTurnUsedSuccess(result));
  } catch (errors) {}
}

function* downloadInvoice(action: PayloadAction<PayloadPayment, string, (error?: any) => void>) {
  try {
    const result: Pageable<any> = yield call(PaymentService.downloadInvoice, action.payload);
    yield put(actions.downloadInvoiceSuccess());
    action.meta(result);
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* getUnitPrice(action: PayloadAction<FilterParams>) {
  try {
    const result: PayloadAction<any> = yield call(PaymentService.getUnitPrice, action.payload);
    yield put(actions.getUnitPriceSuccess(result));
  } catch (errors) {}
}

export function* paymentManagementSaga() {
  yield takeLatest(actions.updatePayment.type, updatePaymentData);
  yield takeLatest(actions.orderPayment.type, orderPaymentData);
  yield takeLatest(actions.payment.type, paymentData);
  yield takeLatest(actions.fetchListInvoice.type, fetchListInvoice);
  yield takeLatest(actions.getListAllPackage.type, getListAllPackage);
  yield takeLatest(actions.orderPaymentPostpaid.type, orderPaymentPostpaidData);
  yield takeLatest(actions.getPlayTurnUsed.type, getPlayTurnUsedData);
  yield takeLatest(actions.downloadInvoice.type, downloadInvoice);
  yield takeLatest(actions.getListExtendPackage.type, fetchListExtend);
  yield takeLatest(actions.getUnitPricePackage.type, fetchUnitPrice);
  yield takeLatest(actions.getUnitPrice.type, getUnitPrice);
}
