import { PayloadAction } from '@reduxjs/toolkit';
import { CreateCampaignParam, DeleteCampaignParam, UpdateCampaignParam } from 'types/Campaign';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { authSaga } from './saga';
import { Campaigns } from './types';

export const initialState: Campaigns = {};

const slice = createSlice({
  name: 'createCampaign',
  initialState,
  reducers: {
    createCampaignSuccess(state, action: PayloadAction<any>) {},
    createCampaign: {
      reducer(state) {
        return state;
      },
      prepare(params: CreateCampaignParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    updateCampaignSuccess(state, action: PayloadAction<any>) {},
    updateCampaign: {
      reducer(state) {
        return state;
      },
      prepare(params: UpdateCampaignParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    deleteCampaignSuccess(state, action: PayloadAction<any>) {},
    deleteCampaign: {
      reducer(state) {
        return state;
      },
      prepare(params: DeleteCampaignParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    fetchCampaignData: {
      reducer(state) {
        return state;
      },
      prepare(params: DeleteCampaignParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    fetchCampaignSuccess(state, action: PayloadAction<any>) {
      state.listCampaign = action.payload;
    },
  },
});

export const { actions: createCampaignActions } = slice;

export const useCreateCampaignSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};
