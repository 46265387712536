import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material';
import { useProfile } from 'app/hooks';
import { translations } from 'locales/translations';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { NotifyType, TypeUnitPrice } from 'types/Enum';
import { createMinute, renderIcons, renderTypeSupportCenter } from 'utils/helpers';

import { useNavigate, useParams } from 'react-router-dom';
import SkeletonNotify from '../Skeleton';
import { useSupportCenterSlice } from '../slice';
import { ListSupportCenter } from '../slice/types';

interface Props {
  typeNotify: NotifyType;
  handleClose: () => void;
  campaignId?: number;
  typeSupportCenter?: TypeUnitPrice;
}

export default function SupportCenterList(props: Props) {
  const { typeNotify, handleClose, campaignId, typeSupportCenter } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { actions } = useSupportCenterSlice();
  const dispatch = useDispatch();
  const [isLoadingAllList, setIsLoadingAllList] = useState<boolean>();
  const [page, setPage] = React.useState(1);
  const [sublListSupportCenter, setSubListSupportCenter] = useState<ListSupportCenter[]>([]);
  const [totalListAllSupport, setTotalListAllSupport] = useState(0);
  const userId = useProfile();
  const { id } = useParams();

  React.useEffect(() => {
    setIsLoadingAllList(true);
    if (typeSupportCenter === undefined || typeSupportCenter === t('All')) {
      dispatch(
        actions.fetchAllListSupportCenter(
          { page, perpage: 10, campaign_id: !!id ? id?.toString() : '' },
          (err?: any) => {
            setSubListSupportCenter([...err?.res.data]);
            setTotalListAllSupport(err.res.total);
            setIsLoadingAllList(false);
          }
        )
      );
    } else {
      dispatch(
        actions.fetchAllListSupportCenter(
          { page, perpage: 10, campaign_id: !!id ? id?.toString() : '', type: typeSupportCenter },
          (err?: any) => {
            setSubListSupportCenter([...err?.res.data]);
            setTotalListAllSupport(err.res.total);
            setIsLoadingAllList(false);
          }
        )
      );
    }
  }, [campaignId, typeSupportCenter]);

  React.useEffect(() => {
    return () => {
      setPage(1);
    };
  }, [typeSupportCenter]);

  const getMoreList = () => {
    dispatch(
      actions.fetchAllListSupportCenter({ page: page + 1, perpage: 10 }, (err?: any) => {
        if (err?.success) {
          setSubListSupportCenter([...sublListSupportCenter, ...err?.res.data]);
          setPage(page + 1);
          setIsLoadingAllList(false);
        }
      })
    );
  };

  const hasMore = useMemo(() => {
    if (totalListAllSupport && totalListAllSupport > sublListSupportCenter.length) {
      return true;
    } else {
      return false;
    }
  }, [totalListAllSupport, sublListSupportCenter]);

  const handleRedirectPage = (spId: number) => {
    navigate(`/campaigns/${id}/support-center?spId=${spId}`)
  };

  const renderMessage = (notify: ListSupportCenter) => {
    if (notify?.reply) {
      return removeTags(" - " + notify?.reply || "") || "";
    } else {
      return ""
    }
  };

  const removeTags = (str: string) => {
    const div = document.createElement('div');
    div.innerHTML = str;
    return div.textContent || div.innerText || '';
  };

  const renderTitle = (status: string, type: string) => {
    const title = (renderTypeSupportCenter(type) + " " + status).toLowerCase();
    return <strong>{title.charAt(0).toUpperCase() + title.slice(1)}</strong>;
  }

  const handleReadNotification = (id: number, type: string) => {
    const params = {
      support_center_id: id,
      type
    }
    dispatch(actions.readSupportCenter(params, (err?: any) => {
      if (err?.success) {
        handleRedirectPage(id)
      }
    }));
  };

  const handleReadAllListNotification = () => {
    dispatch(
      actions.readAllSupportCenter({ type: typeSupportCenter === undefined || typeSupportCenter === t('All') ? "" : typeSupportCenter, campaign_id: Number(id) }, (res?: any) => {
        if (res?.success) {
          dispatch(
            actions.fetchAllListSupportCenter({ page, perpage: 10 }, (err?: any) => {
              setSubListSupportCenter([...err?.res.data]);
              setTotalListAllSupport(err.res.total);
              setIsLoadingAllList(false);
            })
          );
        }
      })
    );
  };

  return (
    <Box id="scrollableDiv">
      <InfiniteScroll
        dataLength={sublListSupportCenter.length || 0}
        next={getMoreList}
        hasMore={hasMore}
        height={500}
        loader={<SkeletonNotify loadingNumber={2} />}
        scrollableTarget="scrollableDiv"
      >
        {isLoadingAllList ? (
          <SkeletonNotify loadingNumber={6} />
        ) : (
          sublListSupportCenter.map((itemSupport, index) => (
            <Grid
              container
              key={itemSupport.id + index}
              p={1}
              bgcolor={itemSupport.tx_checked ? 'unset' : 'rgb(244, 244, 244)'}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexWrap: 'nowrap',
              }}
              onClick={() => {
                handleReadNotification(itemSupport.id, itemSupport.type);
                handleClose();
              }}
            >
              <Grid item sm={1.5}>
                <Avatar src={renderIcons(itemSupport.type)} />
              </Grid>
              <Grid item sm={8}>
                <Typography fontSize={'14px'} lineHeight={'18px'} color={theme.palette.primary.main} fontWeight={600}>
                  {renderTypeSupportCenter(itemSupport.type)}
                </Typography>
                <Typography fontSize={'13px'} lineHeight={'18px'} color={theme.palette.primary.main} mt={0.5}
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                  {renderTitle(itemSupport.status, itemSupport.type)} {renderMessage(itemSupport)}
                </Typography>
              </Grid>
              <Grid item sm={2.5} display={'flex'} flexDirection={'column'} alignItems={'self-end'}>
                <Typography fontSize={'12px'} lineHeight={'17px'} color={theme.palette.primary.main} fontWeight={500}>
                  {createMinute(itemSupport.created_at)}
                </Typography>
                {!itemSupport.tx_checked && (
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      bgcolor: theme.palette.primary.contrastText,
                      mt: 1,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          ))
        )}
      </InfiniteScroll>
      <Typography
        fontSize={'12px'}
        lineHeight={'15px'}
        fontWeight={600}
        color={theme.palette.primary.main}
        my={2}
        mr={1.5}
        textAlign={'end'}
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={handleReadAllListNotification}
      >
        {t(translations.notification.seeAll)}
      </Typography>
    </Box>
  );
}
