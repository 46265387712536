import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { MemberService } from 'services';
import { FeedbackInfo } from './types';
import { feedbackManagementActions as actions } from '.';

function* feedback(action: PayloadAction<FeedbackInfo, string, (error?: any) => void>) {
  try {
    yield call(MemberService.feedback, action.payload);
    yield put(actions.feedbackSuccess());
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* feedbackManagementSaga() {
  yield takeLatest(actions.feedback.type, feedback);
}
