import { useEffect, useState } from 'react';

import { getFromLS, setToLS } from 'utils/helpers';

const lightColor = {
  value: 'Gray',
  label: 'Gray',
  bgColor: '#585858',
  textColor: '#ABABAB',
  textColorActive: '#FFFFFF',
  index: 0,
};

export const useThemeState = () => {
  const [theme, setTheme] = useState(lightColor);
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);

  const setMode = (mode: any) => {
    setToLS('theme', mode);
    setTheme(mode);
  };

  useEffect(() => {
    const localTheme = getFromLS('theme');
    localTheme ? setTheme(localTheme) : setTheme(lightColor);
    setThemeLoaded(true);
  }, []);

  return { theme, themeLoaded, setMode };
};
