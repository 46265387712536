const path = {
  root: '/',
  homePage: '/home',
  dashboard: '/dashboard',

  manageUser: '/users',
  individualUsers: '/users/individuals',
  individualUserInformation: '/users/individuals/:id',
  permissions: '/users/permissions',

  //   individualUsers: '/users/main',
  // createMainUser: '/users/main/create',
  // individualUserInformation: '/users/individuals/:id',

  history: '/history',
  historyManagement: '/history/list',

  managerGameList: '/games',
  gameStrangeCollecting: '/games/strange-collecting',
  gameAddictiveMatch: '/games/addictive-match',
  gameCreate: '/games/create',
  gameList: '/games/list',
  gameDetail: '/games/list/detail',

  campaign: '/campaigns',
  campaignDashboard: '/campaigns/:id/dashboard',
  campaignInvidualUser: '/campaigns/:id/individuals',
  campaignManagement: '/campaigns/players',
  createCampaign: '/campaigns/create',
  playerManagement: '/campaigns/players/list',
  giftManagement: '/campaigns/gifts/list',
  rakingManagement: '/campaigns/rakings/list',
  campaingnGames: '/campaigns/:campaignId/games',
  campaingnGameConfiguration: '/campaigns/:id/game-configuration',
  gameManagementVersionDetail: '/campaigns/:id/games/:gameId/game-versions/:gameVersion_id',
  gameManagementVersion: '/campaigns/:id/games/:gameId/game-versions',
  gameManagement: '/campaigns/:id/list-game',
  gameBase: '/campaigns/:id/list-game/:gameId',
  addGame: '/campaigns/:id/list-game/add-game',
  gameRequestNewTheme: '/campaigns/:id/list-game/:gameId/request-new-theme',

  gameRewardParent: '/campaigns/:id/reward',
  gameReward: '/campaigns/:id/reward/game-reward',
  gameRewardGiftCode: '/campaigns/:id/reward/game-reward/:rewardId',
  gameRewardRedemption: '/campaigns/:id/reward/redemption',
  campaignSettings: '/campaigns/:id/campaign-settings',
  campaignStaff: '/campaigns/:id/staffs',
  leaderBoard: '/campaigns/:id/leaderBoard',
  campaignPlayer: '/campaigns/:id/players',

  supportCenter: '/campaigns/:id/support-center',

  campaignPayment: '/campaigns/:id/payment',
  paymentServicePack: '/campaigns/:id/payment/service-pack',
  extendPackage: '/campaigns/:id/payment/extend-package',
  paymentInvoice: '/campaigns/:id/payment/invoice',
  PrepaidPayment: '/campaigns/:id/payment/service-pack/prepaid-payment',
  paymentSuccess: '/giao-dich',

  notificationBase: '/notification/all-list',

  product: '/products',
  productManagement: '/products/list',

  blog: '/blog',
  blogManagement: '/blog/list',

  login: '/auth/login',
  signUp: '/auth/sign-up',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/reset-password',
  myProfile: '/profile',
  changePassword: '/change-password',
  loginFailed: '/auth/login-failed',

  notFound: '/not-found',

  maintenance: '/maintenance',

  requestNewThemePublic: '/request-new-theme',

  all: '*',
};

export default path;
