import { Box, FormControlLabel, IconButton, Radio, RadioGroup, Stack } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFromLS, setToLS } from 'utils/helpers';
import LanguageIcon from '@mui/icons-material/Language';
import MenuPopover from '../../components/MenuPopover';
// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English (US)',
    icon: '/static/icons/ic_flag_en.svg',
    index: 0,
  },
  {
    value: 'vi',
    label: 'Vietnamese',
    icon: '/static/icons/ic_flag_vi.svg',
    index: 1,
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const { i18n } = useTranslation();
  const handleOpen = () => {
    setOpen(true);
  };
  useMemo(() => {
    if (getFromLS('translate') !== i18n.language) {
      clickLanguage(getFromLS('translate'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (i18n.language === 'en') {
      setIndex(0);
    } else {
      setIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLan = (index: number, value: string) => {
    setIndex(index);
    setOpen(false);
    clickLanguage(value);
    window.location.reload();
    setToLS('translate', value);
  };

  function clickLanguage(lang: string) {
    i18n.changeLanguage(lang);
  }

  return (
    <>
      <IconButton ref={anchorRef} sx={{ width: 44, height: 44 }} onClick={handleOpen}>
        <LanguageIcon />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack>
          {LANGS.map((option) => (
            <Box key={option.label} sx={{ padding: '0 12px' }}>
              <RadioGroup defaultValue={LANGS[index].value}>
                <FormControlLabel
                  value={option.value}
                  onClick={() => handleChangeLan(option.index, option.value)}
                  control={<Radio />}
                  label={option.label}
                />
              </RadioGroup>
            </Box>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
