import { Box } from '@mui/material';
import PopUpExtendPackage from './PopUpExtendPackage';
// import ServicePack from './ServicePack';

export function ExtendPackage() {
  return (
    <Box mt={2} mx={2}>
      <PopUpExtendPackage />
    </Box>
  );
}
