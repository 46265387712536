import ForgotPassword from 'app/pages/Auth/ForgotPassword';
import ResetPassword from 'app/pages/Auth/ResetPassword';
import SignInFailedPage from 'app/pages/Auth/SignInFailedPage';
import Auth from 'app/pages/Auth/SignInPage';
import SignUpPage from 'app/pages/Auth/SignUpPage';
import { CreateCampaign } from 'app/pages/CampaignManagement/CreateCampaign/Loadable';
import { CampaignSettings } from 'app/pages/CampaignSettings/Loadable';
import { ChangePassword } from 'app/pages/ChangePassword/Loadable';
import Dashboard from 'app/pages/Dashboard/Loadable';
import { GameConfiguration } from 'app/pages/GameBaseManagement/GameConfiguration/Loadable';
import AddGame from 'app/pages/GameBaseManagement/GameList/component/AddGame';
import { GameList } from 'app/pages/GameBaseManagement/GameList/Loadable';
import { GameListVersion } from 'app/pages/GameListVersion';
import { HomePage } from 'app/pages/HomePage/Loadable';
import DashboardLayout from 'app/pages/Layouts/DashboardLayout';
import LogoOnlyLayout from 'app/pages/Layouts/LogoOnlyLayout';
import { LeaderBoard } from 'app/pages/LeaderBoardManagement/Loadable';
import NotFound from 'app/pages/NotFound';
import { NotificationManagement } from 'app/pages/NotificationManament';
import { PaymentInvoice } from 'app/pages/PaymentManagement/components/PaymentInvoice/Loadable';
import { PaymentServicePack } from 'app/pages/PaymentManagement/components/PaymentServicePack/Loadable';
import { PaymentSuccess } from 'app/pages/PaymentManagement/components/PaymentSuccess/Loadable';
import { PlayerManagement } from 'app/pages/PlayerManagement/ListPlayer/Loadable';
import { RewardRedemManagement } from 'app/pages/RewardRedemManagement/ListRedemReward/Loadable';
import { RewardManagement } from 'app/pages/RewardsManagement/ListReward/Loadable';
import { ListStaffManagement } from 'app/pages/SaffManagement/ListStaff/Loadable';
import { SystemMaintenance } from 'app/pages/SystemMaintenance/Loadable';
import { useLocation } from 'react-router';
import { Navigate, useRoutes } from 'react-router-dom';
import { isAuthenticated } from 'utils/auth';
import { GameRequestNewTheme } from 'app/pages/GameBaseManagement/GameRequestNewTheme/Loadable';
import { RequestNewTheme } from 'app/pages/RequestNewThemePublic/Loadable';
import { RewardManagementDetailGiftCode } from 'app/pages/RewardsManagement/ListRewardHasDetailGiftCode/Loadable';
import { ExtendPackage } from 'app/pages/PaymentManagement/components/ExtendPackage';
import DetailSupportCenter from 'app/components/SupportCenter/DetailSupportCenter/index';
import path from './path';


export default function Router() {
  const location = useLocation();

  return useRoutes([
    {
      path: path.forgotPassword,
      element: <ForgotPassword />,
    },
    {
      path: path.signUp,
      element: <SignUpPage />,
    },
    {
      path: path.resetPassword,
      element: <ResetPassword />,
    },
    {
      path: path.loginFailed,
      element: <SignInFailedPage />,
    },
    {
      path: path.paymentSuccess,
      element: <PaymentSuccess />,
    },
    {
      path: path.maintenance,
      element: <SystemMaintenance />,
    },
    {
      path: path.requestNewThemePublic,
      element: <RequestNewTheme />,
    },
    {
      path: path.root,
      element: isAuthenticated() ? <DashboardLayout /> : <Navigate to={path.login} state={{ from: location }} />,
      children: [
        { element: <Navigate to={path.root} replace /> },
        {
          element: <HomePage />,
          path: path.homePage,
        },
        {
          path: path.campaignDashboard,
          element: <Dashboard />,
        },
        {
          path: path.supportCenter,
          element: <DetailSupportCenter />,
        },
        {
          path: path.campaignStaff,
          element: <ListStaffManagement />,
        },
        {
          path: path.campaingnGameConfiguration,
          element: <GameConfiguration />,
        },
        {
          path: path.campaignPlayer,
          element: <PlayerManagement />,
        },
        {
          path: path.gameManagement,
          element: <GameList />,
        },
        {
          path: path.gameManagementVersion,
          element: <GameListVersion />,
        },
        {
          path: path.gameManagementVersionDetail,
          element: <GameConfiguration />,
        },
        {
          path: path.gameBase,
          element: <GameConfiguration />,
        },
        {
          path: path.addGame,
          element: <AddGame />,
        },
        {
          path: path.gameRequestNewTheme,
          element: <GameRequestNewTheme />,
        },
        {
          path: path.gameRewardParent,
          children: [
            {
              path: path.gameReward,
              element: <RewardManagement />,
            },
            {
              path: path.gameRewardRedemption,
              element: <RewardRedemManagement />,
            },
          ],
        },
        {
          path: path.gameRewardGiftCode,
          element: <RewardManagementDetailGiftCode />,
        },
        {
          path: path.campaignPayment,
          children: [
            {
              path: path.paymentInvoice,
              element: <PaymentInvoice />,
            },
            {
              path: path.paymentServicePack,
              element: <PaymentServicePack />,
            },
            {
              path: path.paymentServicePack,
              element: <PaymentServicePack />,
            },
          ],
        },
        {
          path: path.extendPackage,
          element: <ExtendPackage />,
        },
        {
          path: path.leaderBoard,
          element: <LeaderBoard />,
        },
        {
          path: path.campaignSettings,
          element: <CampaignSettings />,
        },
        {
          path: path.campaign,
          children: [
            {
              path: path.createCampaign,
              element: <CreateCampaign />,
            },
          ],
        },
        {
          path: path.notificationBase,
          element: <NotificationManagement />,
        },
      ],
    },
    {
      path: path.changePassword,
      element: <ChangePassword />,
    },

    {
      path: path.root,
      element: <LogoOnlyLayout />,
      children: [
        {
          path: path.login,
          element: isAuthenticated() ? <Navigate to={path.root} /> : <Auth />,
        },
        { path: path.notFound, element: <NotFound /> },
      ],
    },
    { path: path.all, element: <Navigate to={path.notFound} replace /> },
  ]);
}
