import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Dialog, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { useProfile } from 'app/hooks';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import { UploadAvatar } from 'app/pages/MyProfile/components/UpdateProfile/UploadAvatar';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryImage } from 'types/Enum';
import * as yup from 'yup';
import { useFeedbackManagementSlice } from './slice';
import { FeedbackInfo } from './slice/types';

interface FeedbackProps {
  openDialog: boolean;
  handleCloseDialog: () => void;
}

export default function SubmitFeedback(props: FeedbackProps) {
  const { openDialog, handleCloseDialog } = props;
  const user = useProfile();
  const { themeInfo } = useSelector(layoutsSelector);
  const theme = useTheme();
  const { t } = useTranslation();
  const [valueAvt, setValueAvt] = useState<any>('');
  const { actions } = useFeedbackManagementSlice();
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>();

  const SubmitFeedback = () => {
    setIsLoadingSubmit(true);
    const params: FeedbackInfo = {
      fullName: getValues('name'),
      email: getValues('email'),
      message: getValues('description'),
      image: valueAvt ? valueAvt.join(',') : '',
      type: CategoryImage.feedback,
    };
    dispatch(
      actions.feedback(params, (error?: any) => {
        setIsLoadingSubmit(false);
        if (!error) {
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('feedback_success')}`,
              type: 'success',
            })
          );
          setTimeout(() => {
            handleCloseDialog();
          }, 500);
        } else {
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('feedback_erorr')}`,
              type: 'error',
            })
          );
        }
      })
    );
  };

  const formSchema = yup.object().shape({
    name: yup.string().required(t('isRequired')).max(50, t('validated.noMoreThan50Characters')),
    email: yup.string().required(t('isRequired')).email(t('validated.incorrectFormat')),
    description: yup.string().required(t('validated.pleaseEnterDescription')),
  });

  const {
    control,
    getValues,
    clearErrors,
    setValue,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(formSchema), mode: 'all' });

  useEffect(() => {
    setValue('name', user?.name);
    setValue('email', user?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAvatar = (_value: boolean) => {};

  const onRemoveFile = (_index: string) => {
    const newValue = valueAvt.filter((data: string) => data !== _index);
    setValueAvt(newValue);
  };

  const renderUpload = React.useMemo(() => {
    let width = Number.parseInt('85');
    let height = Number.parseInt('85');
    if (width > 300 || width < 64) {
      const newW = width > 300 ? 80 : 64;
      height = (newW * height) / width;
      width = newW;
    }
    if (height > 300 || height < 64) {
      const newH = height > 300 ? 80 : 64;
      width = (newH * width) / height;
      height = newH;
    }

    if (valueAvt) {
      return valueAvt.map((_item: string, index: number) => (
        <Box
          key={index}
          sx={(theme) => ({
            position: 'relative',
            background: theme.palette.success.darker,
            border: '1px solid',
            borderRadius: '4px !important',
            marginRight: '15px',
          })}
        >
          <Avatar
            variant="rounded"
            src={_item}
            alt="images"
            sx={{
              height: '80px',
              width: '80px',
              '& img': {
                objectFit: 'cover',
              },
            }}
          />
          <Box position="absolute" top={5} right={5} sx={{ cursor: 'pointer' }} onClick={() => onRemoveFile(_item)}>
            <Icon icon="carbon:close-filled" width="20px" height="20px" />
          </Box>
        </Box>
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueAvt]);

  return (
    <Dialog open={openDialog} fullWidth maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 16px 16px 36px',
        }}
      >
        <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{t('submit_feedback')}</Typography>
        <IconButton
          onClick={() => {
            handleCloseDialog();
          }}
        >
          <CloseIcon style={{ color: `${themeInfo?.bgColor}` }} />
        </IconButton>
      </Box>
      <Box sx={{ padding: '0 36px 20px' }}>
        <form>
          <Box>
            {/*------------- Full Name -------------*/}
            <Controller
              control={control}
              name="name"
              render={({ field }) => {
                return (
                  <TextField
                    sx={{ marginBottom: '30px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <Typography
                        variant="subtitle2"
                        noWrap
                        color={'primary'}
                        sx={{
                          fontSize: 15,
                          fontWeight: 400,
                          mt: '0px',
                          mb: '10px',
                          display: 'flex',
                          marginBottom: '30px',
                        }}
                      >
                        {t('fullName')}
                        <Typography
                          sx={(theme) => ({
                            fontSize: 15,
                            fontWeight: 400,
                            color: theme.palette.info.darker,
                            margin: '1px 3px 0px 4px',
                          })}
                        >
                          *
                        </Typography>
                      </Typography>
                    }
                    {...field}
                    id="name"
                    size="small"
                    fullWidth
                    variant="filled"
                    defaultValue={user?.name}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors('name');
                    }}
                    helperText={errors?.name?.message}
                    error={errors?.name?.message}
                    placeholder="Enter full name"
                  />
                );
              }}
            />
            {/*------------- Email -----------*/}
            <Controller
              control={control}
              name="email"
              render={({ field }) => {
                return (
                  <TextField
                    sx={{ marginBottom: '30px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <Typography
                        variant="subtitle2"
                        noWrap
                        color={'primary'}
                        sx={{ fontSize: 15, fontWeight: 400, mt: '0px', mb: '10px', display: 'flex' }}
                      >
                        Email
                        <Typography
                          sx={(theme) => ({
                            fontSize: 15,
                            fontWeight: 400,
                            color: theme.palette.info.darker,
                            margin: '1px 3px 0px 4px',
                          })}
                        >
                          *
                        </Typography>
                      </Typography>
                    }
                    {...field}
                    id="email"
                    size="small"
                    fullWidth
                    defaultValue={user?.email}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors('email');
                    }}
                    variant="filled"
                    placeholder="Enter email"
                    helperText={errors?.email?.message}
                    error={errors?.email?.message}
                  />
                );
              }}
            />
            {/*------------- Description -------------*/}
            <Controller
              control={control}
              name="description"
              render={({ field }) => {
                return (
                  <TextField
                    sx={{ marginBottom: '30px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={
                      <Typography
                        variant="subtitle2"
                        noWrap
                        color={'primary'}
                        sx={{
                          fontSize: 15,
                          fontWeight: 400,
                          mt: '0px',
                          mb: '10px',
                          display: 'flex',
                          marginBottom: '30px',
                        }}
                      >
                        {t('reward.description')}
                        <Typography
                          sx={(theme) => ({
                            fontSize: 15,
                            fontWeight: 400,
                            color: theme.palette.info.darker,
                            margin: '1px 3px 0px 4px',
                          })}
                        >
                          *
                        </Typography>
                      </Typography>
                    }
                    {...field}
                    id="description"
                    size="small"
                    fullWidth
                    variant="filled"
                    multiline
                    rows={5}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors('description');
                    }}
                    helperText={errors?.description?.message}
                    error={errors?.description?.message}
                    placeholder={t('reward.desc')}
                    inputProps={{
                      style: {},
                    }}
                  />
                );
              }}
            />

            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{t('attach_photo')}</Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {renderUpload}
            </div>

            <UploadAvatar
              setValueAvt={setValueAvt}
              valueAvt={valueAvt}
              handleAvatar={handleAvatar}
              errors={errors}
              isMultiple={true}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleCloseDialog}
              sx={(theme) => ({
                color: theme.palette.secondary.contrastText,
                border: `1px solid ${theme.palette.primary.darker}`,
                padding: '0px 20px',
                height: 36,
                mr: 1,
              })}
            >
              {`${t('common.cancel')}`}
            </Button>
            <LoadingButton
              // type="submit"
              loading={isLoadingSubmit}
              disabled={!isValid}
              sx={{
                width: '100px',
                backgroundColor: theme.palette.primary.main,
                height: 36,
                '&:hover': {
                  color: theme.palette.common.white,
                },
              }}
              onClick={() => {
                SubmitFeedback();
              }}
            >
              <img src="/static/icons/Send.png" alt="" width={18} height={18} style={{ marginRight: '10px' }} />
              {t('Submit')}
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}
