import { useState, useCallback } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { EMAIL_REGEX, PASSWORD_REGEX } from 'utils/helpers/regex';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField, IconButton, InputAdornment, styled, Box } from '@mui/material';
import { SignUpParams } from 'types';
import { useDispatch } from 'react-redux';
import { useSnackbarSlice } from '../../../SnackBar/slice';

export const RootStyle = styled('div')({
  '& .MuiFilledInput-root': {
    borderRadius: '8px',
    background: '#F6F8FC',
  },
  '& .MuiFilledInput-root:after': {
    right: 'unset',
    border: '1px solid  #005FC5',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root.Mui-error:after': {
    border: '1px solid  #FF4842',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root:before': {
    right: 'unset',
    content: '""',
  },
  '& .MuiFormControl-root:after': {
    border: '1px solid  #005FC5',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
});

// ----------------------------------------------------------------------
interface Props {
  onSubmit: (values: SignUpParams) => void;
  error?: any;
  onClearError?: () => void;
  loading: boolean;
}

const authSchema = Yup.object()
  .shape({
    name: Yup.string().required('This field is required.').max(50, 'Must equal or than more 50 characters'),
    email: Yup.string().required('This field is required.').email('Wrong email format!'),
    password: Yup.string().required('This field is required.').max(15, 'Password up to 15 characters!'),
    confirm_password: Yup.string()
      .required('This field is required.')
      .oneOf([Yup.ref('password')], 'Passwords do not match. Please check again!'),
  })
  .required();

export default function SignUpForm(props: Props) {
  const { onSubmit, error, onClearError, loading } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();

  const {
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm<SignUpParams>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirm_password: '',
    },
    resolver: yupResolver(authSchema),
    mode: 'all',
  });
  const [isDisableButtonLogin, setIsdisableButtonLogin] = useState<boolean>(false);

  const handleShowPassword = useCallback((type: string) => {
    if (type === 'password') return setShowPassword((show) => !show);
    setShowConfirmPassword((show) => !show);
  }, []);

  const validatedEmail = () => {
    if (getValues('email') && !EMAIL_REGEX.test(getValues('email'))) {
      setError('email', { message: 'Wrong email format!' });
      return;
    }
  };

  const validatedPassword = () => {
    if (getValues('password') && !PASSWORD_REGEX.test(getValues('password'))) {
      setError('password', {
        message: 'Password must be between 8 and 15 characters and include letters, numbers, and special characters!',
      });
      return;
    }
  };

  if (!!errors?.name || !!errors?.email || !!errors.password || !!errors.confirm_password) {
    dispatch(
      snackbarActions.updateSnackbar({
        message: 'Please check the information.',
        type: 'error',
      })
    );
  }
  return (
    <RootStyle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  sx={{
                    '& .MuiFilledInput-input': {
                      paddingTop: '12px',
                    },
                  }}
                  {...field}
                  fullWidth
                  variant="filled"
                  placeholder={'Full name'}
                  error={!!errors?.name || error?.error === 'invalid_user'}
                  helperText={errors?.name?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors('name');
                    onClearError && onClearError();
                  }}
                  onBlur={(e: any) => {
                    field.onChange(e.target.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '));
                  }}
                />
              );
            }}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  sx={{
                    '& .MuiFilledInput-input': {
                      paddingTop: '12px',
                    },
                  }}
                  {...field}
                  fullWidth
                  variant="filled"
                  placeholder={'Email address'}
                  error={!!errors?.email || error?.message === 'Email is already in use. Please check again.'}
                  helperText={error ? 'This email already exists' : errors?.email?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors('email');
                  }}
                  onBlur={(e: any) => {
                    field.onChange(e.target.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '));
                    validatedEmail();
                  }}
                />
              );
            }}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  sx={{
                    '& .MuiFilledInput-input': {
                      paddingTop: '12px',
                    },
                  }}
                  fullWidth
                  variant="filled"
                  placeholder={'Password'}
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.password || error?.error === 'invalid_password'}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleShowPassword('password')} edge="end">
                          <img
                            src={showPassword ? '/static/icons/eye.svg' : '/static/icons/eye-off.svg'}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors('password');
                  }}
                  onBlur={validatedPassword}
                />
              );
            }}
          />
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  sx={{
                    '& .MuiFilledInput-input': {
                      paddingTop: '12px',
                    },
                  }}
                  fullWidth
                  variant="filled"
                  placeholder={'Confirm password'}
                  type={showConfirmPassword ? 'text' : 'password'}
                  error={!!errors.confirm_password || error?.error === 'invalid_password'}
                  helperText={errors.confirm_password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleShowPassword('confirm_password')} edge="end">
                          <img
                            src={showConfirmPassword ? '/static/icons/eye.svg' : '/static/icons/eye-off.svg'}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value && getValues('email')) {
                      setIsdisableButtonLogin(false);
                    }
                    field.onChange(e);
                    onClearError && onClearError();
                  }}
                />
              );
            }}
          />
        </Stack>
        <Box
          mt={2}
          mx={5}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ marginLeft: { xs: '12px' }, '& ul': { fontSize: '12px', fontWeight: 400 } }}
        >
          <ul>
            <li>Must have 8 - 15 characters</li>
            <li>Including: upper-case letter, lower-case letter, number and special character.</li>
          </ul>
        </Box>
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          disabled={isDisableButtonLogin}
          sx={(theme) => ({
            color: '#FFFFFF',
            mt: 5,
            padding: '20px 0',
            fontWeight: '700',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            bgcolor: '#585858',
            height: '36px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '0px',
            borderTopRightRadius: '0px',
            marginTop: { xs: '15px' },
          })}
        >
          Sign up
        </LoadingButton>
      </form>
    </RootStyle>
  );
}
