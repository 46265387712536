import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from '.';

const selectSlice = (state: RootState) => state.mainGame || initialState;
const selecMainGametSlice = (state: RootState) => state.mainGame || initialState;

export const selectMainGame = createSelector([selectSlice], (state) => state);
export const selectMainGameList = createSelector([selecMainGametSlice], (state) => state);
