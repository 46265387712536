/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from '@iconify/react';
import { Button, Divider, FormHelperText, Popover, Stack, Typography, useTheme } from '@mui/material';
import { useProfile } from 'app/hooks';
import { useCreateCampaignSlice } from 'app/pages/HomePage/slice';
import path from 'app/routes/path';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CampaignRolesType } from 'types';
import SettingsIcon from '@mui/icons-material/Settings';
import { snackbarActions } from 'app/pages/SnackBar/slice';
import { RoleStaff } from 'types/Enum';

export default function CampaignMore() {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const user = useProfile();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const theme = useTheme();
  const { actions } = useCreateCampaignSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const id_campaign = Number(id);
      dispatch(
        actions.fetchCampaignData({ id_campaign }, (err?: any) => {
          if (err?.response?.data?.message === 'account_is_inactive') {
            navigate(path.homePage);
            dispatch(
              snackbarActions.updateSnackbar({
                message: t('notification.accountDisabled'),
                type: 'error',
              })
            );
          }
        })
      );
    }
  }, [params, id]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSeeAllCampaign = () => {
    navigate(path.homePage);
    setIsOpen(false);
  };

  const handleCampaignSettings = () => {
    navigate(`${path.campaign}/${id}/campaign-settings`);
    setIsOpen(false);
  };

  const handleRedirectCampaign = (id: number) => {
    navigate(`${path.campaign}/${id}/dashboard`);
    setIsOpen(false);
  };

  return (
    <>
      <>
        <Button
          ref={anchorRef}
          onClick={handleOpen}
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'flex-start',
            height: 44,
            color: theme.palette.primary.main,
            padding: '15px 10px',
            borderColor: theme.palette.secondary.lighter,
          })}
          endIcon={<Icon icon="eva:arrow-ios-downward-fill" />}
        >
          <Stack>
            {user?.campaigns_roles?.map((item) => {
              return <Stack>{item.campaign_id === Number(id) && item.campaign_name}</Stack>;
            })}
          </Stack>
        </Button>
        <Popover
          sx={{
            left: '25px',
          }}
          open={isOpen}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack padding={2} width={'236px'} height={'309px'}>
            <Stack>
              {user?.campaigns_roles?.map((item) => {
                return item.campaign_id === Number(id) && item.role === RoleStaff.CUSTOMER_OWNER ? (
                  <Button
                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pl: 0, pr: 0 }}
                    onClick={handleCampaignSettings}
                  >
                    <Typography
                      variant="subtitle2"
                      noWrap
                      color={'primary'}
                      sx={{ fontSize: 14, fontWeight: 500, mr: '30px' }}
                    >
                      {t('Campaign Settings')}
                    </Typography>
                    <SettingsIcon width={20} height={20} style={{ color: theme.palette.primary.main, opacity: 0.5 }} />
                  </Button>
                ) : (
                  ''
                );
              })}
            </Stack>

            <Button
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pl: 0, pr: 0 }}
              onClick={handleSeeAllCampaign}
            >
              <Typography
                variant="subtitle2"
                noWrap
                color={'primary'}
                sx={{ fontSize: 14, fontWeight: 500, mr: '30px' }}
              >
                {t('See all campaigns')}
              </Typography>
              <Icon
                icon="eva:home-fill"
                width={20}
                height={20}
                style={{ color: theme.palette.primary.main, opacity: 0.5 }}
              />
            </Button>
            <Divider sx={{ mt: '27px', mb: '24px' }} />
            <FormHelperText sx={{ mb: '24px', fontSize: 12, opacity: 0.5 }}>{t('All campaigns')}</FormHelperText>
            {user?.campaigns_roles
              ?.filter((item) => item.campaign_id !== Number(id))
              .map((campaign: CampaignRolesType) => (
                <Button
                  key={campaign.campaign_id}
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', pl: 0, pr: 0 }}
                  onClick={() => handleRedirectCampaign(campaign.campaign_id)}
                >
                  <Typography
                    variant="subtitle2"
                    noWrap
                    color={'primary'}
                    sx={{ fontSize: 14, fontWeight: 500, mr: '30px' }}
                  >
                    {campaign?.campaign_name}
                  </Typography>
                </Button>
              ))}
          </Stack>
        </Popover>
      </>
    </>
  );
}
