import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import memberService from 'services/api/memberService';

import {
  ChangeNameParamVersionPublic,
  GameBaseParam,
  GameParam,
  GameParamFetchGameThemesVersion,
  GameParamVersion,
  GameParamVersionDelete,
  GameParamVersionPublic,
} from 'types/Game';

import { DataMainGameProps } from './types';

import { mainGameActionsGameVersion as actions } from '.';

function* fetchMainGameListDataVersion(action: PayloadAction<GameParamVersion, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.fetchMainGameVersion, action.payload);
    yield put(actions.fetchMainGameListSuccess(result));
  } catch (errors) {}
}

export function* deleteGameVersion(action: PayloadAction<GameParamVersionDelete, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.deleteGameVersion, action.payload);
    yield put(actions.deleteGameSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* PublicGameVersion(action: PayloadAction<GameParamVersionPublic, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.PublicGameVersion, action.payload);
    yield put(actions.PublicGameSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* fetchGameThemesVersion(
  action: PayloadAction<GameParamFetchGameThemesVersion, string, (error?: any) => void>
) {
  try {
    const result: DataMainGameProps = yield call(memberService.fetchGameThemesVersion, action.payload);
    yield put(actions.fetchGameThemesSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* changeNameVersion(action: PayloadAction<ChangeNameParamVersionPublic, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.changeNameVersion, action.payload);
    yield put(actions.changeNameGameSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* DuplicateGameVersion(action: PayloadAction<GameParamVersionDelete, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.DuplicateGameVersion, action.payload);
    yield put(actions.DuplicateGameSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

function* fetchListGameBaseData() {
  try {
    const result: DataMainGameProps = yield call(memberService.fetchListGameBaseData);
    yield put(actions.fetchListGameBaseDataSuccess(result));
  } catch (error) {}
}

export function* actionGame(action: PayloadAction<GameParam, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.actionGame, action.payload);
    yield put(actions.actionGameSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* chooseGameBase(action: PayloadAction<GameBaseParam, string, (error?: any) => void>) {
  try {
    const result: DataMainGameProps = yield call(memberService.chooseGameBase, action.payload);
    yield put(actions.chooseGameBaseSuccess(result));
    action.meta();
  } catch (error: any) {
    action.meta(error.response?.data);
  }
}

export function* mainGameSagaGameVersion() {
  yield takeLatest(actions.fetchMainGameListVersion.type, fetchMainGameListDataVersion);
  yield takeLatest(actions.deleteGameVersion.type, deleteGameVersion);
  yield takeLatest(actions.DuplicateGameVersion.type, DuplicateGameVersion);
  yield takeLatest(actions.PublicGameVersion.type, PublicGameVersion);
  yield takeLatest(actions.fetchGameThemesVersion.type, fetchGameThemesVersion);
  yield takeLatest(actions.changeNameGameVersion.type, changeNameVersion);
  yield takeLatest(actions.actionGame.type, actionGame);
  yield takeLatest(actions.fetchListGameBaseData.type, fetchListGameBaseData);
  yield takeLatest(actions.chooseGameBase.type, chooseGameBase);
}
