import { Icon } from '@iconify/react';
import { IconButton, Menu } from '@mui/material';
import AuthorizationRouteCampaign from 'app/components/Permission/PermissionCampaign';
import { ReactNode, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PermissionsType } from 'types/Enum';
import { MainGameTypes } from '../slice/types';
import ActionGame from './ActionGame';

export interface Props {
  gameId?: number;
  game: MainGameTypes;
  getListGame: () => void;
  children?: ReactNode;
}

export default function GameMoreMenu({ game, getListGame, gameId, children }: Props) {
  const params = useParams();
  const { id } = params;
  const [isOpen, setIsOpen] = useState(false);

  const [openAction, setOpenAction] = useState(false);

  const ref = useRef(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  const onClose = (event: { stopPropagation?: any }) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      <AuthorizationRouteCampaign
        campaignId={Number(id)}
        allowedPermissions={[
          PermissionsType.papa_game_update,
          PermissionsType.papa_game_delete,
          PermissionsType.papa_game_status_update,
        ]}
      >
        <IconButton
          sx={(theme) => ({ color: theme.palette.primary.main, paddingTop: '24px' })}
          ref={ref}
          onClick={(event) => handleClick(event)}
        >
          <Icon icon="eva:more-vertical-fill" width={20} height={20} />
        </IconButton>
      </AuthorizationRouteCampaign>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={(event) => onClose(event)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {children}
      </Menu>

      {/* ............................... */}
      {openAction && <ActionGame open={openAction} setOpen={setOpenAction} game={game} getListGame={getListGame} />}
    </>
  );
}
