import queryString from 'query-string';
import { LocalStorageService } from 'services';
import {
  AuthParams,
  AuthResponse,
  ForgotPasswordRequest,
  RefreshTokenResponse,
  ResendVerifyEmailRequest,
  ResetPasswordRequest,
  SignUpParams,
  TokenRequest,
  UserInfo,
} from 'types';

import { createService, createServiceNoToken, createServiceRefreshToken } from './axios';

const TokenUrl = process.env.REACT_APP_API_URL;
// const LogoutUrl = process.env.REACT_APP_KEYCLOAK_LOGOUT_URL;

const instance = createServiceNoToken(TokenUrl);
const instanceWithToken = createService(process.env.REACT_APP_API_URL);
const instanceWithRefreshToken = createServiceRefreshToken(process.env.REACT_APP_API_URL);
// const instanceLogout = createServiceNoToken(LogoutUrl);

const login = async (params: AuthParams): Promise<AuthResponse> => {
  const res = await instance.post('/v1/auth/login', queryString.stringify(params), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data.data;
};

const signUp = async (params: SignUpParams): Promise<any> => {
  await instance.post('/v1/auth/register', queryString.stringify(params), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

const getUserInfo = async (): Promise<UserInfo> => {
  const url = '/v1/users/get-me';
  const res = await instanceWithToken.get(url);
  return res.data.data;
};

export const destroyToken = () => {
  LocalStorageService.removeAllItem();
};

const verifyUser = async (): Promise<any> => {
  const url = '/v1/users/verify';
  const res = await instanceWithToken.get(url);
  return res.status;
};

export const tryRefreshToken = () => {
  return authRequest({
    refresh_token: LocalStorageService.get(LocalStorageService.REFRESH_TOKEN),
  });
};

export const saveToken = (responseData: RefreshTokenResponse) => {
  LocalStorageService.set(LocalStorageService.OAUTH_TOKEN, responseData.access_token);
  LocalStorageService.set(LocalStorageService.REFRESH_TOKEN, responseData.refresh_token);
};

export const authRequest = (data: Record<string, any>) => {
  return instanceWithRefreshToken.get(`/v1/auth/refresh-token`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${LocalStorageService.get(LocalStorageService.REFRESH_TOKEN)}`,
    },
  });
};

const logout = async (): Promise<any> => {
  // const refresh_token = LocalStorageService.get(LocalStorageService.REFRESH_TOKEN);
  const access_token = LocalStorageService.get(LocalStorageService.OAUTH_TOKEN);
  // const request = {
  //   refresh_token,
  //   client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  // };
  await instance.get('/v1/auth/logout', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + access_token,
    },
  });
};

const loginByGoogle = async (tokenRequest: TokenRequest): Promise<AuthResponse> => {
  const response = await instance.post('/v1/auth/login/callback', queryString.stringify(tokenRequest), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.data.data;
};

const forotPassword = async (params: ForgotPasswordRequest): Promise<any> => {
  await instance.post('/v1/auth/reset-password', queryString.stringify(params), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

const resetPassword = async (params: ResetPasswordRequest): Promise<any> => {
  const newParams = { ...params };
  delete newParams.token;
  await instance.post(`/v1/auth/reset-password/${params.token}`, queryString.stringify(newParams), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

const resendVerifyEmail = async (params: ResendVerifyEmailRequest): Promise<any> => {
  await instance.post('/v1/auth/resend-email-active', queryString.stringify(params), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export default {
  login,
  getUserInfo,
  verifyUser,
  logout,
  signUp,
  loginByGoogle,
  forotPassword,
  resetPassword,
  resendVerifyEmail,
};
