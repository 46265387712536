import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import {
  ChangeNameParamVersionPublic,
  GameBaseParam,
  GameParamFetchGameThemesVersion,
  GameParamVersion,
  GameParamVersionDelete,
  GameParamVersionDuplicate,
} from 'types/Game';

import { DataMainGameProps, MainGameState } from './types';

import { mainGameSagaGameVersion } from './saga';

export const initialState: MainGameState = {};

const slice = createSlice({
  name: 'mainGameVersion',
  initialState,
  reducers: {
    fetchMainGameListVersion: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParamVersion, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    fetchMainGameListSuccess(state, action: PayloadAction<DataMainGameProps>) {
      state.mainGameList = action.payload;
      state.isLoadingTable = false;
    },
    deleteGameSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    deleteGameVersion: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParamVersionDelete, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    PublicGameSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    PublicGameVersion: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParamVersionDelete, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    fetchGameThemesSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    fetchGameThemesVersion: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParamFetchGameThemesVersion, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    changeNameGameSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    changeNameGameVersion: {
      reducer(state) {
        return state;
      },
      prepare(params: ChangeNameParamVersionPublic, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    DuplicateGameSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    DuplicateGameVersion: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParamVersionDuplicate, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    actionGameSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    actionGame: {
      reducer(state) {
        return state;
      },
      prepare(params: GameParamVersion, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
    fetchListGameBaseData(state) {},
    fetchListGameBaseDataSuccess: (state, action: PayloadAction<DataMainGameProps>) => {
      state.mainGameList = action.payload;
    },
    chooseGameBaseSuccess(state, action: PayloadAction<DataMainGameProps>) {},
    chooseGameBase: {
      reducer(state) {
        return state;
      },
      prepare(params: GameBaseParam, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
  },
});

export const { actions: mainGameActionsGameVersion } = slice;

export const useMainGameSliceGameVersion = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: mainGameSagaGameVersion });
  return { actions: slice.actions };
};
