import { PayloadAction } from '@reduxjs/toolkit';
import { Pageable } from 'types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { paymentManagementSaga } from './saga';
import {
  DataAllPackage,
  DataPlayTurnUsedAction,
  ListInvoice,
  PayloadOrderPayment,
  PayloadPayment,
  PaymentState,
} from './types';

export const initialState: PaymentState = {};

const slice = createSlice({
  name: 'paymentManagement',
  initialState,
  reducers: {
    orderPaymentSuccess() {},
    orderPayment: {
      reducer(state) {
        return state;
      },
      prepare(params: PayloadOrderPayment, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    updatePaymentSuccess() {},
    updatePayment: {
      reducer(state) {
        return state;
      },
      prepare(params: PayloadOrderPayment, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    orderPaymentPostpaidSuccess() {},
    orderPaymentPostpaid: {
      reducer(state) {
        return state;
      },
      prepare(params: PayloadOrderPayment, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    paymentSuccess() {},
    payment: {
      reducer(state) {
        return state;
      },
      prepare(params: PayloadPayment, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    fetchListInvoice(state, action) {
      state.isLoading = true;
    },
    fetchListInvoiceSuccess: (state, action: PayloadAction<Pageable<ListInvoice>>) => {
      state.listInvoice = action.payload;
      state.isLoading = false;
    },

    getListAllPackage(state, action) {
      state.isLoading = true;
    },
    getListAllPackageSuccess: (state, action: PayloadAction<Pageable<DataAllPackage>>) => {
      state.listAllPackage = action.payload;
      state.isLoading = false;
    },

    getListExtendPackage(state, action) {
      state.isLoading = true;
    },
    getListExtendPackageSuccess: (state, action: PayloadAction<Pageable<DataAllPackage>>) => {
      state.listAllPackage = action.payload;
      state.isLoading = false;
    },

    getUnitPricePackage(state, action) {
      state.isLoading = true;
    },
    getUnitPricePackageSuccess: (state, action: PayloadAction<Pageable<ListInvoice>>) => {
      state.listInvoice = action.payload;
      state.isLoading = false;
    },

    getPlayTurnUsed(state, action) {
      state.isLoading = true;
    },
    getPlayTurnUsedSuccess: (state, action: PayloadAction<DataPlayTurnUsedAction>) => {
      state.dataPlayTurnUsed = action.payload;
      state.isLoading = false;
    },

    downloadInvoiceSuccess() {},
    downloadInvoice: {
      reducer(state) {
        return state;
      },
      prepare(params: PayloadPayment, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },

    getUnitPrice(state, action) {
      state.isLoading = true;
    },
    getUnitPriceSuccess: (state, action: PayloadAction<any>) => {
      state.unitPrice = action.payload;
      state.isLoading = false;
    },
  },
});

export const { actions: paymentManagementActions } = slice;

export const usePaymentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: paymentManagementSaga });
  return { actions: slice.actions };
};
