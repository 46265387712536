import { Button } from '@mui/material';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UserInfo } from 'types';
import { useUpdateProfileManagementSlice } from '../../slice';

export interface Props {
  isDisableButtonLogin: boolean;
  user: UserInfo;
  nameInput: string;
  refecthDataUser: () => void;
  avataURL: string;
}

export default function UpdateProfile({ isDisableButtonLogin, user, nameInput, refecthDataUser, avataURL }: Props) {
  const { t } = useTranslation();
  const { actions } = useUpdateProfileManagementSlice();
  const dispatch = useDispatch();
  const { actions: snackbarActions } = useSnackbarSlice();

  const onClickUpdate = () => {
    const name = nameInput ? nameInput : user.name;
    const avatar = avataURL ? avataURL : user.avatar;
    const params = {
      name,
      phone_number: user.phone_number,
      phone_prefix: user.phone_prefix,
      address: user.address,
      meta: user.meta,
      birthday: user.birthday,
      avatar,
    };
    dispatch(
      actions.updateProfile(params, (error?: any) => {
        if (!error) {
          dispatch(
            snackbarActions.updateSnackbar({
              message: t('Saved changes'),
              type: 'success',
            })
          );
          refecthDataUser();
        }
      })
    );
  };

  return (
    <Button
      sx={(theme) => ({
        backgroundColor: theme.palette.success.lighter,
        color: theme.palette.primary.lighter,
        width: '117px',
        display: 'flex',
        height: '36px',
        borderTopLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '0px',
        borderTopRightRadius: '0px',
        mt: '20px',
        ml: '20px',
      })}
      startIcon={<img src="/static/icons/Icon-save.png" alt="btn-upload-phote" width={20} />}
      disabled={isDisableButtonLogin}
      onClick={onClickUpdate}
    >
      {t('Save')}
    </Button>
  );
}
