/* eslint-disable prettier/prettier */
import { Icon } from '@iconify/react';
import { AppBar, Box, IconButton, Stack, Toolbar, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AccountPopover from 'app/components/AccountPopover';

import { useAuthSlice } from 'app/pages/Auth/slice';
import path from 'app/routes/path';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import CampaignMore from '../CampaignMore';
import LanguagePopover from '../LanguagePopover';
import Notification from '../Notification';
import RemainPlayTurn from '../RemainPlayTurn';
import SupportCenter from '../SupportCenter';
import ThemeSwitchPopup from '../ThemeSwitchPopup';
import { useNavbarSlice } from './slice';
import { selectNavbar } from './slice/selectors';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 85;
const COLLAPSE_WIDTH = 100;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const Merge = styled('div')(({ theme }) => ({
  background: '#FFCD29',
  '& .overlay::before, .overlay::after': {
    background: 'transparent',
  },
}));

interface Props {
  onOpenSidebar?: () => void;
  isFullwidth?: boolean;
  handleChangeTheme?: (value: any) => void;
  isOpenNavBar: boolean;
}

function DashboardNavbar(props: Props) {
  const { id } = useParams();
  const theme = useTheme();
  const { onOpenSidebar, handleChangeTheme, isOpenNavBar } = props;
  const { actions } = useAuthSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { actions: strong } = useNavbarSlice();
  const { strongNoti } = useSelector(selectNavbar);
  const { themeInfo } = useSelector(layoutsSelector);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleCloseDialogMobile = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (strongNoti?.[0]?.type === 'TEXT') {
      setOpenDialog(true);
    }
  }, [strongNoti]);

  const RootStyle = styled(AppBar)(({ theme }) => ({
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: theme.palette.secondary.lighter,
    [theme.breakpoints.up('lg')]: {
      width: isOpenNavBar ? `calc(100% - ${COLLAPSE_WIDTH}px)` : `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  }));

  const MainMobile = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }));

  useEffect(() => {
    dispatch(actions.getUserInfo());
    dispatch(strong.strongNotification({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleRedirectHomePage = () => {
    if (location.pathname === path.homePage) {
      return false;
    } else {
      navigate(path.homePage);
    }
  };

  return (
    <RootStyle
      sx={{
        ...(!id && {
          width: '100% !important',
        }),
      }}
    >
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Icon icon="eva:menu-2-fill" />
        </IconButton>
        {!id && (
          <Box sx={{ width: '100%', height: '100%' }}>
            <img
              src={'/static/icons/Logo.png'}
              style={{ cursor: 'pointer' }}
              alt={'logo'}
              onClick={handleRedirectHomePage}
            />
          </Box>
        )}

        {/* <Box sx={{ flexGrow: 1 }} /> */}

        <Stack
          spacing={{ xs: 0.5, sm: 1.5 }}
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: { xs: 'end', sm: 'space-between' },
            width: '100%',
          }}
        >
          <MainMobile>
            <Stack>{id && <CampaignMore />}</Stack>
            {id && <RemainPlayTurn />}
          </MainMobile>
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5 }}>
            <MainMobile>
              <ThemeSwitchPopup handleChangeTheme={handleChangeTheme} />
              <LanguagePopover />
            </MainMobile>
            <Notification />
            {id && <SupportCenter />}
            <AccountPopover />
          </Stack>
        </Stack>
      </ToolbarStyle>
      {openDialog && (
        <Box
          sx={{
            background: theme.palette.error.dark,
            height: 'fit-content',
            gap: '15px',
            overflow: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '6px 20px 10px 40px' }}>
            <Box sx={{ color: `${themeInfo?.bgColor}`, marginTop: '10px', width: '100%' }}>
              <Merge>
                <Marquee
                  style={{
                    fontWeight: '700',
                    width: '100%',
                    height: '70%',
                  }}
                >
                  {strongNoti?.[0].name}
                  {'-'}
                  {strongNoti?.[0].description}
                </Marquee>
              </Merge>
            </Box>
            <Box>
              {' '}
              <IconButton
                onClick={() => {
                  handleCloseDialogMobile();
                }}
              >
                <CloseIcon style={{ color: `${themeInfo?.bgColor}` }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </RootStyle>
  );
}

export default memo(DashboardNavbar);
