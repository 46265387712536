import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import { useTranslation } from 'react-i18next';
import THEME_GAME_AR_1 from 'assets/background/theme-game-ar.png';
import THEME_GAME_AR_2 from 'assets/background/theme-game-ar-1.png';
import { MainGameTypes } from '../../slice/types';
import { useMainGameSlice } from '../../slice';
import WatchVideo from './WatchVideo';

export interface GameArSpecialProps {
  listGameAr: MainGameTypes[];
}
const HashtagStyle = styled('div')(({ theme }) => ({
  background: alpha(theme.palette.warning.lighter, 0.5),
  width: 'fit-content',
  borderRadius: '4px',
  padding: '3px 11px',
  marginRight: '24px',
  marginTop: '8px',
}));

const ThemeGame = styled('img')(({ theme }) => ({
  width: '60px',
  height: '60px',
  objectFit: 'cover',
  borderRadius: '4px',
}));

export default function GameArSpecial(props: GameArSpecialProps) {
  const { listGameAr } = props;
  const params = useParams();
  const { id } = params;
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { themeInfo } = useSelector(layoutsSelector);
  const { actions } = useMainGameSlice();
  const { actions: snackbarActions } = useSnackbarSlice();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const disableVideoGame = process.env.REACT_APP_DISABLE_VIDEO_GAME;
  const [currentTheme, setCurrentTheme] = useState<any>();
  const listTheme = useMemo(() => {
    if (listGameAr?.length) {
      return [listGameAr?.[0].game_thumbnail, THEME_GAME_AR_1, THEME_GAME_AR_2];
    }
    return [THEME_GAME_AR_1, THEME_GAME_AR_2];
  }, [listGameAr]);

  useEffect(() => {
    setCurrentTheme(listTheme[0]);
  }, [listTheme]);

  const handleAddGame = () => {
    const campaign_id = Number(id);
    const game_base_id = listGameAr[0].id;
    dispatch(
      actions.chooseGameBase({ campaign_id, game_base_id }, (err?: any) => {
        if (!err) {
          navigate(`/campaigns/${campaign_id}/list-game`);
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('Game added')}`,
              type: 'success',
            })
          );
        } else {
          if (err.message === 'this_game_added') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('The game already exists')}`,
                type: 'error',
              })
            );
            setOpen(false);
          }
          if (err.message === 'campaign_of_game_has_been_ended') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('campaignBeenEnded')}`,
                type: 'error',
              })
            );
            setOpen(false);
          }
        }
      })
    );
  };

  const handleCancelAction = () => {
    setOpen(false);
  };

  const handleActionGame = () => {
    setOpen(true);
  };

  const handleOpenWatchVideo = () => {
    setIsOpen(true);
  };

  const handleChangeTheme = (themeElement: any) => {
    setCurrentTheme(themeElement);
  };

  return (
    <Fragment>
      {!!listGameAr?.length && (
        <Card
          sx={{
            borderRadius: '8px',
            padding: '0px',
          }}
        >
          <Grid container>
            <Grid
              xs={6}
              sx={{
                '> img': {
                  height: '100%',
                  width: '100%',
                },
                position: 'relative',
              }}
            >
              <img src={currentTheme} alt="" />
              <Button
                sx={(theme) => ({
                  position: 'absolute',
                  top: 'calc(50% - 27px)',
                  left: 'calc(50% - 27px)',
                  backgroundColor: theme.palette.info.darker,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.lighter,
                  },
                  width: '53.33px',
                  height: '53.33px',
                  borderRadius: '30px',
                })}
                disabled={Boolean(disableVideoGame)}
                onClick={handleOpenWatchVideo}
              >
                <Icon icon="eva:arrow-right-fill" width={32} height={32} />
              </Button>
            </Grid>
            <Grid xs={6} px={'38px'} py={'28px'} bgcolor={theme.palette.background.default}>
              <Grid
                sx={{
                  fontFamily: 'Lexend',
                  fontWeight: 500,
                  fontSize: '24px',
                }}
              >
                {listGameAr?.[0].game_name}
              </Grid>
              <Grid sx={{ display: 'flex', flexWrap: 'wrap', mt: '12px' }}>
                <HashtagStyle>{'AR game'}</HashtagStyle>
                <HashtagStyle>{'Multiplayer'}</HashtagStyle>
              </Grid>
              <Grid sx={{ mt: '24px', display: 'flex', flexWrap: 'wrap' }}>
                {listTheme.map((themeElement, index) => (
                  <Grid
                    sx={{
                      marginRight: '14px',
                      padding: '3px',
                      border: currentTheme === themeElement ? `solid 2px ${theme.palette.info.darker}` : 'unset',
                      borderRadius: '8px',
                    }}
                  >
                    <ThemeGame key={index} src={themeElement} onClick={() => handleChangeTheme(themeElement)} />
                  </Grid>
                ))}
              </Grid>
              <Typography sx={{ mt: '24px', fontSize: '14px', fontFamily: 'Lexend', wordBreak: 'break-word' }}>
                The Hunter is an AR game that lets you interact with real-life while collecting items to earn points and
                receive rewards. Players can choose 2 modes: Solo Mode or Multiplayer. Various themes, suitable for many
                businesses.
              </Typography>
              <Button
                onClick={handleActionGame}
                sx={(theme) => ({
                  backgroundColor: `${themeInfo?.bgColor} !important`,
                  '&:hover': {
                    opacity: 0.5,
                  },
                  mt: '30px',
                  height: '30px',
                  borderRadius: '10px 0',
                  fontSize: 14,
                  color: theme.palette.secondary.lighter,
                  fontWeight: 400,
                })}
              >
                {t('Add Game')}
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
      <Dialog open={open} fullWidth maxWidth={'xs'}>
        <DialogTitle sx={(theme) => ({ color: theme.palette.primary.main, fontSize: '18px !important' })}>
          {t('Add Game')}
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="subtitle2"
            noWrap
            color={'primary'}
            sx={(theme) => ({
              fontSize: 14,
              fontWeight: 400,
              mt: '24px',
              mb: '35px',
              display: 'flex',
              justifyContent: 'center',
              color: theme.palette.primary.main,
            })}
          >
            {t('Are you sure you want to add this game?')}
          </Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Button
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.lighter,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
                width: '70px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
              onClick={handleCancelAction}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={handleAddGame}
              sx={(theme) => ({
                backgroundColor: `${themeInfo?.bgColor} !important`,
                '&:hover': {
                  backgroundColor: theme.palette.primary.lighter,
                },
                width: '120px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
            >
              {t('Add')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      {isOpen && <WatchVideo game={listGameAr?.[0]} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </Fragment>
  );
}
