import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, IconButton, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import styled from 'styled-components';
import { NotifyType } from 'types/Enum';

import NotifyListAll from './ListAllNotification';

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: NotifyType;
  value: NotifyType;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function NotificationManagement() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = React.useState<NotifyType>(NotifyType.ALL);

  const handleChange = (event: React.SyntheticEvent, newValue: NotifyType) => {
    setValue(newValue);
  };

  return (
    <RootContainer>
      <Box p={2}>
        <Typography fontSize={'18px'} lineHeight={'22px'} color={theme.palette.secondary.contrastText} fontWeight={500}>
          {t(translations.notification.notification)}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            '& .Mui-selected': {
              bgcolor: theme.palette.primary.dark,
              color: `${theme.palette.secondary.contrastText} !important`,
            },
          }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab
              sx={{
                fontSize: '14px',
                color: theme.palette.primary.darker,
              }}
              label={t(translations.notification.all)}
            />
            <Tab
              label={t(translations.notification.unread)}
              sx={{
                fontSize: '14px',
                color: theme.palette.primary.darker,
              }}
            />
          </Tabs>
          <IconButton
            sx={{
              mr: 1,
              pb: 0,
              mb: 1,
            }}
          >
            <FilterListIcon sx={{ color: theme.palette.common.black }} />
          </IconButton>
        </Box>
        <TabPanel value={value} index={NotifyType.ALL}>
          <NotifyListAll typeNotify={NotifyType.ALL} />
        </TabPanel>
        <TabPanel value={value} index={NotifyType.UNREAD}>
          <NotifyListAll typeNotify={NotifyType.UNREAD} />
        </TabPanel>
      </Box>
    </RootContainer>
  );
}
