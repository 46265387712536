import { Box, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFromLS, setToLS } from 'utils/helpers';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English (US)',
    icon: '/static/icons/ic_flag_en.svg',
    index: 0,
  },
  {
    value: 'vi',
    label: 'Vietnamese',
    icon: '/static/icons/ic_flag_vi.svg',
    index: 1,
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopoverMenu() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const handleOpen = () => {
    setOpen(true);
  };
  useMemo(() => {
    if (getFromLS('translate') !== i18n.language) {
      clickLanguage(getFromLS('translate'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (i18n.language === 'en') {
      setIndex(0);
    } else {
      setIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLan = (index: number, value: string) => {
    setIndex(index);
    setOpen(false);
    clickLanguage(value);
    window.location.reload();
    setToLS('translate', value);
  };

  function clickLanguage(lang: string) {
    i18n.changeLanguage(lang);
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleOpen}>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={(theme) => ({
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          })}
        >
          <img src={LANGS[index].icon} alt={LANGS[index].label} style={{ width: 24, borderRadius: '50%' }} />
        </IconButton>
        <Typography>{t('common.language')}</Typography>
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === LANGS[index].value}
              onClick={() => handleChangeLan(option.index, option.value)}
            >
              <Box
                component="img"
                alt={option.label}
                src={option.icon}
                sx={{ width: 24, mr: 2, borderRadius: '50%' }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
