import { FilterParams, Pageable, ParamsRequest } from 'types';
import { serialize } from 'utils/helpers';

import { DataListAllNoti, ListNotify, NotiResponse } from './../../app/components/Notification/slice/types';

import { createService } from './axios';

const baseURL = process.env.REACT_APP_API_URL;
const instance = createService(baseURL);

const fetchAllListNoti = async (params: ParamsRequest): Promise<ListNotify> => {
  const response = await instance.get(`/v1/users/notifications/get-all-in-customer-page?${serialize(params)}`);
  return response.data;
};

const fetchAllListSupportCenter = async (params: ParamsRequest): Promise<ListNotify> => {
  const response = await instance.get(`/v1/support-center?${serialize(params)}`);
  return response.data;
};

const readNotification = async (params: { notiId: string }): Promise<NotiResponse> => {
  const response = await instance.get(`/v1/users/notifications/${params.notiId}/read`);
  return response.data;
};

const readSupportCenter = async (params: { support_center_id: number, type: string }): Promise<NotiResponse> => {
  const url = `/v1/support-center/read-support-center`;
  const response = await instance.post(url, params);
  return response.data;
};

const readAllNotification = async (params: ParamsRequest): Promise<NotiResponse> => {
  const response = await instance.get(`/v1/users/notifications/read-all`);
  return response.data;
};

const readAllSupportCenter = async (params: { type: string, campaign_id: number }): Promise<NotiResponse> => {
  const newParams = { ...params };
  const response = await instance.get(`/v1/support-center/read-all-support-center?${serialize(newParams)}`);
  return response.data;
};

const getAllListNotification = async (params?: FilterParams): Promise<Pageable<DataListAllNoti>> => {
  const newParams = { ...params };
  const response = await instance.get(`/v1/users/notifications/get-all-in-customer-page?${serialize(newParams)}`);
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const getAllListSupportCenter = async (params?: FilterParams): Promise<Pageable<DataListAllNoti>> => {
  const newParams = { ...params };
  const response = await instance.get(`/v1/support-center?${serialize(newParams)}`);
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

export default {
  fetchAllListNoti,
  fetchAllListSupportCenter,
  readNotification,
  readSupportCenter,
  readAllNotification,
  readAllSupportCenter,
  getAllListNotification,
  getAllListSupportCenter
};
