import { Icon } from '@iconify/react';
import React, { useState } from 'react';

import { matchPath, NavLink as RouterLink, useLocation, useParams } from 'react-router-dom';
// material
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Popover, Tooltip } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ISidebarMenu } from 'types';
import { PermissionsType } from 'types/Enum';
import AuthorizationRouteCampaign from '../Permission/PermissionCampaign';
import { MHidden } from '../@material-extend';
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  display: 'flex',
  justifyContent: 'center',
  // color: theme.palette.grey[100_7],
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
})) as any;

// ----------------------------------------------------------------------

interface Props {
  item: ISidebarMenu;
  active: (path?: string) => boolean;
  selectedTheme?: any;
  isOpenSidebar: boolean;
}

function NavItem(props: Props) {
  const { item, active, selectedTheme, isOpenSidebar } = props;
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, children, iconActive } = item;
  const [open, setOpen] = useState<boolean>(isActiveRoot);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openCollapse = Boolean(anchorEl);
  const id = openCollapse ? 'simple-popover' : undefined;
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: isOpenSidebar ? 0 : '16px',
  });

  const activeRootStyle = {
    '& img': {},
    color: selectedTheme?.textColorActive,
    fontWeight: '200',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  };

  const activeSubStyle = {
    color: selectedTheme?.textColorActive,
    fontWeight: 'fontWeightMedium',
  };

  const activeSubStyleCollapse = {
    color: selectedTheme?.bgColor,
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          // onClick={handleOpen}
          onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            handleOpen();
            handleClick(event);
          }}
          sx={{
            color: selectedTheme?.textColor,
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          {icon && (
            <Tooltip title={item.title}>
              <ListItemIconStyle aria-describedby={id} onClick={handleClick}>
                {icon}
              </ListItemIconStyle>
            </Tooltip>
          )}

          {isOpenSidebar && (
            <Popover
              id={id}
              open={openCollapse}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {children.map((child) => {
                const isActiveSub = active(child.path);

                return (
                  <ListItemStyle
                    key={child.title}
                    component={RouterLink}
                    to={child.path}
                    sx={{
                      color: selectedTheme?.textColor,
                      ...(isActiveSub && activeSubStyleCollapse),
                    }}
                  >
                    <ListItemText disableTypography primary={t(child.title)} />
                  </ListItemStyle>
                );
              })}
            </Popover>
          )}
          {isOpenSidebar ? (
            <MHidden width="lgUp">
              <ListItemText sx={{ ml: 2 }} disableTypography primary={t(title)} />
            </MHidden>
          ) : (
            <ListItemText disableTypography primary={t(title)} />
          )}
          {isOpenSidebar ? (
            ''
          ) : (
            <Box
              component={Icon}
              icon={open ? 'mdi:chevron-down' : 'mdi:chevron-right'}
              sx={{ width: 31, height: 31, ml: 1 }}
            />
          )}
        </ListItemStyle>

        {isOpenSidebar ? (
          ''
        ) : (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((child) => {
                const isActiveSub = active(child.path);

                return (
                  <ListItemStyle
                    key={child.title}
                    component={RouterLink}
                    to={child.path}
                    sx={{
                      color: selectedTheme?.textColor,
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle></ListItemIconStyle>
                    {isOpenSidebar ? '' : <ListItemText disableTypography primary={t(child.title)} />}
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      isOpenSidebar={isOpenSidebar}
      component={RouterLink}
      to={path}
      sx={{
        color: selectedTheme?.textColor,
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      {isActiveRoot ? (
        <ListItemIconStyle>{iconActive && iconActive}</ListItemIconStyle>
      ) : (
        <Tooltip title={item.title}>
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        </Tooltip>
      )}
      {isOpenSidebar ? (
        <MHidden width="lgUp">
          <ListItemText sx={{ ml: 2 }} disableTypography primary={t(title)} />
        </MHidden>
      ) : (
        <ListItemText disableTypography primary={t(title)} />
      )}
    </ListItemStyle>
  );
}

interface NavSectionProps {
  navConfig: ISidebarMenu[];
  selectedTheme?: any;
  [prop: string]: any;
  isOpenSidebar: boolean;
}

export default function NavSection(props: NavSectionProps) {
  const { navConfig, selectedTheme, isOpenSidebar, ...other } = props;
  const params = useParams();
  const { id } = params;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const match = (path?: string) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  const renderPermissions = (label: string) => {
    if (label === t('dashboard')) {
      return [PermissionsType.papa_dashboard_read];
    }
    if (label === t('Staff')) {
      return [
        PermissionsType.papa_staff_invite,
        PermissionsType.papa_staff_read,
        PermissionsType.papa_staff_update,
        PermissionsType.papa_staff_delete,
        PermissionsType.papa_staff_list,
      ];
    }
    if (label === t('Game')) {
      return [
        PermissionsType.papa_game_read,
        PermissionsType.papa_game_update,
        PermissionsType.papa_game_create,
        PermissionsType.papa_game_delete,
        PermissionsType.papa_game_list,
        PermissionsType.papa_game_status_update,
        PermissionsType.papa_game_member_update,
      ];
    }
    if (label === t('Leaderboard')) {
      return [PermissionsType.papa_dashboard_read];
    }
    if (label === t('Player')) {
      return [PermissionsType.papa_player_update, PermissionsType.papa_player_delete, PermissionsType.papa_player_read];
    }
    if (label === t('Reward')) {
      return [
        PermissionsType.papa_reward_list,
        PermissionsType.papa_reward_create,
        PermissionsType.papa_reward_update,
        PermissionsType.papa_reward_delete,
        PermissionsType.papa_reward_read,
      ];
    }
    if (label === t('Payment')) {
      return [PermissionsType.papa_billing_read];
    }
  };

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <AuthorizationRouteCampaign
            key={item.title}
            campaignId={Number(id)}
            allowedPermissions={renderPermissions(item.title)}
          >
            <NavItem
              key={item.title}
              item={item}
              active={match}
              isOpenSidebar={isOpenSidebar}
              selectedTheme={selectedTheme}
            />
          </AuthorizationRouteCampaign>
        ))}
      </List>
    </Box>
  );
}
