import { Box, FormHelperText, styled, TextField, Typography } from '@mui/material';
import { Title } from 'app/components/Title';
import UploadFile from 'app/components/UploadFile';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FILE_ERROR_MESSAGES, MAX_FILE_SIZE } from 'utils/config';

export const RootStyle = styled('div')({
  '& .MuiFilledInput-root input': {
    background: '#F6F8FC',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root:after': {
    right: 'unset',
    border: '1px solid  #005FC5',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root.Mui-error:after': {
    border: '1px solid  #FF4842',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root:before': {
    right: 'unset',
    content: '""',
  },
  '& .MuiFormControl-root:after': {
    border: '1px solid  #005FC5',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
});

const RequestPromotionForm = () => {
  const { t } = useTranslation();
  const {
    clearErrors,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();
  const handleFilesCallback = async (file: string, field: any, multiple?: boolean) => {
    clearErrors(field.name);
    field.onChange(file);
  };

  const removeFile = (fileIndex?: number, field?: any) => {
    setValue(field.name, '');
    setError(field.name, {
      message: t('isRequired'),
    });
  };

  const validateFiles = (file: File, field: any) => {
    let message = '';

    if (!file?.type.includes('image')) {
      const fileName = file?.name;
      message = FILE_ERROR_MESSAGES.invalid(fileName);
      setError(field.name, {
        message,
      });
      return message;
    }

    if (file?.size > MAX_FILE_SIZE) {
      message = FILE_ERROR_MESSAGES.oversize;
      setError(field.name, {
        message,
      });
      return message;
    }
  };

  return (
    <RootStyle>
      <Box display="flex" mt={2}>
        <Controller
          control={control}
          name="campaign_url"
          render={({ field }) => {
            return (
              <TextField
                sx={{ marginBottom: '30px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                label={
                  <Typography
                    variant="subtitle2"
                    noWrap
                    color={'primary'}
                    sx={{
                      fontSize: 15,
                      fontWeight: 400,
                      mt: '0px',
                      mb: '10px',
                      display: 'flex',
                      marginBottom: '30px',
                    }}
                  >
                    {t('campaignUrl')}
                    <Typography
                      sx={(theme) => ({
                        fontSize: 15,
                        fontWeight: 400,
                        color: theme.palette.info.darker,
                        margin: '1px 3px 0px 4px',
                      })}
                    >
                      *
                    </Typography>
                  </Typography>
                }
                {...field}
                placeholder={`${t('reward.Enter')} ${t('campaignUrl')}`}
                id="campaignUrl"
                size="small"
                fullWidth
                variant="filled"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors('campaign_url');
                }}
                helperText={errors?.campaign_url?.message}
                onBlur={(e: any) => {
                  field.onChange(e.target.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '));
                }}
                error={!!errors?.campaign_url}
                inputProps={{
                  style: {},
                }}
                InputProps={{
                  style: {
                    marginBottom: '12px',
                  },
                }}
              />
            );
          }}
        />
      </Box>
      <Box display="flex">
        <Title title={t('banner')} />
      </Box>
      <Controller
        name="banner_campaign"
        control={control}
        render={({ field }) => {
          return (
            <>
              <UploadFile
                handleFilesCallback={handleFilesCallback}
                field={field}
                fileProp={field.value}
                removeFile={removeFile}
                validateFiles={validateFiles}
                error={errors?.banner_campaign?.message as string}
                categoryImage={'avatar'}
                width="120"
                height="80"
                setError={setError}
                isBanner
              />
              <FormHelperText
                sx={(theme) => ({
                  ml: '20px',
                  mt: '10px',
                })}
              >
                {t('recommendFileBanner')}
              </FormHelperText>
            </>
          );
        }}
      />
    </RootStyle>
  );
};

export default React.memo(RequestPromotionForm);
