import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import ReactPlayer from 'react-player';
import { MainGameTypes } from '../../../slice/types';

export interface Props {
  game: MainGameTypes;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function WatchVideo({ game, isOpen, setIsOpen }: Props) {
  const onCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth={'lg'} sx={{ justifyContent: 'center' }}>
      {game?.video_instruction ? (
        <DialogContent sx={{ p: '0px' }}>
          <ReactPlayer url={game?.video_instruction} width="100%" height="534px" controls={true} />
        </DialogContent>
      ) : (
        <CircularProgress />
      )}
    </Dialog>
  );
}
