import { Box, FormHelperText, Typography } from '@mui/material';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FileService } from 'services';
import { FileTypeUpload, MultiFileTypeUpload } from 'types';
import { CategoryImage } from 'types/Enum';

interface Props {
  setValueAvt: (valueAvt: string) => void;
  valueAvt: string;
  handleAvatar: (value: boolean) => void;
  errors: any;
  isMultiple: boolean;
}

export const UploadAvatar = ({ setValueAvt, valueAvt, handleAvatar, errors, isMultiple }: Props) => {
  const { t } = useTranslation();
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();
  const [errUploadAvt] = useState(false);

  const handleUploadAvatar = async (event: any) => {
    const { files } = event.target;
    if (files?.length && files[0].size < 3000000) {
      const paramsUploadAvatar: FileTypeUpload = {
        category_image: CategoryImage.avatar,
        image: files[0],
      };
      const response = await FileService.uploadFile(paramsUploadAvatar);
      setValueAvt(response?.data?.data?.imageUrl);
      handleAvatar(true);
    } else {
      handleAvatar(false);
    }
    event.target.value = '';
  };

  const handleUploadMultiFile = async (event: any) => {
    const { files } = event.target;
    if (files.length > 0) {
      const arrImage = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size < 10240000) {
          arrImage.push({ title: `image[${i}]`, file });
        } else {
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('common.fileSizeLessThan10Mb')}`,
              type: 'error',
            })
          );
          handleAvatar(false);
        }
      }
      const paramsUploadAvatar: MultiFileTypeUpload = {
        category_image: CategoryImage.feedback_image,
        images: arrImage,
      };
      const response = await FileService.uploadFileMulti(paramsUploadAvatar);
      setValueAvt(response?.data?.data?.imageUrl);
      handleAvatar(true);
    }
    event.target.value = '';
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.lighter,
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          border: `1px solid ${theme.palette.primary.main}`,
          width: { md: '170px', xs: '122px' },
          display: 'flex',
          height: { md: '36px', xs: '24px' },
          borderRadius: '10px 0',
          mt: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        })}
        component="label"
      >
        {isMultiple ? (
          <input hidden type="file" multiple onChange={handleUploadMultiFile} accept={'image/*'} />
        ) : (
          <input hidden type="file" onChange={handleUploadAvatar} accept={'image/*'} />
        )}
        <img
          src="/static/icons/Icon-upload-photo.png"
          alt="btn-upload-phote"
          width={20}
          style={{ marginRight: '10px' }}
        />
        <Typography sx={(theme) => ({ fontSize: '12px' })}>{t('Upload photo')}</Typography>
      </Box>
      <FormHelperText
        sx={(theme) => ({
          ml: '0px',
          mt: '10px',
          marginBottom: '10px',
          color: !errUploadAvt ? theme.palette.success.lighter : theme.palette.error.main,
          marginLeft: { md: '12px', xs: '14px' },
        })}
      >
        {t('Allowed file')}
      </FormHelperText>
      <FormHelperText
        sx={(theme) => ({
          mt: '10px',
          marginBottom: '30px',
          color: theme.palette.error.main,
        })}
      >
        {errors?.avatar?.message}
      </FormHelperText>
    </>
  );
};
