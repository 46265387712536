import { Grid } from '@mui/material';
import { DataMainGameProps, MainGameTypes } from '../../slice/types';
import GameBaseElement from './GameBaseElement';

export interface Props {
  gameList?: DataMainGameProps;
}

export default function GameBaseList({ gameList }: Props) {
  const listGameBase = gameList as MainGameTypes[] | undefined;

  return (
    <Grid container spacing={3}>
      {listGameBase &&
        listGameBase.map((game: MainGameTypes) => (
          <Grid key={game.id} item xs={12} sm={6} md={6} xl={4} lg={4}>
            <GameBaseElement game={game} />
          </Grid>
        ))}
    </Grid>
  );
}
