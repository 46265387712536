import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { withLoading } from 'app/components/HOC/WithLoading';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import React, { memo } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RequestPromotionData } from 'types/RequestPromotion';
import * as yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import SEND_ICON from 'assets/table/send.svg';
import { RequestPromotionService } from 'services';
import RequestPromotionForm from './Components/RequestPromotionForm';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const RequestPromotion = withLoading(
  memo((props: Props) => {
    const { open, onClose } = props;
    const { t } = useTranslation();
    const { actions: snackbarActions } = useSnackbarSlice();
    const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const params = useParams();
    const { id } = params;
    const { themeInfo } = useSelector(layoutsSelector);
    const formSchema = yup.object().shape({
      campaign_url: yup
        .string()
        .required(t('validated.pleaseEnterCampignUrl'))
        .min(5, t('validated.leastCharacters'))
        // .max(50, t('validated.mustMore50Characters'))
        .nullable(),
      banner_campaign: yup.string().required(t('validated.pleaseSelectImage')).nullable(),
    });

    const methods = useForm<any>({
      resolver: yupResolver(formSchema),
      defaultValues: {
        campaign_url: '',
        banner_campaign: '',
      },
      mode: 'all',
    });
    const {
      reset,
      formState: { isDirty, isValid },
    } = methods;

    const onSubmit = async (data: RequestPromotionData) => {
      const request = { ...data, campaign_id: id };
      setLoading(true);
      const response = await RequestPromotionService.requestPromotion(request);
      setLoading(false);
      if (response.status) {
        dispatch(
          snackbarActions.updateSnackbar({
            message: t('Your request was sent'),
            type: 'success',
          })
        );
        onClose();
      } else {
        if (response.message === 'The campaign url must be a valid URL.') {
          dispatch(
            snackbarActions.updateSnackbar({
              message: t('The campaign url must be a valid URL'),
              type: 'error',
            })
          );
        } else {
          dispatch(
            snackbarActions.updateSnackbar({
              message: t("Your request wasn't sent"),
              type: 'error',
            })
          );
          onClose();
        }
      }
    };

    return (
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={() => {
            onClose && onClose();
            methods.reset({
              campaign_url: '',
              banner_campaign: '',
            });
            methods.clearErrors();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '18px',
            }}
          >
            <Box>
              <Typography sx={{ textAlign: 'left' }}>{t('requestPromotion')}</Typography>
              <Typography
                sx={(theme) => ({
                  textAlign: 'left',
                  fontSize: '12px',
                  fontFamily: 'Lexend',
                  fontWeight: '400',
                  color: theme.palette.success.lighter,
                })}
              >
                {t('We support to promote your campaign on Papahub website')}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                onClose();
                methods.reset({
                  campaign_url: '',
                  banner_campaign: '',
                });
              }}
              sx={(theme) => ({
                color: `${themeInfo?.bgColor}`,
              })}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogTitle sx={{ padding: '0 24px' }}></DialogTitle>
          <DialogContent>
            <RequestPromotionForm />
            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: 2,
              }}
            >
              <Button
                onClick={() => {
                  onClose();
                  reset();
                }}
                variant="outlined"
                sx={(theme) => ({
                  fontSize: '14px',
                  backgroundColor: theme.palette.secondary.lighter,
                  color: theme.palette.primary.main,
                  borderRadius: '10px 0',
                  padding: '6px 16px',
                  border: 0,
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.lighter,
                  },
                })}
              >
                {t('common.cancel')}
              </Button>
              <LoadingButton
                startIcon={<img src={SEND_ICON} alt="send icon" />}
                loading={loading}
                onClick={methods.handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
                disabled={!(isDirty && isValid)}
                variant="contained"
                sx={(theme) => ({
                  background: '#FFCC04',
                  padding: '6px 16px',
                  fontSize: '14px',
                  backgroundColor: theme.palette.info.dark,
                  borderRadius: '10px 0',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                  },
                })}
              >
                {t('sendRequest')}
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </FormProvider>
    );
  })
);
