import { useProfile } from 'app/hooks';
import React from 'react';
import { PermissionsType } from 'types/Enum';

export interface Props {
  allowedPermissions?: PermissionsType[];
  children: React.ReactNode;
  campaignId?: number;
}

export default function AuthorizationRouteCampaign(props: Props) {
  const { allowedPermissions, children, campaignId } = props;
  const user = useProfile();
  const campaignPermissions = user?.campaigns_roles?.find((item) => item.campaign_id === campaignId)?.permissions || [];
  const hasPermission = (allowedPermissions || []).some((permission) => campaignPermissions.includes(permission));
  if (hasPermission) return <>{children}</>;
  return null;
}
