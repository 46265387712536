import { Fragment, memo, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { usePaymentSlice } from 'app/pages/PaymentManagement/slice';
import { useParams } from 'react-router-dom';
import { selectPaymentManagement } from 'app/pages/PaymentManagement/slice/selectors';

const RemainPlayTurnMenu = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { actions } = usePaymentSlice();
  const { dataPlayTurnUsed } = useSelector(selectPaymentManagement);

  const [percentPlayTurnUsed, setPercentPlayTurnUsed] = useState<number>(0);

  useEffect(() => {
    if (id) {
      dispatch(actions.getPlayTurnUsed({ campaign_id: id }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (dataPlayTurnUsed) {
      const purchased = dataPlayTurnUsed?.data.total_play_turns_purchased;
      const remaining = dataPlayTurnUsed?.data.total_play_turns_remaining;
      const percentUsed = (remaining / purchased) * 100;
      setPercentPlayTurnUsed(percentUsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPlayTurnUsed]);

  return (
    <Fragment>
      {dataPlayTurnUsed ? (
        <Box
          sx={{
            display: 'inline-flex',
            justifyContent: 'space-around',
            padding: '0 10px',
            backgroundColor:
              percentPlayTurnUsed <= 20 || dataPlayTurnUsed.data.total_play_turns_remaining === 0
                ? theme.palette.error.contrastText
                : theme.palette.info.darker,
            borderRadius: 20,
          }}
        >
          <Typography sx={{ display: 'flex', width: 'auto' }}>
            <Typography fontSize={'12px'}>{t('common.remainPlayTurn')}:</Typography>
            <Typography sx={{ paddingLeft: '4px', fontSize: '12px' }}>
              {dataPlayTurnUsed?.data.total_play_turns_remaining}
            </Typography>
          </Typography>
        </Box>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default memo(RemainPlayTurnMenu);
