import { Box } from '@mui/material';
import React, { memo } from 'react';

export const BackgroundImage = memo(() => {
  return (
    <Box
      sx={{
        width: { md: '45%', xs: '100%' },
        display: { xs: 'none', md: 'flex' },
        position: { xs: 'absolute', md: 'inherit' },
        height: { xs: '100%' },
      }}
    >
      <img src="/static/illustrations/bannerSignIn.png" alt="" width={'100%'} height={'100%'} />
    </Box>
  );
});
