import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Drawer, Grid, List, ListItemButton, ListItemText, Tooltip, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { selectCampaign } from 'app/pages/HomePage/slice/selectors';
import path from 'app/routes/path';
import EXCLUDE_ICON from 'assets/table/exclude.svg';
import UPGRADE_ICON from 'assets/table/upgrade.svg';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { LocalStorageService } from 'services';
import { PackageName, PackageTypePayment } from 'types/Enum';
import Scrollbar from '../../components/Scrollbar';
import { MHidden } from '../@material-extend';
import { Logo } from '../Logo';
import { LogoCollapse } from '../LogoCollapse';
import NavSection from '../NavSection';
import { RequestPromotion } from './SidebarMenu/Components/RequestPromotion';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 100;

interface Props {
  isOpenSidebar: boolean;
  onCloseSidebar?: () => void;
  selectedTheme?: any;
  inOpenSidebar?: () => void;
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, selectedTheme, inOpenSidebar }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();
  const { t } = useTranslation();
  const { id } = params;
  const getIconSideBar = (name: string | undefined) => <img src={name} alt={name} width={22} height={22} />;
  const { pathname } = useLocation();
  const fetchFormData = useSelector(selectCampaign);
  const { listCampaign } = fetchFormData;
  const [openDialog, setOpenDialog] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState<boolean>(false);
  const [openUpgradeSappssire, setOpenUpgradeSappssire] = useState<boolean>(false);
  const [checkShowPopup, setCheckShowPopup] = useState<boolean>(false);

  const versionMeta = process.env.REACT_APP_VERSION_META;

  const handleChangeCheckboxShow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckShowPopup(event.target.checked);
  };

  const handleDontShow = () => {
    LocalStorageService.set(LocalStorageService.DONT_SHOW_POPUP_UPGRADE_PAYMENT, checkShowPopup);
  };

  const handleOpenExtend = () => {
    setOpenUpgrade(true);
  };

  useEffect(() => {
    if (listCampaign) {
      const purchased = listCampaign?.package.package_package_type;
      const remaining = listCampaign?.package.package_package_type;
      if (purchased === PackageTypePayment.EMERALD) {
        setOpenUpgrade(true);
      } else if (remaining === PackageTypePayment.SAPPHIRE) {
        setOpenUpgradeSappssire(true);
      } else {
        setOpenUpgrade(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCampaign]);

  const formSchema = yup.object().shape({
    reward_name: yup
      .string()
      .required(t('validated.pleaseEnterCampignUrl'))
      .min(5, t('validated.leastCharacters'))
      .max(50, t('validated.mustMore50Characters'))
      .nullable(),
  });

  const methods = useForm<any>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      reward_name: '',
    },
    mode: 'all',
  });
  const {
    formState: { isDirty, isValid },
  } = methods;

  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: isOpenSidebar ? COLLAPSE_WIDTH : DRAWER_WIDTH,
    },
  }));

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    // if (isOpenSidebar) {
    //   onCloseSidebar && onCloseSidebar();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const sidebarConfig = [
    {
      title: t('dashboard'),
      path: `${path.campaign}/${id}/dashboard`,
      icon: getIconSideBar('/static/icons/Icon-dashboard-custumer.svg'),
      iconActive: getIconSideBar('/static/icons/Icon-dashboard-custumer_active.svg'),
    },
    {
      title: t('Staff'),
      path: `${path.campaign}/${id}/staffs`,
      icon: getIconSideBar('/static/icons/Icon-staff-custumer.svg'),
      iconActive: getIconSideBar('/static/icons/Icon-staff-custumer_active.svg'),
    },
    {
      title: t('Game'),
      path: `${path.campaign}/${id}/list-game`,
      icon: getIconSideBar('/static/icons/Icon-game-custumer.svg'),
      iconActive: getIconSideBar('/static/icons/Icon-game-custumer_active.svg'),
    },
    {
      title: t('Leaderboard'),
      path: `${path.campaign}/${id}/leaderBoard`,
      icon: getIconSideBar('/static/icons/Icon-Leaderboard-custumer.svg'),
      iconActive: getIconSideBar('/static/icons/Icon-Leaderboard-custumer_activate.svg'),
    },
    {
      title: t('Player'),
      path: `${path.campaign}/${id}/players`,
      icon: getIconSideBar('/static/icons/Icon-play-custumer.svg'),
      iconActive: getIconSideBar('/static/icons/Icon-play-custumer_activate.svg'),
    },
    {
      title: 'asdasdasd',
      path: `${path.campaign}/${id}/players`,
      icon: getIconSideBar('/static/icons/Icon-player.png'),
      iconActive: getIconSideBar('/static/icons/Icon-player-active.png'),
    },
    {
      title: t('Reward'),
      path: `${path.campaign}/${id}/reward`,
      icon: getIconSideBar('/static/icons/Icon-reward-custumer.svg'),
      iconActive: getIconSideBar('/static/icons/Icon-reward-custumer-activate.svg'),
      children: [
        {
          title: t('rewardRedem.rewardStatistics'),
          path: `${path.campaign}/${id}/reward/game-reward`,
        },
        {
          title: t('rewardRedem.rewardRedemption'),
          path: `${path.campaign}/${id}/reward/redemption`,
        },
      ],
    },
    {
      title: t('Payment'),
      path: `${path.campaign}/${id}/payment`,
      icon: getIconSideBar('/static/icons/Icon-reward.png'),
      iconActive: getIconSideBar('/static/icons/Icon-reward-active.png'),
      children: [
        {
          title: t('payment.invoice'),
          path: `${path.campaign}/${id}/payment/invoice`,
        },
        {
          title: t('payment.servicePack'),
          path: `${path.campaign}/${id}/payment/service-pack`,
        },
      ],
    },
  ];

  // const handleOpenService = () => {
  //   setOpenService(true);
  // };

  const renderMenu = () => {
    switch (listCampaign?.package?.package_package_type) {
      case PackageTypePayment.EMERALD: {
        return (
          <>
            <List
              sx={(theme) => ({
                width: '100%',
                borderTop: `1px solid ${theme.palette.grey[100]}`,
                marginTop: '16px',
              })}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <Typography sx={{ ml: '20px', color: theme.palette.common.white, fontSize: '14px' }}>
                {PackageName.EMERALD}
              </Typography>
              <Tooltip title={isOpenSidebar ? `${t('payment.upgrade')}` : ''}>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    justifyContent: `${!isOpenSidebar ? 'none' : 'center'}`,
                  }}
                  onClick={() => navigate(`${path.campaign}/${id}/payment/service-pack`)}
                >
                  <img src={EXCLUDE_ICON} alt="exclude icon" />
                  {!isOpenSidebar ? (
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey[100],
                        fontFamily: 'Lexend',
                        fontSize: '12px',
                        marginLeft: '16px',
                      })}
                    >
                      {t('payment.upgrade')}
                    </Typography>
                  ) : (
                    <MHidden width="lgUp">
                      <ListItemText
                        sx={(theme) => ({
                          color: theme.palette.grey[100],
                          fontFamily: 'Lexend',
                          fontSize: '12px',
                          marginLeft: '16px',
                        })}
                        disableTypography
                        primary={t('payment.upgrade')}
                      />
                    </MHidden>
                  )}
                </ListItemButton>
              </Tooltip>
            </List>
          </>
        );
      }
      case PackageTypePayment.SAPPHIRE: {
        return (
          <>
            <List
              sx={(theme) => ({
                width: '100%',
                borderTop: `1px solid ${theme.palette.grey[100]}`,
                marginTop: '16px',
              })}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: !isOpenSidebar ? 'unset' : 'center',
                  alignContent: 'center',
                }}
              >
                <Typography
                  sx={{ ml: !isOpenSidebar ? '22px' : 0, color: theme.palette.common.white, fontSize: '12px' }}
                >
                  {PackageName.SAPPHIRE}
                </Typography>
                <Typography
                  sx={{
                    display: !isOpenSidebar ? 'block' : 'none',
                    ml: 1,
                    color: theme.palette.success.lighter,
                    fontSize: '12px',
                  }}
                >
                  (EXP: {moment.utc(listCampaign?.package?.expired_date).local().format('DD/MM/YYYY').toString()})
                </Typography>
              </Box>
              <Tooltip title={isOpenSidebar ? `${t('payment.upgrade')}` : ''}>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    justifyContent: `${!isOpenSidebar ? 'none' : 'center'}`,
                  }}
                  onClick={() => navigate(`${path.campaign}/${id}/payment/extend-package`)}
                >
                  <img src={UPGRADE_ICON} alt="upgrade icon" />
                  {!isOpenSidebar ? (
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey[100],
                        fontFamily: 'Lexend',
                        fontSize: '12px',
                        marginLeft: '16px',
                      })}
                    >
                      {t('payment.extend')}
                    </Typography>
                  ) : (
                    <MHidden width="lgUp">
                      <ListItemText
                        sx={(theme) => ({
                          color: theme.palette.grey[100],
                          fontFamily: 'Lexend',
                          fontSize: '12px',
                          marginLeft: '16px',
                        })}
                        disableTypography
                        primary={t('payment.extend')}
                      />
                    </MHidden>
                  )}
                </ListItemButton>
              </Tooltip>
              <Tooltip title={isOpenSidebar ? `${t('requestPromotion')}` : ''}>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    justifyContent: `${!isOpenSidebar ? 'none' : 'center'}`,
                  }}
                  onClick={handleOpenDialog}
                >
                  <img src={EXCLUDE_ICON} alt="exclude icon" />
                  {!isOpenSidebar ? (
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey[100],
                        fontFamily: 'Lexend',
                        fontSize: '12px',
                        marginLeft: '16px',
                      })}
                    >
                      {t('requestPromotion')}
                    </Typography>
                  ) : (
                    <MHidden width="lgUp">
                      <ListItemText
                        sx={(theme) => ({
                          color: theme.palette.grey[100],
                          fontFamily: 'Lexend',
                          fontSize: '12px',
                          marginLeft: '16px',
                        })}
                        disableTypography
                        primary={t('requestPromotion')}
                      />
                    </MHidden>
                  )}
                </ListItemButton>
              </Tooltip>
            </List>
            <RequestPromotion open={openDialog} onClose={handleCloseDialog} />
          </>
        );
      }
      case PackageTypePayment.RUBY: {
        return (
          <>
            <List
              sx={(theme) => ({
                width: '100%',
                borderTop: `1px solid ${theme.palette.grey[100]}`,
                marginTop: '16px',
              })}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: !isOpenSidebar ? 'unset' : 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ ml: !isOpenSidebar ? '20px' : 0, color: theme.palette.common.white, fontSize: '14px' }}
                >
                  {PackageName.RUBY}
                </Typography>
                <Typography
                  sx={{
                    display: !isOpenSidebar ? 'block' : 'none',
                    ml: 1,
                    color: theme.palette.success.lighter,
                    fontSize: '12px',
                  }}
                >
                  (EXP: {moment.utc(listCampaign?.package?.expired_date).local().format('DD/MM/YYYY').toString()})
                </Typography>
              </Box>
              <Tooltip title={isOpenSidebar ? `${t('payment.upgrade')}` : ''}>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    justifyContent: `${!isOpenSidebar ? 'none' : 'center'}`,
                  }}
                  onClick={() => navigate(`${path.campaign}/${id}/payment/extend-package`)}
                >
                  <img src={UPGRADE_ICON} alt="upgrade icon" />
                  {!isOpenSidebar ? (
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey[100],
                        fontFamily: 'Lexend',
                        fontSize: '12px',
                        marginLeft: '16px',
                      })}
                    >
                      {t('payment.extend')}
                    </Typography>
                  ) : (
                    <MHidden width="lgUp">
                      <ListItemText
                        sx={(theme) => ({
                          color: theme.palette.grey[100],
                          fontFamily: 'Lexend',
                          fontSize: '12px',
                          marginLeft: '16px',
                        })}
                        disableTypography
                        primary={t('payment.extend')}
                      />
                    </MHidden>
                  )}
                </ListItemButton>
              </Tooltip>
              <Tooltip title={isOpenSidebar ? `${t('requestPromotion')}` : ''}>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    justifyContent: `${!isOpenSidebar ? 'none' : 'center'}`,
                  }}
                  onClick={handleOpenDialog}
                >
                  <img src={EXCLUDE_ICON} alt="exclude icon" />
                  {!isOpenSidebar ? (
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey[100],
                        fontFamily: 'Lexend',
                        fontSize: '12px',
                        marginLeft: '16px',
                      })}
                    >
                      {t('requestPromotion')}
                    </Typography>
                  ) : (
                    <MHidden width="lgUp">
                      <ListItemText
                        sx={(theme) => ({
                          color: theme.palette.grey[100],
                          fontFamily: 'Lexend',
                          fontSize: '12px',
                          marginLeft: '16px',
                        })}
                        disableTypography
                        primary={t('requestPromotion')}
                      />
                    </MHidden>
                  )}
                </ListItemButton>
              </Tooltip>
            </List>
            <RequestPromotion open={openDialog} onClose={handleCloseDialog} />
          </>
        );
      }
      case PackageTypePayment.DIAMOND: {
        return (
          <>
            <List
              sx={(theme) => ({
                width: '100%',
                borderTop: `1px solid ${theme.palette.grey[100]}`,
                marginTop: '16px',
                justifyContent: 'center',
              })}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ ml: '20px', mr: 1, color: theme.palette.common.white, fontSize: '14px' }}>
                  {PackageName.DIAMOND}
                </Typography>
                <Typography sx={{ color: theme.palette.success.lighter, fontSize: '12px' }}>
                  (EXP: {moment.utc(listCampaign?.package?.expired_date).local().format('DD/MM/YYYY').toString()})
                </Typography>
              </Box>
              <Tooltip title={isOpenSidebar ? `${t('payment.upgrade')}` : ''}>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    justifyContent: `${!isOpenSidebar ? 'none' : 'center'}`,
                  }}
                >
                  <img src={EXCLUDE_ICON} alt="exclude icon" />
                  {!isOpenSidebar ? (
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey[100],
                        fontFamily: 'Lexend',
                        fontSize: '12px',
                        marginLeft: '16px',
                      })}
                    >
                      {t('payment.upgrade')}
                    </Typography>
                  ) : (
                    <MHidden width="lgUp">
                      <ListItemText
                        sx={(theme) => ({
                          color: theme.palette.grey[100],
                          fontFamily: 'Lexend',
                          fontSize: '12px',
                          marginLeft: '16px',
                        })}
                        disableTypography
                        primary={t('payment.upgrade')}
                      />
                    </MHidden>
                  )}
                </ListItemButton>
              </Tooltip>
              <Tooltip title={isOpenSidebar ? `${t('requestPromotion')}` : ''}>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    padding: '12px 20px',
                    justifyContent: `${!isOpenSidebar ? 'none' : 'center'}`,
                  }}
                >
                  <img src={UPGRADE_ICON} alt="exclude icon" />
                  {!isOpenSidebar ? (
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.grey[100],
                        fontFamily: 'Lexend',
                        fontSize: '12px',
                        marginLeft: '16px',
                      })}
                    >
                      {t('requestPromotion')}
                    </Typography>
                  ) : (
                    <MHidden width="lgUp">
                      <ListItemText
                        sx={(theme) => ({
                          color: theme.palette.grey[100],
                          fontFamily: 'Lexend',
                          fontSize: '12px',
                          marginLeft: '16px',
                        })}
                        disableTypography
                        primary={t('requestPromotion')}
                      />
                    </MHidden>
                  )}
                </ListItemButton>
              </Tooltip>
            </List>
            <RequestPromotion open={openDialog} onClose={handleCloseDialog} />
          </>
        );
      }
      default:
        return false;
    }
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Grid>
        <Box sx={{ px: 2.5, py: 3, textAlign: 'start' }}>
          <Box component={RouterLink} to={path.homePage} sx={{ display: 'inline-flex', textDecoration: 'none' }}>
            {isOpenSidebar ? <LogoCollapse /> : <Logo />}
          </Box>
        </Box>
        <NavSection isOpenSidebar={isOpenSidebar} navConfig={sidebarConfig} selectedTheme={selectedTheme} />
        <Box sx={{ flexGrow: 1 }} />
        {renderMenu()}
      </Grid>
      <MHidden width="lgDown">
        <Grid>
          {isOpenSidebar ? (
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '30px',
                cursor: 'pointer',
              }}
            >
              <ArrowBackIosIcon
                sx={(theme) => ({
                  borderColor: theme.palette.success.lighter,
                  borderRadius: '4px',
                  padding: '3px 1px 2px 7px',
                  transform: 'rotate(180deg)',
                  width: '30px',
                  height: '30px',
                  color: theme.palette.success.lighter,
                  cursor: 'pointer',
                })}
                onClick={inOpenSidebar}
              />
            </Grid>
          ) : (
            <Grid
              sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', padding: '8px 20px', my: '8px' }}
            >
              <ArrowBackIosIcon
                sx={(theme) => ({
                  color: theme.palette.success.lighter,
                  width: '25px',
                  height: '25px',
                  cursor: 'pointer',
                })}
                onClick={inOpenSidebar}
              />
            </Grid>
          )}
          {versionMeta === 'true' && !isOpenSidebar ? (
            <Box>
              <Typography sx={{ color: theme.palette.common.white, fontSize: '14px', textAlign: 'center', mb: '24px' }}>
                Version beta testing
              </Typography>
            </Box>
          ) : (
            ''
          )}
        </Grid>
      </MHidden>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: isOpenSidebar ? COLLAPSE_WIDTH : DRAWER_WIDTH,
              backgroundColor: `${selectedTheme?.bgColor} !important`,
              // borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#585858',
              // borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
