import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Grid, Typography } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbarSlice } from './slice';
import { selectSnackbar } from './slice/selectors';

const Snackbar = () => {
  const { open, message, type } = useSelector(selectSnackbar);
  const { actions } = useSnackbarSlice();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(actions.closeSnackbar());
  };
  return (
    <MuiSnackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={handleClose}
      message={
        <Grid
          sx={() => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
          })}
        >
          {type === 'success' ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
          <Typography
            alignItems="center"
            variant="subtitle2"
            sx={{
              ml: 1.25,
            }}
            color={'white'}
          >
            {message}
          </Typography>
        </Grid>
      }
      key={'top right'}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          top: '25px !important',
          margin: theme.spacing(0, 5),
        },
        '& .MuiSnackbarContent-root': {
          background: type === 'success' ? theme.palette.info.lighter : theme.palette.error.light,
          borderRadius: '8px',
          boxShadow: '0px 4px 16px rgb(0 0 0 / 8%) !important',
          padding: '6px 12px 6px 12px',
        },
        '& .css-15fgii9-MuiPaper-root-MuiSnackbarContent-root': {
          minWidth: 'fit-content !important',
        },
        top: '70px !important',
      })}
    />
  );
};

export default React.memo(Snackbar);
