import { Box, Skeleton } from '@mui/material';

interface Props {
  loadingNumber?: number;
}

export default function SkeletonNotify(props: Props) {
  const { loadingNumber } = props;
  return (
    <Box>
      {Array.from(new Array(loadingNumber)).map((skeleton, index) => {
        return (
          <Box
            key={Math.floor(Math.random() * Date.now())}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              marginBottom: 2,
            }}
          >
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="text" sx={{ width: '80%', height: 30, margin: 1 }} />
          </Box>
        );
      })}
    </Box>
  );
}
