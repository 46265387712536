import { FeedbackInfo } from 'app/components/SubmitFeedback/slice/types';
import { ChangePasswordManagementState } from 'app/pages/ChangePassword/slice/types';
import { ResponseMainGame } from 'app/pages/GameBaseManagement/GameList/slice/types';
import { DataCustomerAccountProps } from 'app/pages/HomePage/slice/types';

import { ActionStaffRequest } from 'app/pages/IndividualUsersManagement/slice/types';
import {
  CampaignInfo,
  FilterParams,
  IndividualUsers,
  inviteStaffRequest,
  Pageable,
  PlayerExportParams,
  RequestPayload,
  StaffInfo,
  UserInfo,
} from 'types';
import { CreateCampaignParam, DeleteCampaignParam, UpdateCampaignParam } from 'types/Campaign';
import {
  ChangeNameParamVersionPublic,
  GameBaseParam,
  GameParam,
  GameParamFetchGameThemesVersion,
  GameParamVersion,
  GameParamVersionDelete,
  GameParamVersionDuplicate,
  GameParamVersionPublic,
} from 'types/Game';
import { ChangePasswordParams, ProfileParams } from 'types/Profile';
import { RoleCustomer, RoleUpdateStaff } from 'types/Role';
import { serialize } from 'utils/helpers';
import { createService, downloadFileService } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const baseURL_USER = process.env.REACT_APP_API_URL;
const instance = createService(baseURL);
const instance_user = createService(baseURL_USER);

const baseUserURL = process.env.REACT_APP_API_URL;
const instanceUser = createService(baseUserURL);

const instanceGetFile = downloadFileService(baseURL_USER, 'application/json');
const fetchListCampaign = async (params?: FilterParams): Promise<Pageable<CampaignInfo>> => {
  const response = await instance.get(`/v1/products/campaigns?${serialize(params)}`);
  return response.data.data.data;
};

const fetchRole = async (): Promise<Pageable<RoleCustomer>> => {
  const response = await instance_user.get(`/v1/roles/customer`);
  return response.data.data;
};

const updateRole = async (params?: RoleUpdateStaff): Promise<Pageable<RoleCustomer>> => {
  const url = `/v1/products/campaigns/${params?.id_campaign}/change-role`;
  const response = await instance.post(url, params);
  return response.data;
};

const updateProfile = async (params?: ProfileParams): Promise<Pageable<UserInfo>> => {
  const url = `/v1/auth/update-profile`;
  const response = await instance_user.post(url, params);
  return response.data;
};

const changePassword = async (params?: ChangePasswordParams): Promise<Pageable<ChangePasswordManagementState>> => {
  const url = `/v1/users/change-password`;
  const response = await instance_user.post(url, params);
  return response.data;
};

const createCampaign = async (params?: CreateCampaignParam): Promise<Pageable<CreateCampaignParam>> => {
  const url = '/v2/products/campaigns';
  const res = await instance.post(url, params);
  return res.data;
};

const updateCampaign = async (params?: UpdateCampaignParam): Promise<Pageable<UpdateCampaignParam>> => {
  const url = `/v1/products/campaigns/${params?.id_campaign}`;
  const res = await instance.put(url, params);
  return res.data;
};

const feedback = async (params?: FeedbackInfo): Promise<Pageable<FeedbackInfo>> => {
  const url = `/v1/send-contact-for-customer`;
  const res = await instance_user.post(url, params);
  return res.data;
};

const deleteCampaign = async (params?: DeleteCampaignParam): Promise<any> => {
  const url = `/v1/products/campaigns/${params?.id_campaign}`;
  const res = await instance.delete(url);
  return res.data;
};

const fetchCampaign = async (params?: DeleteCampaignParam): Promise<any> => {
  const url = `/v1/products/campaigns/${params?.id_campaign}`;
  const res = await instance.get(url);
  return res.data.data;
};

const fetchIndividualUserData = async (params?: FilterParams): Promise<Pageable<StaffInfo>> => {
  const newParams = { ...params };
  delete newParams.campaignId;
  const response = await instance.get(
    `/v1/products/campaigns/${params?.campaignId}/get-staffs?${serialize(newParams)}`
  );
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const fetchPersonalInformation = async (params?: string): Promise<IndividualUsers> => {
  const response = await instance.get(`/v1/profile/personal/${params}`);
  return response.data;
};

const fetchRoles = async (): Promise<Pageable<any>> => {
  const response = await instanceUser.get(`/v1/roles/customer`);
  return response.data.data;
};

const inviteStaffInformation = async (params: inviteStaffRequest) => {
  const newParams = { ...params };
  delete newParams.campaignId;
  await instance.post(`/v1/products/campaigns/${params.campaignId}/invite`, newParams);
};

const statusStaffOutCampaign = async (param: ActionStaffRequest) => {
  const newParams = { ...param };
  delete newParams.campaignId;
  delete newParams.type;
  await instance.post(`/v1/products/campaigns/${param.campaignId}/${param.type}`, newParams);
};

const fetchMainGameData = async (params: GameParam): Promise<ResponseMainGame> => {
  const newParams = { ...params };
  delete newParams.id_campaign;
  const response = await instance.get(
    `/v1/products/campaigns/${params.id_campaign}/game-bases?${serialize(newParams)}`
  );
  return response.data.data.data;
};
const fetchMainGameVersion = async (params: GameParamVersion): Promise<ResponseMainGame> => {
  const response = await instance.get(`/v1/products/game-bases/${params.gameIdVersion}/game-versions`);
  return response.data.data;
}; //2

const deleteGameVersion = async (params: GameParamVersionDelete): Promise<ResponseMainGame> => {
  const response = await instance.delete(
    `/v1/products/game-bases/${params.gameId}/game-versions/${params.gameVersion_id}`
  );
  return response.data.data;
};

const DuplicateGameVersion = async (params: GameParamVersionDuplicate): Promise<ResponseMainGame> => {
  const response = await instance.post(
    `/v1/products/campaigns/${params.id_campaign}/game-versions/${params.gameVersion_id}/duplicate`
  );
  return response.data.data;
};

const PublicGameVersion = async (params: GameParamVersionPublic): Promise<ResponseMainGame> => {
  const response = await instance.post(
    `/v1/products/game-bases/${params.gameId}/game-versions/${params.gameVersion_id}`
  );
  return response.data.data;
};

const changeNameVersion = async (params: ChangeNameParamVersionPublic): Promise<ResponseMainGame> => {
  const response = await instance.post(
    `/v1/products/game-bases/${params.gameId}/game-versions/${params.gameVersion_id}/change-name`,
    { game_version_name: params.game_version_name }
  );
  return response.data.data;
};

const fetchGameThemesVersion = async (params: GameParamFetchGameThemesVersion): Promise<ResponseMainGame> => {
  const response = await instance.get(`/v1/products/game-bases/${params.gameId}/game-versions/${params.id}`);
  return response.data.data;
};

const deleteGame = async (params: GameParam): Promise<ResponseMainGame> => {
  const response = await instance.delete(`/v1/products/game-bases/${params.id_campaign}`);
  return response.data.data;
};

const actionGame = async (params: GameParam): Promise<ResponseMainGame> => {
  const response = await instance.post(`/v1/products/game-bases/${params.id_campaign}/update-status`);
  return response.data.data;
};

const fetchListGameBaseData = async (): Promise<ResponseMainGame> => {
  const response = await instance.get(`/v1/products/game-bases`);
  return response.data.data;
};

const chooseGameBase = async (params: GameBaseParam): Promise<ResponseMainGame> => {
  const response = await instance.post(`/v1/products/game-bases/${params.game_base_id}/choose`, params);
  return response.data.data;
};

const deleteMultiStaffs = async (params?: RequestPayload): Promise<Pageable<StaffInfo>> => {
  const response = await instance.post(`/v1/products/campaigns/${params?.id}/remove-staff-by-ids`, {
    staff_ids: params?.idSelected,
  });
  return response.data;
};

const fetchStrongNotificationList = async (): Promise<DataCustomerAccountProps> => {
  const response = await instance_user.get(`/v1/users/strong-notification/get-all-in-customer-page`);
  return response.data.data;
};

const actionExportPlayer = async (params: PlayerExportParams): Promise<string> => {
  const response = await instanceGetFile.get(`/v1/users/customers`, { params });
  return response.data;
};
export default {
  fetchIndividualUserData,
  fetchPersonalInformation,
  fetchListCampaign,
  fetchRole,
  updateRole,
  updateProfile,
  changePassword,
  createCampaign,
  updateCampaign,
  feedback,
  deleteCampaign,
  fetchCampaign,
  fetchRoles,
  inviteStaffInformation,
  statusStaffOutCampaign,
  fetchMainGameData,
  fetchMainGameVersion,
  deleteGameVersion,
  DuplicateGameVersion,
  PublicGameVersion,
  changeNameVersion,
  fetchGameThemesVersion,
  deleteGame,
  actionGame,
  fetchListGameBaseData,
  chooseGameBase,
  deleteMultiStaffs,
  actionExportPlayer,
  fetchStrongNotificationList,
};
