import { Box, Card, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { MainGameTypes } from '../slice/types';
import GameMoreMenu from './GameMoreMenu';
import StatusGame from './StatusGame';

export interface Props {
  game: MainGameTypes;
  gameId?: number;
  getListGame: () => void;
  children?: ReactNode;
}

export default function GameElement({ game, getListGame, gameId, children }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        p: 0,
        width: '326px',
        height: '307px',
        marginBottom: '20px',
        marginRight: '2vw',
      }}
    >
      <Box
        sx={{
          height: '304px',
          alignItems: 'flex-start',
        }}
      >
        <Stack
          direction="column"
          width="100%"
          sx={{
            '& img': {
              width: '100%',
              height: '190px',
            },
          }}
        >
          <img src={game?.game_thumbnail || '/static/illustrations/bannerLogin.png'} alt="" />
          <Box sx={{ padding: '0px 16px 0px 16px' }}>
            <Typography
              variant="subtitle2"
              noWrap
              sx={(theme) => ({
                fontSize: 18,
                color: theme.palette.primary.main,
              })}
            >
              {game.game_version_name?.split('_')[0]}
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              sx={(theme) => ({
                fontSize: 12,
                color: theme.palette.grey[100],
              })}
            >
              {`${t('gameBase.lastEdited')} :`}
              {moment(game?.updated_at).local().format('DD/MM/YYYY')}
            </Typography>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <StatusGame game={game} />
              <GameMoreMenu getListGame={getListGame} game={game} gameId={gameId} children={children} />
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Card>
  ); // các button more cho các game
}
