import { styled } from '@mui/material/styles';
import { useThemeState } from 'app/hooks';
import path from 'app/routes/path';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BackgroundGameConfig from 'assets/background/Background-gameconfig.png';
import RequestNewTheme from 'app/components/RequestNewTheme';
import DashboardNavbar from '../../components/NavBar';
import DashboardSidebar from '../../components/Sidebar';
import { useLayoutsSlice } from './slice';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 85;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const { actions } = useLayoutsSlice();
  const [open, setOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<any>();
  const { theme: customTheme, themeLoaded } = useThemeState();
  const navigate = useNavigate();
  const currentUrl = window.location.pathname;
  const { id, gameId, gameVersion_id } = useParams();
  const [searchParams] = useSearchParams();

  const MainStyle = styled('div')(({ theme }) => ({
    possition: 'relative',
    flexGrow: 1,
    overflow: 'hidden',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP,
    },
    background:
      currentUrl === `/campaigns/${id}/list-game/${gameId}` ||
      currentUrl === `/campaigns/${id}/list-game/${gameId}/request-new-theme`
        ? `url(${BackgroundGameConfig})`
        : '#f3f3f3',
  }));

  const renderAssetNewTheme = (
    url: string,
    campaignId?: string,
    gameId?: string,
    gameVersionId?: string,
    atGameVersion?: string,
    typeGameVersion?: string
  ) => {
    if (url === `/campaigns/${campaignId}/list-game/${gameId}`) return 'block';
    if (atGameVersion && atGameVersion === 'gameVersions' && typeGameVersion && typeGameVersion === 'official')
      return 'block';
    return 'none';
  };

  const AssetNewTheme = styled('div')(({ theme }) => ({
    position: 'absolute',
    // display: currentUrl === `/campaigns/${id}/list-game/${gameId}` ? 'block' : 'none',
    display: renderAssetNewTheme(
      currentUrl,
      id,
      gameId,
      gameVersion_id,
      searchParams.get('at') || '',
      searchParams.get('type') || ''
    ),
    bottom: '32px',
    right: '24px',
  }));

  useEffect(() => {
    if (window.location.pathname.length === 1) return navigate(path.homePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    setSelectedTheme(customTheme);
    dispatch(actions.showTheme(customTheme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeLoaded]);

  const handleChangeTheme = (theme: any) => {
    setSelectedTheme(theme);
    dispatch(actions.showTheme(theme));
  };

  return (
    <RootStyle>
      <DashboardNavbar
        isOpenNavBar={open}
        onOpenSidebar={() => setOpen(true)}
        handleChangeTheme={handleChangeTheme}
        isFullwidth={false}
      />
      {currentUrl && currentUrl !== '/home' && (
        <DashboardSidebar
          isOpenSidebar={open}
          inOpenSidebar={() => {
            setOpen(!open);
          }}
          onCloseSidebar={() => {
            setOpen(false);
          }}
          selectedTheme={selectedTheme}
        />
      )}
      <MainStyle>
        <Outlet />
        <AssetNewTheme>
          <RequestNewTheme />
        </AssetNewTheme>
      </MainStyle>
    </RootStyle>
  );
}
