import { Box, Link, Typography } from '@mui/material';
import { withLoading } from 'app/components/HOC/WithLoading';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import path from '../../routes/path';
import { useSnackbarSlice } from '../SnackBar/slice';
import { BackgroundImage } from './components/BackgroundImage';
import ResetPasswordForm from './components/ResetPasswordForm';
import { useAuthSlice } from './slice';

// ----------------------------------------------------------------------
interface Props {
  setLoading?: Function;
}

const ResetPassword = (props: Props) => {
  const { actions } = useAuthSlice();
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token') as string;

  const handleResetPassword = useCallback(
    ({ password, confirm_password }) => {
      dispatch(
        actions.resetPassword({ password, confirm_password, token }, (err?: any) => {
          if (!err) {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `Password reset successfully. Please sign in again.`,
                type: 'success',
              })
            );
            navigate(path.login);
          } else {
            if (err.error === 'invalid_user') {
              dispatch(
                snackbarActions.updateSnackbar({
                  message: 'Please check the information.',
                  type: 'error',
                })
              );
            }
            setError(err);
          }
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, dispatch, navigate, token]
  );

  const clearError = () => {
    setError(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#E2E2E2',
        height: '100%',
      }}
    >
      <BackgroundImage />
      {token ? (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: { md: '55%', xs: '100%' },
            mx: 3,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'end',
              top: '40px',
              right: '20px',
            }}
          >
            <Typography
              variant="body2"
              sx={(theme) => ({
                display: 'flex',
                fontSize: 14,
                color: theme.palette.primary.main,
              })}
            >
              {'Already have an account?'}
              <Link
                component={RouterLink}
                style={{ textDecoration: 'none' }}
                to={path.login}
                className="link-forgot-password"
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600, ml: 1 }}>
                  Sign in
                </Typography>
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              m: { md: '50px 140px', xs: '0px 0px' },
            }}
          >
            <Typography fontSize="18px">Reset password</Typography>
            <Typography fontSize="14px" my={1} fontWeight={400}>
              Enter your new password below.
            </Typography>
            <ResetPasswordForm onSubmit={handleResetPassword} error={error} onClearError={clearError} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '55%',
            mx: 3,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'end',
              top: '40px',
              right: '20px',
            }}
          >
            <Typography
              variant="body2"
              sx={(theme) => ({
                display: 'flex',
                fontSize: 14,
                color: theme.palette.primary.main,
              })}
            >
              {'Already have an account?'}
              <Link
                component={RouterLink}
                style={{ textDecoration: 'none' }}
                to={path.login}
                className="link-forgot-password"
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600, ml: 1 }}>
                  Sign in
                </Typography>
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              m: '50px 140px',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: '500',
              }}
              my={1}
              fontWeight={400}
            >
              Verification failed
            </Typography>
            <Typography fontSize="12px" my={1} fontWeight={400} sx={{ display: 'flex', alignItems: 'center' }}>
              The link you followed has expired.
              <Link
                component={RouterLink}
                style={{ textDecoration: 'underline' }}
                to={path.forgotPassword}
                className="link-forgot-password"
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px', marginLeft: '5px' }}>
                  Please try again.
                </Typography>
              </Link>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withLoading(ResetPassword);
