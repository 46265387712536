import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { FilterParams, Pageable, ParamsRequest } from 'types';
import notificationService from 'services/api/notificationService';
import { DataListAllNoti, NotiResponse } from './types';

import { notificationActions as actions } from '.';

function* fetchAllListNotification(action: PayloadAction<ParamsRequest, string, (error?: any) => void>) {
  try {
    const response: NotiResponse = yield call(notificationService.fetchAllListNoti, action.payload);
    yield put(actions.fetchAllListNotificationSuccess());
    action.meta({
      success: true,
      res: response.data,
    });
  } catch (error: any) {
    action.meta({
      success: false,
      response: error.response,
    });
  }
}

function* readNotification(action: PayloadAction<{ notiId: string }, string, (error?: any) => void>) {
  try {
    yield call(notificationService.readNotification, action.payload);
    yield put(actions.readNotificationSuccess());
    action.meta({
      success: true,
    });
  } catch (error: any) {
    action.meta({
      success: false,
      response: error.response,
    });
  }
}

function* readAllNotification(action: PayloadAction<ParamsRequest, string, (error?: any) => void>) {
  try {
    yield call(notificationService.readAllNotification, action.payload);
    yield put(actions.readAllNotificationSuccess());
    action.meta({
      success: true,
    });
  } catch (error: any) {
    action.meta({
      success: false,
      response: error.response,
    });
  }
}

function* getAllListNotification(action: PayloadAction<FilterParams>) {
  try {
    const result: Pageable<DataListAllNoti> = yield call(notificationService.getAllListNotification, action.payload);
    yield put(actions.getAllListNotificationSuccess(result));
  } catch (errors) {}
}

export function* notificationSaga() {
  yield takeLatest(actions.fetchAllListNotification.type, fetchAllListNotification);
  yield takeLatest(actions.readNotification.type, readNotification);
  yield takeLatest(actions.readAllNotification.type, readAllNotification);
  yield takeLatest(actions.getAllListNotification.type, getAllListNotification);
}
