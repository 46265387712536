import { useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { EMAIL_REGEX, PASSWORD_REGEX } from 'utils/helpers/regex';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Link,
  styled,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { AuthParams } from 'types';
import path from 'app/routes/path';
import { ErrorLogin } from 'types/Enum';
import { get } from 'lodash';
export const RootStyle = styled('div')({
  '& .MuiFilledInput-root': {
    borderRadius: '8px',
    background: '#FFFFFF',
  },
  '& .MuiFilledInput-root:after': {
    right: 'unset',
    border: '1px solid  #005FC5',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root.Mui-error:after': {
    border: '1px solid  #FF4842',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root:before': {
    right: 'unset',
    content: '""',
  },
  '& .MuiFormControl-root:after': {
    border: '1px solid  #005FC5',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
});

// ----------------------------------------------------------------------
interface Props {
  onSubmit: (values: AuthParams) => void;
  error?: any;
  onClearError?: () => void;
  loading: boolean;
}

const authSchema = Yup.object()
  .shape({
    email: Yup.string().required('Please enter email!').email('Wrong email format!'),
    password: Yup.string().required('Please enter your password!'),
  })
  .required();

export default function LoginForm(props: Props) {
  const { onSubmit, error, onClearError, loading } = props;
  const [showPassword, setShowPassword] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm<AuthParams>({
    defaultValues: {
      email: '',
      password: '',
      remember_me: true,
    },
    resolver: yupResolver(authSchema),
    mode: 'all',
  });
  const [isDisableButtonLogin, setIsdisableButtonLogin] = useState<boolean>(true);

  const handleShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  const checkFullData = () => {
    if (getValues('email') && !EMAIL_REGEX.test(getValues('email'))) {
      setIsdisableButtonLogin(true);
      setError('email', { message: 'Wrong email format!' });
      return;
    }
    if (getValues('email') && getValues('password')) {
      setIsdisableButtonLogin(false);
    } else {
      setIsdisableButtonLogin(true);
    }
    if (error?.message === 'email_incorrect') {
      setError('email', { message: 'Email already exists' });
    }
    if (error?.message === 'account_is_inactive') {
      setError('email', { message: 'Your account has been deactivated' });
    }
  };

  const validatedPassword = () => {
    if (getValues('password') && !PASSWORD_REGEX.test(getValues('password'))) {
      setError('password', {
        message: 'Password must be between 8 and 15 characters and include letters, numbers, and special characters!',
      });
      setIsdisableButtonLogin(true);
      return;
    }
  };

  useEffect(() => {
    if (error?.message === 'email_incorrect') {
      setError('email', { message: 'Email incorrect' });
    }
  }, [error, setError]);

  return (
    <RootStyle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  placeholder={'Email address'}
                  label={'Email'}
                  error={!!errors?.email || error?.message === 'email_incorrect'}
                  helperText={errors.email?.message}
                  onChange={(e) => {
                    if (e.target.value && getValues('password') && EMAIL_REGEX.test(e.target.value)) {
                      setIsdisableButtonLogin(false);
                    }
                    field.onChange(e);
                    clearErrors('email');
                    onClearError && onClearError();
                  }}
                  onBlur={(e: any) => {
                    field.onChange(e.target.value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '));
                    checkFullData();
                  }}
                  // onBlur={checkFullData}
                />
              );
            }}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  variant="filled"
                  placeholder={'Password'}
                  type={showPassword ? 'text' : 'password'}
                  label={'Password'}
                  error={!!errors.password || error?.message === 'wrong_password'}
                  helperText={
                    error.message === 'wrong_password' ? `${get(ErrorLogin, error.message)}` : errors.password?.message
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon
                            icon={showPassword ? 'eva:eye-outline' : 'eva:eye-off-outline'}
                            color="#585858"
                            width={22}
                            height={22}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value && getValues('email')) {
                      setIsdisableButtonLogin(false);
                    }
                    field.onChange(e);
                    onClearError && onClearError();
                  }}
                  onBlur={validatedPassword}
                />
              );
            }}
          />
        </Stack>
        <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between">
          <Controller
            name="remember_me"
            control={control}
            render={({ field }) => {
              return (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    }
                    label="Remember me"
                  />
                </FormGroup>
              );
            }}
          />
          <Box
            sx={{
              mt: 1,
              '& a': {
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '20px',
              },
            }}
          >
            <Link component={RouterLink} variant="subtitle2" to={path.forgotPassword} className="link-forgot-password">
              {'Forgot password?'}
            </Link>
          </Box>
        </Box>
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          disabled={isDisableButtonLogin}
          sx={(theme) => ({
            backgroundColor: isDisableButtonLogin ? '#EAECEF' : '#585858',
            color: '#FFFFFF',
            mt: 5,
            padding: '20px 0',
            fontWeight: '700',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            bgcolor: '#585858',
            height: '36px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '0px',
            borderTopRightRadius: '0px',
          })}
        >
          Sign in
        </LoadingButton>
      </form>
    </RootStyle>
  );
}
