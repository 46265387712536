export const API_STORAGE_S3 = process.env.REACT_APP_PUBLIC_API_STORAGE_S3;

const ONE_MB = Math.pow(1024, 2);
export const MAX_FILE_SIZE = 3 * ONE_MB;

export const FILE_ERROR_MESSAGES = {
  required: 'Trường này là bắt buộc',
  invalid: (fileName: string) => `${fileName} không hợp lệ`,
  oversize: 'File vượt quá kích thước',
  incorrectsize: 'Định dạng hình ảnh này không hợp lệ',
};

export const FILE_ERROR_MESSAGES_EN = {
  required: 'This field is required',
  invalid: (fileName: string) => `${fileName} invalid`,
  oversize: 'File exceeds size',
  incorrectsize: 'This image format is invalid',
};
