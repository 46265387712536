import {
  FilterParams,
  GiftCodeInfomation,
  GiftCodeUpload,
  Pageable,
  PlayerExportParamsStaff,
  RemoveGiftCodeRequest,
  RequestPayload,
  RewardInformation,
  RewardRequest,
} from 'types';
import { serialize, serializeExport } from 'utils/helpers';
import { createService, downloadFileService } from './axios';

const baseURL = process.env.REACT_APP_API_URL_PRODUCT;
const instance = createService(baseURL);

const instanceGetFile = downloadFileService(baseURL, 'application/json');
const fetchGameRewardData = async (params?: FilterParams): Promise<Pageable<RewardInformation>> => {
  const newParams = { ...params };
  delete newParams.campaignId;
  const response = await instance.get(
    `/v1/products/campaigns/${params?.campaignId}/rewards-list?${serialize(newParams)}`
  );
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const createReward = async (param: RewardRequest) => {
  const response = await instance.post(`/v1/products/rewards`, param);
  return response.data;
};

const fetchRewardInformation = async (param: string): Promise<RewardInformation> => {
  const response = await instance.get(`/v1/products/rewards/${param}`);
  return response.data.data;
};

const editReward = async (param: RewardRequest) => {
  const newParam = { ...param };
  delete newParam.reward_id;
  const response = await instance.post(`/v1/products/rewards/${param.reward_id}`, newParam);
  return response.data;
};

const removeReward = async (params: { rewardId: number }): Promise<RewardInformation> => {
  const response = await instance.delete(`/v1/products/rewards/${params.rewardId}`);
  return response.data;
};

const deleteMultiReward = async (params?: RequestPayload): Promise<Pageable<RewardInformation>> => {
  const response = await instance.post(`/v1/products/rewards/${params?.id}/remove-reward-by-ids`, {
    reward_ids: params?.idSelected,
  });
  return response.data;
};

const actionExportPlayerStaff = async (params: PlayerExportParamsStaff): Promise<PlayerExportParamsStaff> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  const response = await instanceGetFile.get(
    `/v1/products/campaigns/${params?.campaign_id}/get-staffs?${serializeExport(newParams)}`,
    {}
  );
  return response.data;
};

const actionExportRedemReward = async (params: PlayerExportParamsStaff): Promise<PlayerExportParamsStaff> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  const response = await instanceGetFile.get(
    `/v1/products/campaigns/${params?.campaign_id}/redemptions?${serializeExport(newParams)}`,
    {}
  );
  return response.data;
};

const actionExportReward = async (params: PlayerExportParamsStaff): Promise<PlayerExportParamsStaff> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  const response = await instanceGetFile.get(
    `/v1/products/campaigns/${params?.campaign_id}/rewards-list?${serializeExport(newParams)}`,
    {}
  );
  return response.data;
};

const fetchGameGiftCodeData = async (params?: FilterParams): Promise<Pageable<RewardInformation>> => {
  const newParams = { ...params };
  delete newParams.campaignId;
  const response = await instance.get(
    `/v1/products/campaigns/${params?.campaignId}/gift-codes/get-by-campaign?${serialize(newParams)}`
  );
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const importGiftCode = async (param: GiftCodeUpload) => {
  try {
    const formData = new FormData();
    formData.append('campaign_id', param.campaign_id || '');
    formData.append('description', param.description || '');
    formData.append('status', param.status || '');
    formData.append('point_for_redeem', param.point_for_redeem || '');
    formData.append('imageUrl', param.imageUrl || '');
    formData.append('type', param.type || '');
    formData.append('reward_name', param.reward_name || '');
    formData.append('importFile', param.importFile || File);
    const response = await instance.post(`/v1/products/rewards/import/gift-code`, formData);
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

const dowloadTemplate = async (param: any) => {
  try {
    const formData = new FormData();
    formData.append('campaign_id', param.campaign_id || '');
    formData.append('description', param.description || '');
    formData.append('status', param.status || '');
    formData.append('point_for_redeem', param.point_for_redeem || '');
    formData.append('imageUrl', param.imageUrl || '');
    formData.append('type', param.type || '');
    formData.append('reward_name', param.reward_name || '');
    formData.append('importFile', param.importFile || File);

    await instance.post(`/v1/products/rewards/import/download-template`, formData);
    const _data = {
      status: true,
    };
    return _data;
  } catch (e: any) {
    const _data = {
      status: false,
    };
    return _data;
  }
};

const fetchGiftCodesByRewardId = async (params?: FilterParams): Promise<Pageable<GiftCodeInfomation>> => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  delete newParams.reward_id;
  const response = await instance.get(
    `/v1/products/campaigns/${params?.campaign_id}/rewards/${params?.reward_id}/gift-code/?${serialize(newParams)}`
  );
  const res = {
    data: response.data.data.data,
    total: response.data.data.total,
  };
  return res;
};

const removeGiftCode = async (params: RemoveGiftCodeRequest): Promise<GiftCodeInfomation> => {
  const response = await instance.delete(
    `/v1/products/campaigns/${params.campaign_id}/rewards/${params.reward_id}/gift-code/${params.gift_code}`
  );
  return response.data;
};

const removeMultiGiftCode = async (params: RequestPayload) => {
  try {
    const formData = new FormData();
    formData.append('gift_code_ids', params.gift_code_ids || '');
    await instance.post(
      `/v1/products/campaigns/${params.campaign_id}
      /rewards/${params.reward_id}/delete-by-ids`,
      formData
    );
    const _data = {
      status: true,
    };
    return _data;
  } catch (e: any) {
    const _data = {
      status: false,
    };
    return _data;
  }
};

const editGiftCode = async (param: RewardRequest) => {
  try {
    const newParam = { ...param };
    delete newParam.reward_id;
    delete newParam.campaign_id;
    delete newParam.gift_code;
    const response = await instance.put(
      `/v1/products/campaigns/${param.campaign_id}/rewards/${param.reward_id}/gift-code/${param.gift_code}`,
      newParam
    );
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

const createGiftCode = async (param: RewardRequest) => {
  const newParam = { ...param };
  delete newParam.reward_id;
  delete newParam.campaign_id;
  const response = await instance.post(
    `/v1/products/campaigns/${param.campaign_id}/rewards/${param.reward_id}/gift-code`,
    newParam
  );
  return response.data;
};

const createGiftCodeImportFile = async (param: any) => {
  try {
    const formData = new FormData();
    formData.append('importFile', param.importFile || File);
    const response = await instance.post(
      `/v1/products/campaigns/${param.campaign_id}/rewards/${param.reward_id}/import-gift-code`,
      formData
    );
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

const actionExportGiftCodeDetail = async (params?: FilterParams) => {
  const newParams = { ...params };
  delete newParams.campaign_id;
  delete newParams.reward_id;
  const response = await instanceGetFile.get(
    `/v1/products/campaigns/${params?.campaign_id}/rewards/${params?.reward_id}/gift-code/?${serialize(newParams)}`
  );
  return response.data;
};

const dowloadTemplateGiftCode = async () => {
  const response = await instanceGetFile.post(`/v1/products/rewards/import/download-template`);
  return response.data;
};

export default {
  createReward,
  fetchRewardInformation,
  editReward,
  fetchGameRewardData,
  removeReward,
  deleteMultiReward,
  actionExportPlayerStaff,
  actionExportReward,
  actionExportRedemReward,
  fetchGameGiftCodeData,
  importGiftCode,
  dowloadTemplate,
  fetchGiftCodesByRewardId,
  removeGiftCode,
  removeMultiGiftCode,
  editGiftCode,
  createGiftCode,
  createGiftCodeImportFile,
  actionExportGiftCodeDetail,
  dowloadTemplateGiftCode,
};
