import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { ProfileParams } from 'types/Profile';

import { UserInfoManagementState } from './types';

import { updateProfileManagementSaga } from './saga';

export const initialState: UserInfoManagementState = {};

const slice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    updateProfileSuccess() {},
    updateProfile: {
      reducer(state) {
        return state;
      },
      prepare(params: ProfileParams, meta: (error?: any) => void) {
        return { payload: params, meta };
      },
    },
  },
});

export const { actions: updateProfileManagementActions } = slice;

export const useUpdateProfileManagementSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: updateProfileManagementSaga });
  return { actions: slice.actions };
};
