import { Icon } from '@iconify/react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import path from 'app/routes/path';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TokenRequest } from 'types';
import { TypeSocial } from 'types/Enum';
import { useAuthSlice } from '../../slice';
// ----------------------------------------------------------------------

export default function AuthSocial() {
  const { actions: snackbarActions } = useSnackbarSlice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useAuthSlice();
  const facebookClientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID || '';

  const responseGmail = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const params: TokenRequest = {
        provider: TypeSocial.GOOGLE,
        access_provider_token: codeResponse.access_token as string,
      };
      handleLogin(params);
    },
  });

  const handleLogin = (params: TokenRequest) => {
    dispatch(
      actions.loginByGoogle(params, (err: any) => {
        if (!err) {
          dispatch(
            snackbarActions.updateSnackbar({
              message: 'Đăng nhập thành công',
              type: 'success',
            })
          );
          navigate(path.root);
        } else {
          dispatch(
            snackbarActions.updateSnackbar({
              message: 'Đăng nhập không thành công',
              type: 'error',
            })
          );
        }
      })
    );
  };

  const responseFacebook = (response: any) => {
    const params: TokenRequest = {
      provider: TypeSocial.FACEBOOK,
      access_provider_token: response.accessToken as string,
    };
    handleLogin(params);
  };

  return (
    <>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary', display: { md: 'none' } }}>
          Or, sign in with
        </Typography>
      </Divider>

      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          onClick={() => responseGmail()}
          sx={{
            borderColor: '#585858',
            height: '36px',
            borderRadius: '10px 0',
          }}
          variant="outlined"
        >
          <Icon icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
        </Button>

        <FacebookLogin
          appId={facebookClientId}
          scope={'public_profile, email'}
          callback={responseFacebook}
          render={(renderProps) => (
            <Button
              fullWidth
              onClick={renderProps.onClick}
              style={{
                borderColor: '#585858',
                height: '36px',
                borderRadius: '10px 0',
              }}
              variant="outlined"
            >
              <Icon icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
            </Button>
          )}
        />
      </Stack>
    </>
  );
}
