import { Box, Container, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import BreadCrumbs from 'app/components/BreadCrumbs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMainGameSlice } from '../../slice';
import { selectMainGameList } from '../../slice/selectors';
import { MainGameTypes, TypeGameAR } from '../../slice/types';
import GameBaseList from './GameBaseList';
import GameArSpecial from './GameArSpecial';

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  position: 'relative',
}));

export default function AddGame() {
  const { t } = useTranslation();
  const { actions } = useMainGameSlice();
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const fetchFormData = useSelector(selectMainGameList);
  const { mainGameList } = fetchFormData;

  const links = React.useMemo(
    () => [
      {
        title: t('Game list'),
        path: `/campaigns/${id}/list-game`,
      },
      {
        title: t('Add Game'),
        path: `/campaigns/${id}/list-game/add-game`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const gameAR = React.useMemo(() => {
    const listGame = mainGameList as MainGameTypes[] | undefined;
    if (listGame && listGame?.length) {
      const argameFilter = listGame?.filter((e: MainGameTypes) => e.game_type === TypeGameAR.GAME_AR);
      return argameFilter;
    }
    return [];
  }, [mainGameList]);

  useEffect(() => {
    dispatch(actions.fetchListGameBaseData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banner = (
    <Box style={{ width: '100%', height: '100%' }}>
      <Container
        style={{ position: 'absolute', paddingLeft: '8%', paddingRight: '8%', paddingTop: '2%', maxWidth: '100%' }}
      >
        <Stack sx={(theme) => ({ marginBottom: '50px' })}>
          <BreadCrumbs links={links} />
        </Stack>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <GameArSpecial listGameAr={gameAR} />
        </Stack>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <GameBaseList gameList={mainGameList} />
        </Stack>
      </Container>
      <img style={{ width: '100%', height: '55%' }} src={'/static/icons/banner-v2.png'} alt="banner" />
    </Box>
  );

  return <>{mainGameList && <MainStyle>{banner}</MainStyle>}</>;
}
