import React from 'react';
import { Box, Typography, Link, useTheme } from '@mui/material';
import path from 'app/routes/path';
import { Link as RouterLink } from 'react-router-dom';
import { BackgroundImage } from './components/BackgroundImage';

const SignInFailedPage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: { md: 'flex' },
        flexDirection: 'row',
        backgroundColor: theme.palette.primary.lighter,
        height: '100%',
      }}
    >
      <BackgroundImage />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: { md: '55%', xs: '100%' },
          marginLeft: { xs: '0px' },
          height: { xs: '100%' },
          mx: 3,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'end',
            top: { md: '40px', xs: '95vh' },
            right: { md: '10px', xs: '60px' },
          }}
        >
          <Typography
            variant="body2"
            sx={(theme) => ({
              display: 'flex',
              fontSize: 14,
              color: theme.palette.primary.main,
            })}
          >
            {'Already have an account?'}
            <Link
              component={RouterLink}
              style={{ textDecoration: 'none' }}
              to={path.login}
              className="link-forgot-password"
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: { md: '600', xs: '700' }, ml: 1, textDecoration: 'underline' }}
              >
                Sign in
              </Typography>
            </Link>
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            m: { md: '100px 100px', xs: '35px 24px 85px 24px' },
          })}
        >
          <Typography sx={{ display: { md: 'none', xs: 'flex' }, justifyContent: 'center' }}>
            <img src="/static/illustrations/Logo_mobile.svg" alt="" width={'220px'} height={'40px'} />
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: '18px',
              paddingTop: { xs: '12vw', md: '0vw' },
              fontWeight: 600,
            })}
          >
            Sign in failed
          </Typography>
          <Typography fontSize="14px" my={1} fontWeight={400} sx={{ color: theme.palette.secondary.contrastText }}>
            Your account has been deactivated.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SignInFailedPage;
