import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AuthorizationRouteCampaign from 'app/components/Permission/PermissionCampaign';
import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import path from 'app/routes/path';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import { Fragment, useMemo, useState } from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionsType } from 'types/Enum';
import Duplicate from 'assets/background/duplicate.svg';
import DeleteIcon from 'assets/background/icon_delete.svg';
import PublishIcon from 'assets/background/publish.svg';

import { useMainGameSliceGameVersion } from '../slice';
import { DataMainGameProps, MainGameTypes } from '../slice/types';
import GameElement from './GameElement';

export interface Props {
  gameList?: DataMainGameProps;
  getListGame: () => void;
  rules?: RegisterOptions;
}

interface PropMoreMenu {
  item: MainGameTypes;
}

export default function ListGame({ gameList, getListGame }: Props) {
  const listGameBase = gameList as MainGameTypes[] | undefined;
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const [openConfirmEditName, setopenConfirmEditName] = useState(false);
  const [openConfirmPublishGame, setOpenConfirmPublishGame] = useState(false);
  const dispatch = useDispatch();
  const { actions } = useMainGameSliceGameVersion();
  const { actions: snackbarActions } = useSnackbarSlice();
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isDisableButtonDelete, setIsdisableButtonDelete] = useState(true);
  const [itemDraft, setItemDraft] = useState<MainGameTypes>();
  const { themeInfo } = useSelector(layoutsSelector);

  const { control } = useForm({
    mode: 'all',
  });

  const gameDraft = useMemo(() => {
    return listGameBase?.filter((item) => item.active_version === 0) || null;
  }, [listGameBase]);

  const [gameVersionName, setGameVersionName] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setIsCheck(event.target.checked);
    setIsdisableButtonDelete(!event.target.checked);
  };
  const handleEdit = (data: MainGameTypes) => {
    const gameId = data.game_id;
    const gameVersion_id = data.id;

    navigate(`${path.campaign}/${id}/games/${gameId}/game-versions/${gameVersion_id}?at=gameVersions`, {
      state: { isDraft: true },
    });
  };

  const handleCancelDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpenConfirm(false);
    setOpenConfirmPublishGame(false);
    setopenConfirmEditName(false);
  };

  const handleOpenEditName = (event: React.MouseEvent<HTMLElement>, item: MainGameTypes) => {
    event.stopPropagation();
    setopenConfirmEditName(true);
    setGameVersionName(item.game_version_name);
    setItemDraft(item);
  };

  const handleOpenPublishGame = (event: React.MouseEvent<HTMLElement>, item: MainGameTypes) => {
    event.stopPropagation();
    setOpenConfirmPublishGame(true);
    setItemDraft(item);
  };

  const handleOpenDelete = (event: React.MouseEvent<HTMLElement>, item: MainGameTypes) => {
    event.stopPropagation();
    setOpenConfirm(true);
    setItemDraft(item);
  };

  const handlePublic = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const gameId = itemDraft?.game_id;
    const gameVersion_id = itemDraft?.id;
    dispatch(
      actions.PublicGameVersion({ gameId, gameVersion_id }, (err?: any) => {
        if (!err) {
          getListGame();
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('configManagement.publishedNewGameVersion')}`,
              type: 'success',
            })
          );
          setOpenConfirmPublishGame(false);
        } else {
          if (err.message === 'campaign_of_game_has_been_ended') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('campaignBeenEnded')}`,
                type: 'error',
              })
            );
          }
        }
      })
    );
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const gameId = itemDraft?.game_id;
    const gameVersion_id = itemDraft?.id;
    event.stopPropagation();
    dispatch(
      actions.deleteGameVersion({ gameId, gameVersion_id }, (err?: any) => {
        if (!err) {
          getListGame();
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('configManagement.deletedGameVersion')} `,
              type: 'success',
            })
          );
          setOpenConfirm(false);
        } else {
          if (err.message === 'can_not_delete_version_official') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('campaignBeenEnded')}`,
                type: 'error',
              })
            );
          }
        }
      })
    );
  };

  const handleChangeName = () => {
    const gameId = itemDraft?.game_id;
    const gameVersion_id = itemDraft?.id;
    const game_version_name = gameVersionName;
    dispatch(
      actions.changeNameGameVersion({ gameId, gameVersion_id, game_version_name }, (err?: any) => {
        if (!err) {
          getListGame();
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('configManagement.changedVersionName')} `,
              type: 'success',
            })
          );
          setopenConfirmEditName(false);
        } else {
          if (err.message === 'The game version name must not be greater than 50 characters.') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('common.noMoreThan50Characters')}`,
                type: 'error',
              })
            );
          }
          if (err.message === 'name_version_must_unique') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('configManagement.thisNameDuplicated')}`,
                type: 'error',
              })
            );
          }
          if (err.message === 'The game version name field is required.') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('configManagement.theGameVersionNameFieldRequired')}`,
                type: 'error',
              })
            );
          }
        }
      })
    );
  };

  const handleDuplicate = (data: MainGameTypes) => {
    const gameVersion_id = data.id;
    const id_campaign = Number(id);
    dispatch(
      actions.DuplicateGameVersion({ id_campaign, gameVersion_id }, (err?: any) => {
        if (!err) {
          getListGame();
          dispatch(
            snackbarActions.updateSnackbar({
              message: `${t('configManagement.duplicated')} ${data.game_version_name}`,
              type: 'success',
            })
          );
        } else {
          if (err.message === 'limit_draft_versions') {
            dispatch(
              snackbarActions.updateSnackbar({
                message: `${t('configManagement.canNotDuplicateThan5Versions')}`,
                type: 'error',
              })
            );
          }
        }
      })
    );
  };

  const MoreMenuDraft = ({ item }: PropMoreMenu) => {
    const totalDuplicate = Number(listGameBase?.length) - 1;

    return (
      <Fragment>
        <AuthorizationRouteCampaign campaignId={Number(id)} allowedPermissions={[PermissionsType.papa_game_update]}>
          <MenuItem
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            onClick={() => handleEdit(item)}
          >
            <ListItemIcon>
              <Icon icon="solar:settings-linear" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={t('Edit configuration')} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </AuthorizationRouteCampaign>

        <AuthorizationRouteCampaign
          campaignId={Number(id)}
          allowedPermissions={[PermissionsType.papa_game_status_update]}
        >
          <MenuItem
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            onClick={(e) => handleOpenEditName(e, item)}
          >
            <ListItemIcon>
              <Icon icon="carbon:edit" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={t('configManagement.rename')} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          <MenuItem
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            onClick={() => handleDuplicate(item)}
          >
            <ListItemIcon>
              <img src={Duplicate} alt="" width="24px" height="24px" />
            </ListItemIcon>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <ListItemText primary={t('configManagement.duplicate')} primaryTypographyProps={{ variant: 'body2' }} />
              <Box
                sx={(theme) => ({
                  fontSize: '14px',
                  color: theme.palette.primary.main,
                })}
              >
                ({totalDuplicate}/5)
              </Box>
            </Box>
          </MenuItem>

          <MenuItem
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            onClick={(e) => handleOpenPublishGame(e, item)}
          >
            <ListItemIcon>
              <img src={PublishIcon} alt="" width="24px" height="24px" />
            </ListItemIcon>
            <ListItemText
              primary={t('configManagement.publishVersion')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>

          <AuthorizationRouteCampaign campaignId={Number(id)} allowedPermissions={[PermissionsType.papa_game_delete]}>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={(e) => handleOpenDelete(e, item)}>
              <ListItemIcon>
                <img src={DeleteIcon} alt="" width="24px" height="24px" />
              </ListItemIcon>

              <ListItemText primary={t('staff.delete')} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </AuthorizationRouteCampaign>
        </AuthorizationRouteCampaign>
      </Fragment>
    );
  };

  return (
    <Grid container sx={{ justifyContent: 'flex-start' }}>
      {gameDraft &&
        gameDraft?.map((item) => (
          <GameElement game={item} getListGame={getListGame} children={<MoreMenuDraft item={item} />} />
        ))}
      <Dialog open={openConfirmEditName} fullWidth maxWidth={'xs'}>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <DialogTitle sx={(theme) => ({ color: theme.palette.primary.main, fontSize: '18px !important' })}>
            {t('configManagement.rename')}
          </DialogTitle>
          <IconButton
            onClick={handleCancelDelete}
            sx={{ color: `${themeInfo?.bgColor}`, paddingTop: '10px', marginBottom: '15px' }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>

        <DialogContent>
          {/* ----------------- */}
          <Controller
            name={'game_version_name'}
            control={control}
            rules={{
              required: String(t('common.fieldEmpty')),
              maxLength: {
                value: 50,
                message: t('common.noMoreThan50Characters'),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  sx={{ marginTop: '20px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <Typography
                      variant="subtitle2"
                      noWrap
                      color={'primary'}
                      sx={{
                        fontSize: 15,
                        fontWeight: 400,
                        mt: '0px',
                        mb: '10px',
                        display: 'flex',
                        marginBottom: '30px',
                      }}
                    >
                      {t('versionName')}
                      <Typography
                        sx={(theme) => ({
                          fontSize: 15,
                          fontWeight: 400,
                          color: theme.palette.info.darker,
                          margin: '1px 3px 0px 4px',
                        })}
                      >
                        *
                      </Typography>
                    </Typography>
                  }
                  size="small"
                  fullWidth
                  variant="filled"
                  defaultValue={gameVersionName}
                  onChange={(e) => {
                    setGameVersionName(e.target.value);
                    field.onChange(e.target.value);
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    style: {
                      marginBottom: '12px',
                    },
                  }}
                />
              );
            }}
          />
          {/* <Typography>
           
          </Typography> */}
          {/* ---------------- */}

          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Button
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.lighter,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
                width: '70px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
              onClick={handleCancelDelete}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={handleChangeName}
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.lighter,
                },
                width: '120px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
            >
              {t('configManagement.ok')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog open={openConfirm} fullWidth maxWidth={'xs'}>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <DialogTitle sx={(theme) => ({ color: theme.palette.primary.main, fontSize: '18px !important' })}>
            {t('configManagement.deleteGameVersion')}
          </DialogTitle>
          <IconButton onClick={handleCancelDelete} sx={{ color: `${themeInfo?.bgColor}`, paddingTop: '10px' }}>
            <CloseIcon />
          </IconButton>
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            color={'primary'}
            sx={(theme) => ({
              fontSize: { xs: '12px', sm: '14px' },
              fontWeight: 400,
              mt: { xs: '0px', sm: '24px' },
              mb: { xs: '0px', sm: '35px' },
              display: 'flex',
              justifyContent: 'center',
              color: theme.palette.primary.main,
              textAlign: 'center',
            })}
          >
            {t('configManagement.areYouSureDeleteGameVersion')}
          </Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}></Stack>
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Button
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.lighter,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
                width: '70px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
              onClick={handleCancelDelete}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={handleDelete}
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.lighter,
                },
                width: '120px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
              // disabled={isDisableButtonDelete}
            >
              {t('reward.delete')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      {/* ....................... */}
      <Dialog open={openConfirmPublishGame}>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <DialogTitle sx={(theme) => ({ color: theme.palette.primary.main, fontSize: '18px !important' })}>
            {t('configManagement.publishGame')}
          </DialogTitle>
          <IconButton onClick={handleCancelDelete} sx={{ color: `${themeInfo?.bgColor}`, paddingTop: '10px' }}>
            <CloseIcon />
          </IconButton>
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            color={'primary'}
            sx={(theme) => ({
              fontSize: { xs: '12px', sm: '14px' },
              textAlign: 'center',
              fontWeight: 400,
              mt: { xs: '12px', sm: '24px' },
              mb: { xs: '12px', sm: '35px' },
              display: 'flex',
              justifyContent: 'center',
              color: theme.palette.primary.main,
            })}
          >
            {t('configManagement.AreYouSurePublishGameVersion')}
            {<br></br>}&nbsp;&nbsp;
            {t('configManagement.ThisGameVersionWillCurrent')}
          </Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Checkbox checked={isCheck} onChange={handleChange} color="default" />
            <Typography
              variant="subtitle2"
              color={'primary'}
              sx={(theme) => ({
                fontSize: { xs: '12px', sm: '14px' },
                textAlign: 'center',
                fontWeight: 400,
                display: 'flex',
                justifyContent: 'center',
                color: theme.palette.primary.main,
                marginTop: { xs: '15px', sm: '0px' },
              })}
            >
              {t('configManagement.IConfirmThatPublishGameVersion')}
            </Typography>
          </Stack>
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Button
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.lighter,
                color: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
                width: '70px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
              })}
              onClick={handleCancelDelete}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={handlePublic}
              sx={(theme) => ({
                backgroundColor: theme.palette.success.lighter,
                '&:hover': {
                  backgroundColor: theme.palette.primary.lighter,
                },
                width: '120px',
                display: 'flex',
                height: '36px',
                borderRadius: '10px 0 10px 0',
                mr: '20px',
                mt: '40px',
                fontSize: '14px',
                '&.MuiButton-root': {
                  background: theme.palette.primary.main,
                  color: theme.palette.secondary.lighter,
                  '&.Mui-disabled': {
                    background: theme.palette.success.lighter,
                    color: theme.palette.secondary.lighter,
                  },
                },
              })}
              disabled={isDisableButtonDelete}
            >
              {t('configManagement.publish')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
