import { useState, useCallback } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { PASSWORD_REGEX } from 'utils/helpers/regex';
import { Stack, TextField, IconButton, InputAdornment, Button, styled, Box } from '@mui/material';
import { ResetPasswordRequest } from 'types';

export const RootStyle = styled('div')({
  '& .MuiFilledInput-root': {
    borderRadius: '8px',
    background: '#F6F8FC',
  },
  '& .MuiFilledInput-root:after': {
    right: 'unset',
    border: '1px solid  #005FC5',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root.Mui-error:after': {
    border: '1px solid  #FF4842',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root:before': {
    right: 'unset',
    content: '""',
  },
  '& .MuiFormControl-root:after': {
    border: '1px solid  #005FC5',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
});

// ----------------------------------------------------------------------
interface Props {
  onSubmit: (values: ResetPasswordRequest) => void;
  error?: any;
  onClearError?: () => void;
}

const authSchema = Yup.object()
  .shape({
    password: Yup.string().required('Please enter a password').max(15, 'Password up to 15 characters'),
    confirm_password: Yup.string()
      .required('Please enter password confirmation')
      .oneOf([Yup.ref('password')], 'Password does not match.'),
  })
  .required();

export default function ResetPasswordForm(props: Props) {
  const { onSubmit, error, onClearError } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm<ResetPasswordRequest>({
    defaultValues: {
      password: '',
      confirm_password: '',
    },
    resolver: yupResolver(authSchema),
    mode: 'all',
  });

  const handleShowPassword = useCallback((type: string) => {
    if (type === 'password') return setShowPassword((show) => !show);
    setShowConfirmPassword((show) => !show);
  }, []);

  const validatedPassword = () => {
    if (getValues('password') && !PASSWORD_REGEX.test(getValues('password'))) {
      setError('password', {
        message: 'Password must be between 8 and 15 characters and include letters, numbers, and special characters!',
      });
      return;
    }
  };

  return (
    <RootStyle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  variant="filled"
                  placeholder={'Password'}
                  type={showPassword ? 'text' : 'password'}
                  label={'Password'}
                  error={!!errors.password || error?.error === 'invalid_password'}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleShowPassword('password')} edge="end">
                          <img
                            src={showPassword ? '/static/icons/eye.svg' : '/static/icons/eye-off.svg'}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors('password');
                  }}
                  onBlur={validatedPassword}
                />
              );
            }}
          />
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  variant="filled"
                  placeholder={'Confirm password'}
                  type={showConfirmPassword ? 'text' : 'password'}
                  label={'Confirm password'}
                  error={!!errors.confirm_password || error?.error === 'invalid_password'}
                  helperText={errors.confirm_password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleShowPassword('confirm_password')} edge="end">
                          <img
                            src={showConfirmPassword ? '/static/icons/eye.svg' : '/static/icons/eye-off.svg'}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                    onClearError && onClearError();
                  }}
                />
              );
            }}
          />
        </Stack>
        <Box
          mt={2}
          mx={5}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ '& ul': { fontSize: { md: '12px', xs: '14px' }, fontWeight: 400 } }}
        >
          <ul>
            <li>Must have 8 - 15 characters</li>
            <li>Including: upper-case letter, lower-case letter, number and special character.</li>
          </ul>
        </Box>
        <Button
          type="submit"
          fullWidth
          sx={(theme) => ({
            color: '#FFFFFF',
            mt: 5,
            padding: '20px 0',
            fontWeight: '700',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
            bgcolor: '#585858',
            height: '36px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '0px',
            borderTopRightRadius: '0px',
          })}
        >
          Complete
        </Button>
      </form>
    </RootStyle>
  );
}
