import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { layoutsSelector } from 'app/pages/Layouts/slice/selectors';
import { selectPaymentManagement } from 'app/pages/PaymentManagement/slice/selectors';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useSnackbarSlice } from 'app/pages/SnackBar/slice';
import path from 'app/routes/path';

import { LoadingButton } from '@mui/lab';
import { selectCampaign } from 'app/pages/HomePage/slice/selectors';

import { PackageName, PackageTypePayment, TypeUnitPrice } from 'types/Enum';
import { usePaymentSlice } from '../../../slice';

export default function PopUpExtendPackage() {
  const theme = useTheme();
  const { id } = useParams();
  const { t } = useTranslation();
  const { actions } = usePaymentSlice();
  const dispatch = useDispatch();
  const { listAllPackage, listInvoice, unitPrice } = useSelector(selectPaymentManagement);

  const [isLoadingButtonAdvance, setIsLoadingButtonAdvance] = useState(false);
  const { themeInfo } = useSelector(layoutsSelector);
  const { actions: snackbarActions } = useSnackbarSlice();
  const fetchFormData = useSelector(selectCampaign);
  const { listCampaign } = fetchFormData;

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalPlayTurn, setTotalPlayTurn] = useState<number>();

  useEffect(() => {
    dispatch(actions.getListExtendPackage({ page: 1, perpage: 10 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(actions.getUnitPricePackage({ type: TypeUnitPrice.ASSERPACK }));
    dispatch(actions.getUnitPrice({ type: TypeUnitPrice.SERVICEPACK }));
    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unitPrice && totalPlayTurn) {
      setTotalPrice(totalPlayTurn * unitPrice);
    }
  }, [unitPrice, totalPlayTurn]);

  const handleOrderPaymentPayasyougo = () => {
    setIsLoadingButtonAdvance(true);
    if (listInvoice?.total) {
      dispatch(
        actions.orderPaymentPostpaid(
          {
            play_turn: Number(value),
            package_id: listCampaign?.package?.id,
            campaign_id: Number(id),
          },
          (result?: any) => {
            if (result?.success) {
              const orderId = result.data.id;
              const totalPrice = result.data.total_price;
              dispatch(
                actions.payment({ order_id: orderId, amount: totalPrice, language: 'vn' }, (err?: any) => {
                  if (err.success) {
                    window.open(err.data, '_self');
                  }
                })
              );
              setIsLoadingButtonAdvance(false);
            } else if (result.message === 'campaign_has_been_ended') {
              dispatch(
                snackbarActions.updateSnackbar({
                  message: t('reward.campaignEnd'),
                  type: 'error',
                })
              );
              setIsLoadingButtonAdvance(false);
            } else {
              dispatch(
                snackbarActions.updateSnackbar({
                  message: t('payment.upgradePackageFailed'),
                  type: 'error',
                })
              );
              setIsLoadingButtonAdvance(false);
            }
          }
        )
      );
      setIsLoadingButtonAdvance(false);
    } else {
      dispatch(
        actions.orderPaymentPostpaid(
          {
            play_turn: Number(value),
            package_id: listCampaign?.package?.order_package_id,
            campaign_id: Number(id),
          },
          (result?: any) => {
            if (result.success) {
              const orderId = result.data.id;
              const totalPrice = result.data.total_price;
              dispatch(
                actions.payment({ order_id: orderId, amount: totalPrice, language: 'vn' }, (err?: any) => {
                  if (err.success) {
                    window.open(err.data, '_self');
                  }
                })
              );
              setIsLoadingButtonAdvance(false);
            } else if (result.message === 'campaign_has_been_ended') {
              dispatch(
                snackbarActions.updateSnackbar({
                  message: t('reward.campaignEnd'),
                  type: 'error',
                })
              );
              setIsLoadingButtonAdvance(false);
            } else if (result.errors) {
              if (result.errors.campaign_time) {
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: t('payment.campaignTimeError'),
                    type: 'error',
                  })
                );
              }
              if (result.errors.access_per_day) {
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: t('payment.accessPerDayError'),
                    type: 'error',
                  })
                );
              }
              if (result.errors.max_user) {
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: t('payment.maxUserError'),
                    type: 'error',
                  })
                );
              }
              if (result.errors.campaign_time && result.errors.access_per_day) {
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: t('payment.campaignTimeAndAccessPerDayError'),
                    type: 'error',
                  })
                );
              }
              if (result.errors.campaign_time && result.errors.max_user) {
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: t('payment.campaignTimeAndMaxUserError'),
                    type: 'error',
                  })
                );
              }
              if (result.errors.access_per_day && result.errors.max_user) {
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: t('payment.accessPerDayAndMaxUserError'),
                    type: 'error',
                  })
                );
              }
              if (result.errors.campaign_time && result.errors.access_per_day && result.errors.max_user) {
                dispatch(
                  snackbarActions.updateSnackbar({
                    message: t('payment.campaignTimeAndAccessPerDayAndMaxUserError'),
                    type: 'error',
                  })
                );
              }
            } else {
              dispatch(
                snackbarActions.updateSnackbar({
                  message: t('payment.pleaseUpgradePackage'),
                  type: 'error',
                })
              );
              setIsLoadingButtonAdvance(false);
            }
            setIsLoadingButtonAdvance(false);
          }
        )
      );
    }
  };

  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const handleChange = (event: any) => {
    setValue(event.target.value as string);
    setTotalPlayTurn(event.target.value as number);
  };

  const renderPackageName = () => {
    switch (listCampaign?.package?.package_package_type) {
      case PackageTypePayment.EMERALD:
        return PackageName.EMERALD;
      case PackageTypePayment.SAPPHIRE:
        return PackageName.SAPPHIRE;
      case PackageTypePayment.RUBY:
        return PackageName.RUBY;
      case PackageTypePayment.DIAMOND:
        return PackageName.DIAMOND;
    }
  };

  return (
    <Grid>
      <Box
        sx={{
          padding: '0 24px 24px 0',
        }}
      >
        <Typography sx={{}}> {t('payment.extendYourPackage')}</Typography>
      </Box>
      <Grid
        sx={{
          width: '100%',
          padding: '10px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '559px', marginTop: '50px' }}>
          <Box
            sx={{
              height: '35px',
              background: 'black',
              color: 'white',
              padding: '6px 0px 0px 25px',
              borderRadius: '5px 5px 0 0',
            }}
          >
            {renderPackageName()}
          </Box>
          <Box sx={{ height: '249px', background: 'white', borderRadius: '0 0 5px 5px' }}>
            <Box sx={{}}>
              <FormControl sx={{ width: '512px', marginLeft: '18px', marginTop: '52px' }}>
                <InputLabel id="demo-simple-select-label">{t('payment.numberOfPlayTurn')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  label={t('payment.numberOfPlayTurn')}
                  onChange={handleChange}
                >
                  {listAllPackage?.data.map((item, index) => (
                    <MenuItem value={item.play_turn}>{item.play_turn}</MenuItem>
                  ))}
                </Select>
                <Box
                  sx={{
                    padding: '15px 14px 1px 15px',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  {`${totalPrice} $`}
                </Box>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    pt: 2,
                    gap: '12px',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <LoadingButton
                    loading={isLoadingButtonAdvance}
                    sx={{
                      background: theme.palette.primary.main,
                      color: theme.palette.common.white,
                      mr: 2,
                      minWidth: '80px',
                      p: '0px 16px',
                    }}
                    disabled={value === ''}
                    onClick={() => handleOrderPaymentPayasyougo()}
                  >
                    {t('payment.payNow')}
                  </LoadingButton>
                  <Button
                    variant={'outlined'}
                    sx={{ color: theme.palette.common.black, minWidth: '80px' }}
                    onClick={() => navigate(`${path.campaign}/${id}/payment/service-pack`)}
                  >
                    {t('payment.seeAllPackage')}
                  </Button>
                </Grid>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
